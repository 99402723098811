import React, { useRef, useEffect, useState } from 'react';
import { Box, Paper, Typography, Switch, Grid, IconButton, Button } from '@mui/material';
import { Oval } from 'react-loader-spinner'


interface props {
    icon: any,
    title: string,
    data: string,
    message?: string,
    layout: string,
    loaded: boolean,
    additional?: any,
}





export default function Tile({ icon, title, data, message, layout, loaded, additional }: props) {


    let colour = 'black'

    if ((title === "Warmest Temperature") || (title === "Warmest Location")) {
        colour = 'red'
    }
    else if ((title === "Coolest Temperature") || (title === "Coolest Location")) {
        colour = 'blue'
    }

    if (layout === "normal") {

        return (

            <Paper elevation={3} sx={{ m: '10px', p: '10px', backgroundColor: 'white', border: 'solid 1px #b2bec3' }}>
                <Grid container >
                    {loaded
                        ? (
                            <>
                                <Grid item xs={12} > <Typography sx={{ display: 'flex', alignContent: 'center', color: colour }}> {icon} {title} </Typography></Grid>
                                <Grid item xs={8}> <Typography variant='h3' sx={{ marginTop: '5px' }} >{data}</Typography></Grid>
                                {additional}
                                <Grid item xs={12} sx={{ marginTop: '5px' }}> {message} </Grid>
                            </>
                        )

                        : (<>

                        <Box sx={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>

                            <Oval
                                    height={80}
                                    width={80}
                                    color="#76C7F0"
                                    wrapperStyle={{}}
                                    wrapperClass=""
                                    visible={true}
                                    ariaLabel='oval-loading'
                                    secondaryColor="#1BB4FF"
                                    strokeWidth={6}
                                    strokeWidthSecondary={6}

                                />

                        </Box>

                            

                        </>)
                    }
                </Grid>
            </Paper>
        )
    }

    else if (layout === "tight") {

        let style = {
            fontSize: "50"
        }

        let modify_icon = React.cloneElement(icon, { style: style })


        return (

            <Box>
                <Paper elevation={6} sx={{ m: '5px', p: '5px', width: '160px', height: '160px', backgroundColor: '#white', border: 'solid 1px #b2bec3', display: 'flex', alignItems: 'center' }}>
                    <Grid container >
                        {loaded
                            ? (<>
                                <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <Typography variant='h5' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: colour }}> {modify_icon} </Typography></Grid>
                                <Grid item xs={8} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px' }} > <Typography sx={{ display: 'flex', alignContent: 'center', color: colour, ml:'5px', width:'100%' }}> {title} </Typography></Grid>
                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <Typography variant='h5' sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', textAlign: 'center' }}> {data}</Typography></Grid>
                            </>)

                            : (<>
                            </>)
                        }
                    </Grid>
                </Paper>

            </Box>
        )

    }

    else if (layout === "widget") {

        let style = {
            fontSize: "30"
        }

        let modify_icon = React.cloneElement(icon, { style: style })

        let text_size: "h4"|"body2"|"body1"|'h5' = 'h5'

        if (title === "Coolest Location") {

            text_size = 'body1'
        }

        return (

            <Box sx={{}}>
                <Paper elevation={6} sx={{ m: '5px', p: '5px', width: '115px', height: '90px', backgroundColor: 'rgba(255,255,255,0.8)', border: 'solid 0px #b2bec3', display: 'flex', alignItems: 'center' }}>
                    <Grid container >
                        {loaded
                            ? (<>
                                <Grid item xs={3} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <Typography variant='body2' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: colour }}> {modify_icon} </Typography></Grid>
                                <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '5px' }} > <Typography variant='body2' sx={{ display: 'flex', alignContent: 'center', color: colour, textAlign:'center' }}> {title} </Typography></Grid>
                                <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}> <Typography variant={text_size} sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', textAlign: 'center' }}> {data}</Typography></Grid>
                            </>)

                            : (<>
                            </>)
                        }
                    </Grid>
                </Paper>

            </Box>
        )

    }

    return (<></>)
}
