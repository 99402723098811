import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import stations_to_sens from '../sens_to_station.json';
import station_to_irrigation from '../station_to_irrigation.json';
import zones from '../All_zones_test.json';
import { getSoilSensorObservations, getSoilMoistureStreams, getSoilSensorAdjustments, getSoilScalingStreams, getAllSoilSensorAdjustments } from '../SenapsCalls'
import EChartsReact from "echarts-for-react";
import * as echarts from 'echarts/core';
import '../style.css'
import ZoneDialogue from './zone_dialogue';


import { Box, Paper, Typography, Switch, Grid, IconButton, Button, Drawer } from '@mui/material';
import { keys } from '@mui/system';

// const useStyles = makeStyles({

//   grid: {
//       display: 'grid',
//       gridTemplateColumns: 'max-content repeat(6,1fr)',
//       padding: '10px 15% 10px 15%',
//       justifyContent: 'center',

//   },

//   item: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       borderBottom: '1px solid lightgrey',
//       fontSize: '12px',
//       padding: '0px 2px',



//   },
//   item2: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       borderRadius: '50%',
//       width: '17px',
//       height: '17px',
//       margin: '2px 0px',
//       '&:hover': {
//           transform: 'scale(1.0)',
//           boxShadow: '0px 0px 5px 0px black',

//       }
//   },
//   title: {
//       display: 'flex',
//       justifyContent: 'right',
//       alignItems: 'center',

//       fontSize: '12px',
//       padding: '0px 2px',

//   },

//   label: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       borderTop: '1px solid grey',
//       borderRight: '1px solid grey',
//       borderBottom: '1px solid grey',
//       fontSize: '12px',
//       padding: '10px 5px',
//       // writingMode: 'vertical-rl',
//       // WebkitTransform: 'rotate(180deg)',




//   },


//   label2: {
//       borderLeft: '1px solid black',
//       borderRight: '1px solid black',
//       borderTop: '1px solid black',
//       padding: '10px 0px',
//       fontSize: '12px',
//       fontWeight: 'bold',
//   },
//   overlap: {
//       gridArea: '1 / 1 / 10 / 10',
//       position: 'relative',
//       ZIndex: '1',
//   }

// });


interface props {
  open: boolean,
  openfunction: any
  setDataFunction: any
}


export default function SummaryDialogue({ open, openfunction, setDataFunction }: props) {

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [currentDate, setCurrentDate] = React.useState("");
  const [stations, setStations] = React.useState<any>([]);
  const [irrigations, setIrrigations] = React.useState<any>([]);

  const [stationData, setStationData] = React.useState<any>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  let [open_zone_dialogue, setOpenZoneDialogue] = React.useState<boolean>(false)
  let [zone_dialogue_data, setZoneDialogueData] = React.useState<any>("")


  const handleClickOpen = () => {
    openfunction(true);
  };

  const handleClose = () => {
    openfunction(false);
  };

  const handleZoneDialogueOpen = () => {

    if (open_zone_dialogue) {
      setOpenZoneDialogue(false)
    } else {
      setOpenZoneDialogue(true)
    }
  }

  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullWidth(event.target.checked);
  };

  React.useEffect(() => {

    let set_data = async () => {

      //load in the json data and save it to the state

      let station_links: any = {}

      stations_to_sens.forEach((station: any) => {

        let station_id: string = station['Station']
        let sens_id: string = station['Sensor']

        station_links[station_id] = sens_id


      })

      let irrigation_links: any = {}

      station_to_irrigation.forEach((station: any) => {

        let station_id: string = station['Station']
        let irrigation_id: string = station['Irrigation_stream']

        irrigation_links[station_id] = irrigation_id

      })

      // make a call to get all the soil senor streams with there first and last dates and the number of observations
      let sensor_data: any = {}

      let data = await getSoilMoistureStreams()

      let streams = await getSoilScalingStreams()

      // filter the steams for the ones with only ".irrigation_schedule_scaling"
      streams = streams.filter((stream: any) => stream['id'].includes('.irrigation_schedule_scaling'))

      // split streams into two euqal parts and store in an array
      let streams1 = streams.slice(0, Math.floor(streams.length / 2))
      let streams2 = streams.slice(Math.floor(streams.length / 2), streams.length)


      // join all the streams into a string comma seperated
      let stream_ids1 = streams1.map((stream: any) => stream['id']).join('%2C')
      let stream_ids2 = streams2.map((stream: any) => stream['id']).join('%2C')


      //get all the stream factors
      let stream_factors_1 = await getAllSoilSensorAdjustments(stream_ids1)
      let stream_factors_2 = await getAllSoilSensorAdjustments(stream_ids2)

      // join the stream factors merge and ensure there are no duplicates
      let stream_factors = [...stream_factors_1, ...stream_factors_2]

      // take only the elements that have keys that are iso dates past yesterday
      stream_factors = stream_factors.filter((stream: any) => {
        let key = Object.keys(stream)[0]
        let date = new Date(key)
        let now = new Date()
        now.setDate(now.getDate() - 1)
        return date > now
      })



      data.forEach((stream: any) => {

        // modify the stream id to get only the "SENS" part
        let stream_id = stream['id'].split('.')[3]
        stream_id = stream_id.split('-')[0]

        let first = stream['resultsSummary']['first']['t']
        let last = stream['resultsSummary']['last']['t']
        let count = stream['resultsSummary']['count']

        let lastValue = stream['resultsSummary']['last']['v']['v']


        // count the number of days between the first and last date
        let first_date = new Date(first)
        let last_date = new Date(last)

        let days = Math.round((last_date.getTime() - first_date.getTime()) / (1000 * 3600 * 24))

        // check if the last value is within the last 24 hours
        let current_date = new Date()
        let current_time = current_date.getTime()

        let last_time = last_date.getTime()

        let time_diff = (current_time - last_time)

        let within_24hours = true

        if (time_diff > 1000 * 3600 * 24) {
          within_24hours = false
        }

        sensor_data[stream_id] = [count, days, within_24hours, lastValue]

      })

      // for each zone get the data needed to make the table
      let zone_data = zones['features'].map((zone: any) => {

        let guid = zone['properties']['guid']
        let console_id = guid.split('_')[0].slice(1)
        let station = guid.split('_')[1]

        // to skip the zones that have no soil sensor
        if (zone['properties']['parkfile'] === null) {
          return [null, null, null, null, null, null]
        }

        let sensor_id = zone['properties']['parkfile']['soil_sensor']
        let greenlist = zone['properties']['parkfile']['greenlist']

        if (greenlist) {
          greenlist = 1
        }
        else {
          greenlist = 0
        }

        let soil_data = zone['properties']['parkfile']['soil_parameters']
        let nse = soil_data['nse']

        let predictionQuality = 1
        if (nse < 0.5) {
          predictionQuality = 0
        } else if (nse < 0.7) {
          predictionQuality = 2
        }

        let days = sensor_data[sensor_id][1]
        let current = sensor_data[sensor_id][2]

        if (current) {
          current = 1
        }
        else {
          current = 0
        }

        let ready = 0

        if (current && greenlist && ((predictionQuality === 1) || (predictionQuality === 2)) && (days > 90)) {
          ready = 1
        }

        // get the current soil moisutre value and determine if it is wet, moist or dry

        let lastValue = sensor_data[sensor_id][3]
        let wilting_point = soil_data['wilting_point'] * 100
        let field_capacity = soil_data['field_capactiy'] * 100
        let saturation = soil_data['saturation'] * 100


        let soil_status = 5

        if (lastValue < wilting_point) {
          soil_status = 1
        }
        else if (lastValue < field_capacity - (field_capacity - wilting_point) * (2 / 3)) {
          soil_status = 2
        }
        else if (lastValue < field_capacity) {
          soil_status = 3
        }
        else if (lastValue < saturation) {
          soil_status = 4
        }

        

        // now I need to get the irrigation adjustments for the next three days for the given console
        let adjustments: any[] = []

        // first walk through the stream factors and find the ones that are for the given console
        let console_factors = stream_factors.forEach((stream: any) => {

          // fist get the date which is the first key
          let key = Object.keys(stream)[0]

          // then get the data which is a dictionary with the stream_ids as keys
          let data = stream[key]

          // get the keys of the data dictionary which are the stream_ids
          let dataKeys = Object.keys(data)

          // filter the datakeys to only include the ones that are for the given guid
          let guidKeys = dataKeys.filter((key: any) => {
            return key.includes(guid + ".")
          })


          // now get the data for the given guid and push it to the adjustments array
          if (guidKeys.length > 0) {
            adjustments.push([key.split('T')[0], data[guidKeys[0]]['v'].toFixed(2)])
          }

        })

        // trim the data to only include the next three days
        adjustments = adjustments.slice(0, 4)

        // if ((ready === 0) || (adjustments.length < 4)) {
        //   soil_status = 6
        //   adjustments = []
        // }

        if ((adjustments.length < 4)) {
          soil_status = 6
          adjustments = []
        }

      

      

        return [console_id, station, guid, sensor_id, soil_status, greenlist, predictionQuality, current, days, ready, adjustments]
      })



      // sort the zone data by the console number and then the station number
      zone_data.sort((a: any, b: any) => {

        if (a[0] === b[0]) {
          return a[1] - b[1]
        }
        else {
          return a[0] - b[0]
        }

      })



      setStationData(zone_data)
      setDataFunction(zone_data)

      setStations(station_links)
      setIrrigations(irrigation_links)
      setLoaded(true)

    }

    set_data()

  }, []);

  const getColor = (value: number) => {

    if (value === 1) {
      return '#0d8c2f'
    } else if (value === 2) {
      return '#FFFF00'
    }
    else if (value === 0) {
      return '#FF0000'
    }
  }

  const getSoilwetness = (value: number) => {

    if (value === 1) {
      return '#f00e02'
    } else if (value === 2) {
      return '#f5a50f'
    }
    else if (value === 3) {
      return '#0d8c2f'
    }
    else if (value === 4) {
      return '#0fe2f5'
    }
    else if (value === 5) {
      return '#0c14f5'
    }
    else if (value === 6) {
      return 'grey'
    }
  }

  const getDayColour = (value: number) => {

    if (value < 90) {
      return '#FF0000'
    } else if (value > 90) {
      return '#0d8c2f'
    }
  }

  const getAdjustmentColour = (value: number) => {
     
      if (value > 1.5) {
        return '#00008B'
      } else if (value > 1) {
        return '#0000CD'
      }
      else if (value > 0.5) {
        return '#6495ED'
      }
      else if (value > 0) {
        return '#87CEEB'
      }
  }

  let console_id = 81

  const renderRow = (station: any) => {

    return (station.map((item: any, index: any) => {

      if ((item === null) || (index === 0) || (index === 1)) {
        return (<></>)
      }

      if (index === 2) {
        return (
          <div className="Item Link" onClick={() => {

            setZoneDialogueData(item)
            handleZoneDialogueOpen()

          }} style={{ cursor: 'pointer', }}>
            <a>{item}</a>
          </div>
        )
      }

      if (index === 4) {
        return (
          <div className="Item" style={{}}>
            <div className='TrafficLight' style={{ backgroundColor: getSoilwetness(item) }}></div>
          </div>)

      }

      if (index === 8) {
        return (
          <div className="Item" style={{}}>
            <div className='TrafficLight' style={{ backgroundColor: getDayColour(item) }}></div>
          </div>)

      }

      if (((index >= 6) && (index <= 7))) {
        return (
          <div className="Item" style={{}}>
            <div className='TrafficLight' style={{ backgroundColor: getColor(item) }}></div>
          </div>
        )
      }

      if ((index === 5)) {
        return (
          <div className="Item" style={{ borderLeft: 'solid 1px black' }}>
            <div className='TrafficLight' style={{ backgroundColor: getColor(item) }}></div>
          </div>
        )
      }

      if ((index === 9)) {
        return (
          <div className="Item" style={{ borderLeft: 'solid 1px black', borderRight: 'solid 1px black' }}>
            <div className='TrafficLight' style={{ backgroundColor: getColor(item) }}></div>
          </div>
        )
      }

      if ((index === 10)) {

        return [1, 2, 3, 4].map((number: any, ind: any) => {
      

          if (item.length > 0) {

            let value = item[ind][1]

            if (value === '0.00') {
              value = '-'
            }

            return (
              <div className='Item' style={{color:getAdjustmentColour(item[ind][1])}}>{value}</div>
            )
          }

          else {
            return (
              <div className='Item'>-</div>
            )
          }

        })

      }

      else {
        return (
          <div className="Item" style={{}}>
            {item}
          </div>
        )
      }
    }))

  }

  return (
    <>

      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Network Status</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>

          {loaded

            ? (


              <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }} >
                <div style={{ position: 'fixed', display: 'grid', gridTemplateColumns: 'repeat(8,100px) repeat(4, 75px)', padding: '10px 5% 5px 5%', justifyContent: 'center', backgroundColor: 'white', zIndex: '10' }}>
                  <div className="Heading" style={{}}> Station </div>
                  <div className="Heading" style={{}}> Soil Sensor </div>
                  <div className="Heading" style={{}}> Current Soil Conditions </div>
                  <div className="Heading" style={{}}> Green List </div>
                  <div className="Heading" style={{}}> Calibration Quality </div>
                  <div className="Heading" style={{}}> Reported in the last 24 hours </div>
                  <div className="Heading" style={{}}> Number of Days Installed* </div>
                  <div className="Heading" style={{}}> Record Length</div>
                  <div className="Heading" style={{ gridRow: "span 1", gridColumn: "span 4" }}> Baseline Adjustments</div>
                  {stationData[3][10].map((item: any, index: any) => {
                    return (<div style={{ fontSize: '12px', fontWeight: 'bold', textAlign: 'center', borderBottom: 'solid 1px black', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{item[0]}</div>)
                  })}

                </div>


                <div style={{ position: 'relative', display: 'grid', gridTemplateColumns: 'repeat(8,100px) repeat(4, 75px)', padding: '100px 5% 0px 5%', justifyContent: 'left' }}>

                  {stationData.map((station: any) => {

                    if ((console_id !== station[0]) && (station[0] !== null)) {
                      console_id = station[0]
                      return (
                        <>
                          <div className="Heading" style={{ gridColumn: "1/-1", marginTop: '10px', marginBottom: '0px' }}> console {station[0]}</div>

                          {renderRow(station)}
                        </>)
                    }

                    else {

                      return (
                        <>
                          {renderRow(station)}
                        </>)
                    }

                  })}
                </div>
              </div>

            )

            : (<></>)
          }






        </DialogContent >
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog >
      <ZoneDialogue open={open_zone_dialogue} openfunction={handleZoneDialogueOpen} values={zone_dialogue_data} />
    </>

  );
}
