import React, {FC, useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import {get, login, post} from "../fetchers/fetchers";
import {useNavigate} from "react-router-dom";

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Select,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from "@mui/material";


export interface IUser {
    username?: string
    password?: string
    token?: string
}

export interface IInfo {
    version: string
}




interface ILogin {
}

const {REACT_APP_API_URL} = process.env;

const Login: FC<ILogin> = () => {

    let navigate = useNavigate();

    const [user, setUser] = useState<IUser>({});
    const [errorMessage, setErrorMessage] = useState<string>()

    const [info, setInfo] = useState<IInfo>()

    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    type keys = "username" | "password" | "token"

    const renderField = (id: string, label: string, key: keys, type: string) => {
        return <div>
            <div style={{
                width: "100%",
                margin: "auto",
            }}>
                <Typography>
                    {label}
                </Typography>
                <TextField
                    id={id}
                    fullWidth
                    value={user[key]}
                    onChange={(event) => {
                            let v = (event.target.value as string);
                            let c = {...user};
                            c[key] = v
                            setUser(c)
                        }
                    }
                    type={type}
                />
            </div>
        </div>
    }

    useEffect(() => {
        if (!isLoaded && !isLoading) {
            setIsLoading(true)
            getVersion().then(
                () => {
                    setIsLoading(false)
                    setIsLoaded(true)
                }
            )
        }

    }, [isLoaded])

    const getToken = async (user: IUser) => {
        return await login<IUser>(REACT_APP_API_URL + "user/login/", user);
    }

    const getVersion = async () => {
        const info =  await get<IInfo>(REACT_APP_API_URL + "info");
        setInfo(info)
    }

    const setUserStorage = (user: IUser) => {
        if (user.username) {
            localStorage.setItem("username", user.username)
        }
        if (user.token) {
            localStorage.setItem("token", user.token)
        }
    }

    const handleLogin = () => {
        getToken(user).then((d) => {
            setUserStorage(d)
            navigate("/")
        }).catch((error) => {

            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
                if (error.response.status == 403) {
                    setErrorMessage("Incorrect user name or password")
                }


            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the
                // browser and an instance of
                // http.ClientRequest in node.js
                setErrorMessage("Server is offline please try again later")
            } else {
                // Something happened in setting up the request that triggered an Error
                setErrorMessage("This should not have happened")
                //console.log('Error', error.message);
            }
        })
    }


    return <Card elevation={8} style={{
        minWidth: "300px",
        width: "40vw",
        margin: "auto",
        position: "relative"
    }}>
        <CardHeader
            title="Log In | Park Now"
            subheader={
                <div>
                    <span style={{
                        color: "#f4f4f4",
                    }}>HARC</span>
                    <span style={{
                        float: "right"
                    }}>
                    {/*<Button onClick={this.handleHelp} size={"small"} variant={"outlined"} style={{color:"#f4f4f4", borderColor:"#f4f4f4"}}>Documentation</Button>*/}
                            </span>
                </div>
            }
            style={{
                color: "#f4f4f4",
                backgroundColor: "#19b0ef"
            }}
        />
        <CardContent>
            <div style={{textAlign: "right"}}><small> Build {process.env.REACT_APP_VERSION} </small><br/></div>
            <div style={{textAlign: "right"}}><small> Backend {info?.version || "offline"} </small><br/></div>
            <small style={{color: "red"}}>{errorMessage}</small>

            {renderField("email", "Email", "username", "email")}
            <br/>
            {renderField("password", "Password", "password", "password")}
        </CardContent>
        <CardActions>

            <Button onClick={handleLogin} id={"login"} size={"small"} color="primary" variant={"outlined"}
                    style={{marginLeft: "auto"}}>login</Button>
        </CardActions>
    </Card>

}

export default Login;
