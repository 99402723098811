import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import stations_to_sens from '../sens_to_station.json';
import station_to_irrigation from '../station_to_irrigation.json';
import zones from '../All_zones_test.json';
import { getSoilSensorObservations, getSoilSensorPredictions, getSingleRunObservations, getSoilMoistureChanges, getEvapObservations, getSoilSensorAdjustments, getMLSoilSensorPredictions, getCalibrationParameters, getRainfallObservations, getTemperatureObservations, getRainfallForecast, getIrrigationHistory } from '../SenapsCalls'
import EChartsReact from "echarts-for-react";
import * as echarts from 'echarts/core';
import MapDialogue from './dialogue_map';
import { Oval } from 'react-loader-spinner'
import Spinner from './spinner';
import EditIcon from '@mui/icons-material/Edit';

import { Box, Paper, Typography, Switch, Grid, IconButton, Button, Drawer } from '@mui/material';



interface props {
  open: boolean,
  openfunction: any
  values: any,
}


export interface SimpleDialogProps {
  open: boolean;
  selectedValue: number;
  onClose: (value: number) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
  const {  onClose, open, selectedValue } = props;

  // convert selected value to string


  const [val , setVal] = React.useState<string>(selectedValue.toFixed(2).toString());

  const handleClose = () => {
    // convert string to number
    let v = parseFloat(val);
    onClose(v);
  };

  return (
    <Dialog  onClose={handleClose} open={open}>
      <DialogTitle>Set soil moisture target</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The soil moister target is calculated as the fraction of the soil mositure between the wilting point and the field capacity. If the target is set to 0 the wilting point is used, if the target is set to 1 the field capacity is used.
        </DialogContentText>
      <TextField
        autoFocus
        margin="dense"
        value={val}
        onChange={(event) => {
          let v = (event.target.value as string);

          setVal(v)
        }}
        id="name"
        label="Soil moisture target"
        fullWidth
      />
      </DialogContent>
    </Dialog>
  );
}


export default function ZoneDialogue({ open, openfunction, values }: props) {



  let [guid_modified, setGuid_modified] = React.useState(values)
  let [loaded, setLoaded] = React.useState(false)
  let [loadedTiles, setLoadedTiles] = React.useState(false)
  let [prevValue, setPervValue] = React.useState("")
  
  let [setTargetDlg, setSetTargetDlg] = React.useState(false)

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [currentDate, setCurrentDate] = React.useState("");
  const [stations, setStations] = React.useState<any>([]);
  const [stationsLoaded, setStationsLoaded] = React.useState<boolean>(false);
  const [irrigations, setIrrigations] = React.useState<any>([]);
  const [soilDataHistoric, setSoilDataHistoric] = React.useState<any>([]);
  const [soilDataPredictedHealth, setSoilDataPredictedHealth] = React.useState<any>([]);
  const [soilDataPredictedCooling, setSoilDataPredictedCooling] = React.useState<any>([]);
  const [soilDataPredictedNoIrrigation, setSoilDataPredictedNoIrrigation] = React.useState<any>([]);
  const [soilDataPredictedML, setSoilDataPredictedML] = React.useState<any>([]);
  const [singleRunSoilData, setSingleRunSoilData] = React.useState<any>([]);
  const [tempDataHistoric, setTempDataHistoric] = React.useState<any>([]);


  const [irrigationDates, setIrrigationDates] = React.useState<any>([]);
  const [irrigationAmounts, setIrrigationAmounts] = React.useState<any>([]);

  const [irrigationCoolDates, setIrrigationCoolDates] = React.useState<any>([]);
  const [irrigationCoolAmounts, setIrrigationCoolAmounts] = React.useState<any>([]);
  const [irrigationCoolAmountsFormatted, setIrrigationCoolAmountsFormatted] = React.useState<any>([]);

  const [irrigationHistory, setIrrigationHistory] = React.useState<any>([]);
  const [evapoHistory, setEvapoHistory] = React.useState<any>([]);

  const [rainfallHistoric, setRainfallHistoric] = React.useState<any>([]);
  const [rainfallForecast, setRainfallForecast] = React.useState<any>([]);

  const [soilParameters, setSoilParameters] = React.useState<any>([[], [0, 0, 0], []]);
  const [stationData, setStationData] = React.useState<any>([[], [], []]);
  const [trendData, setTrendData] = React.useState<any>([]);

  const [soilSensor, setSoilSensor] = React.useState<any>("");
  const [curretSoilMoisture, setCurrentSoilMoisture] = React.useState<any>(0);
  const [currentTime, setCurrentTime] = React.useState<any>(0);

  const [greenlistStatus, setGreenlistStatus] = React.useState<any>(false);
  const [tempSensor, setTempSensor] = React.useState<any>("");
  const [modelPrediction, setModelPrediction] = React.useState<any>("Poor");
  const [maxMinLength, setMaxMinLength] = React.useState<any>([]);

  const [exceedanceData, setExceedanceData] = React.useState<any>([]);
  const [allSoilData, setAllSoilData] = React.useState<any>([]);
  const [allRainfallData, setAllRainfallData] = React.useState<any>([]);
  const [allIrrigationData, setAllIrrigationData] = React.useState<any>([]);

  const [target, setTarget] = React.useState<any>([]);
  
  const [allTarget, setAllTarget] = React.useState<any>([]);

  const [soilMoistureTarget, setSoilMoistureTarget] = React.useState<number>(0.6);

  const [soilCalibrationData, setSoilCalibrationData] = React.useState<any>([]);


  const [soilSensors, setSoilSensors] =  React.useState([""]); // List of soil sensors

  const handleChange = (event: any) => {
    setSoilSensor(event.target.value);
  };




  const handleClickOpen = () => {
    openfunction(true);
  };

  const handleClose = () => {
    openfunction(false);
    setLoaded(false)
    setLoadedTiles(false)
    setGuid_modified("")
   
  };

  const handleCloseTargetDlg = (value: number) => {
    setSetTargetDlg(false);
    setSoilMoistureTarget(value);
  }

  const handleClickChangeTarget = () => {
    setSetTargetDlg(true)
  }

  React.useEffect(() => {

    let smt = soilMoistureTarget

    let target_sm = ((soilCalibrationData['field_capactiy'] - soilCalibrationData['wilting_point']) * smt + soilCalibrationData['wilting_point'])*100



    let target_formatted = rainfallHistoric.map((data: any) => {
      return [data[0],target_sm]})

    let target_formatted_f = rainfallForecast.map((data: any) => {
        return [data[0],target_sm]})

    // combined target_formatted and target_formatted_f
    let target_formatted_combined = target_formatted.concat(target_formatted_f)
    setTarget(target_formatted_combined)


    let all_target_formatted = allRainfallData.map((data: any) => {
      return [data[0],target_sm]})

    setAllTarget(all_target_formatted)


  }, [soilMoistureTarget]);

  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullWidth(event.target.checked);
  };

  React.useEffect(() => {

    //load in the json data and save it to the state

    let station_links: any = {}

    stations_to_sens.forEach((station: any) => {

      let station_id: string = station['Station']
      let sens_id: string = station['Sensor']

      station_links[station_id] = sens_id


    })

    let irrigation_links: any = {}

    station_to_irrigation.forEach((station: any) => {

      let station_id: string = station['Station']
      let irrigation_id: string = station['Irrigation_stream']

      irrigation_links[station_id] = irrigation_id

    })


    setStations(station_links)
    setIrrigations(irrigation_links)
    setStationsLoaded(true)

  }, []);


  function scheduleToIrrigation(value: any, area: any, station_flow_rate: any) {

    return value * station_flow_rate / 1000 / area * 1000;
  }




  React.useEffect(() => {

    if (!stationsLoaded) {
      return
    }

    if (guid_modified === "") {
      return
    }

    if (loaded) {
      if (guid_modified !== values) {
        values = guid_modified

      }
    }

    setLoadedTiles(false)

    let station_flow_rate = 0;
    let area = 0;
    
    // let target_SM = 10

    // non async version just to find me the area
    zones['features'].forEach((zone: any) => {

      if (zone['properties']['guid'] === values) {

        //set all the data for the zone
        area = zone['properties']['area']
        station_flow_rate = zone['properties']['parkfile']['station_parameters']['station_flow_rate_ls']

      }
    })

    const get_data = async () => {

      let closest_station = ""


      // because of the async nature of the code, we need to make sure that the data is loaded before we try to use it
      zones['features'].forEach(async (zone: any) => {

        if (zone['properties']['guid'] === values) {

          //set all the data for the zone
          let soil_data = zone['properties']['parkfile']['soil_parameters']


          let soil_moistue_12H = { v: { v: 0 }, t: { v: 0 } }
          let soil_moistue_24H = { v: { v: 0 }, t: { v: 0 } }
          let soil_moistue_72H = { v: { v: 0 }, t: { v: 0 } }
          let soil_moistue_168H = { v: { v: 0 }, t: { v: 0 } }

          // if zone['properties']['guid'] contains "C84"
          if (true) {

            // read in the current soil_moisture changes 
            let soil_moistue_12H_data = await getSoilMoistureChanges(`simpact.toolbox.test.${zone['properties']['guid']}.H12`)
            let soil_moistue_24H_data = await getSoilMoistureChanges(`simpact.toolbox.test.${zone['properties']['guid']}.H24`)
            let soil_moistue_72H_data = await getSoilMoistureChanges(`simpact.toolbox.test.${zone['properties']['guid']}.H72`)
            let soil_moistue_168H_data = await getSoilMoistureChanges(`simpact.toolbox.test.${zone['properties']['guid']}.H168`)


            soil_moistue_12H = soil_moistue_12H_data[soil_moistue_12H_data.length - 1]
            soil_moistue_24H = soil_moistue_24H_data[soil_moistue_24H_data.length - 2]
            soil_moistue_72H = soil_moistue_72H_data[soil_moistue_72H_data.length - 2]
            soil_moistue_168H = soil_moistue_168H_data[soil_moistue_168H_data.length - 2]

          }


          // need to read in the calibration data for the soil sensor

          let calibration_parameters = await getCalibrationParameters(zone['properties']['parkfile']['soil_sensor'])

          // the the soil data NSE from the calibration parameters
  
          let nse = calibration_parameters['NSE']

          

          let predictionQuality = "Good"
          if (nse < 0.5) {
            predictionQuality = "Poor"
          } else if (nse < 0.7) {
            predictionQuality = "Fair"
          }



          let soil_parameter_names = ['Saturation', 'Field Capacity', 'Wilting Point', 'Horton Initial Infiltration', 'Horton Final Infiltration', 'Horton Decay Rate', 'Transpiration Rate', 'Gravity Drainage Rate', 'Initial Loss', 'Rainfall Scaling', 'NSE']
          let soil_parameter_units = ['%VWC', '%VWC', '%VWC', 'm/hr', 'm/hr', '/hr', '', '', 'm', '']

          let soil_parameter_values: any = []

          if (calibration_parameters.statuscode) {

            soil_parameter_values = [soil_data['saturation'] * 100, soil_data['field_capactiy'] * 100, soil_data['wilting_point'] * 100, 0.6, soil_data['horton_final_infiltration'], 0.06, soil_data['transpiration_capacity'], soil_data['ground_water_recharge_rate'], 0.01, 1, soil_data['nse']]

          } else {

            soil_parameter_values = [calibration_parameters['saturation'] * 100, calibration_parameters['field_capactiy'] * 100, calibration_parameters['wilting_point'] * 100, 0.6, calibration_parameters['horton_final_infiltration'], 0.06, calibration_parameters['transpiration_capacity'], calibration_parameters['ground_water_recharge_rate'], 0.01, calibration_parameters['rainfall_scaling'], calibration_parameters['NSE']]

          }


          let station_data = zone['properties']['parkfile']['station_parameters']

          let nearest_temp_station = zone['properties']['join_device name']

          let station_parameter_names = ['Presentation Standards', 'Dominant Vegitation', 'Summer Water Requirements', 'Summer Drought Tolerance', 'Winter Water Requirements', 'Winter Drought Tolerance', 'Station Flow Rate', 'Area']

          let station_parameter_units = ["", "", "", "", "", "", "L/s", "m2"]

          let station_parameter_values = [
            station_data['station_presentation_standard'],
            station_data['station_dominant_vegetation'],
            station_data['station_water_requirement_summer'],
            station_data['station_drought_tolerance_summer'],
            station_data['station_water_requirement_winter'],
            station_data['station_drought_tolerance_winter'],
            station_data['station_flow_rate_ls'],
            zone['properties']['area']]

          // area = zone['properties']['area']
          // station_flow_rate = station_data['station_flow_rate_ls']



          setSoilParameters([soil_parameter_names, soil_parameter_values, soil_parameter_units])
          setStationData([station_parameter_names, station_parameter_values, station_parameter_units])
          setGreenlistStatus(zone['properties']['parkfile']['greenlist'])
          setTempSensor(nearest_temp_station)
          closest_station = nearest_temp_station
          setModelPrediction(predictionQuality)
          setTrendData([soil_moistue_12H, soil_moistue_24H, soil_moistue_72H, soil_moistue_168H])
        }

      })

      // get and set the data for the station
      let station_id = values


      let soil_sensor = stations[station_id]
      let irrigation_stream: string = `fieldmouse.bicentennialpark.station_irrigations.${station_id}`

      // make a call to get the soil moisutre data for the last two weeks

      // create a timestring in iso format for 30 days ago
      let date = new Date();
      date.setDate(date.getDate() - 30);
      let iso_date = date.toISOString();

      // create a new date for now in iso format
      let iso_date_now = new Date().toISOString();




      const soil_data = await getSoilSensorObservations(soil_sensor, iso_date)
      const single_run = await getSingleRunObservations(soil_sensor, iso_date)

      let soil_data_formatted = soil_data.map((data: any) => {
        return [data['t'], data['v']['v'].toFixed(2)]
      })

      let single_run_formatted = single_run.map((data: any) => {
        let time = new Date(data['t'])
        time.setHours(time.getHours() + 24)

        return [time, data['v']['v'].toFixed(2)]
      })


      // get the predicted soil moisture for plant health
      const soil_prediction = await getSoilSensorPredictions(station_id, "")

      // get the predicted soil moisture
      const soil_prediction_cooling = await getSoilSensorPredictions(station_id, "cool_")

      // get the predicted soil moisture for no irrigation
      const soil_prediction_no_irrigation = await getSoilSensorPredictions(station_id, "no_")

      // get the predicted soil moisture for base irrigation

      // get the predicted soil moisutre from the ML model from now onwards
      const soil_prediction_ML = await getMLSoilSensorPredictions(soil_sensor, iso_date_now)




      let soil_prediction_formatted = []
      let soil_prediction_cooling_formatted = []
      let soil_prediction_ML_formatted = []
      let soil_prediction_no_irrigation_formatted = []

      if (soil_prediction.length > 0) {
        soil_prediction_formatted = soil_prediction.map((data: any) => {
          // add 12 hours to the time to get the middle of the day
          let time = new Date(data['t'])
          time.setHours(time.getHours() + 24)

          return [time, data['v']['v'].toFixed(2)]
        })
      }

      if (soil_prediction_cooling.length > 0) {
        soil_prediction_cooling_formatted = soil_prediction_cooling.map((data: any) => {
          let time = new Date(data['t'])
          time.setHours(time.getHours() + 24)
          return [time, data['v']['v'].toFixed(2)]
        })
      }

      if (soil_prediction_ML.length > 0) {
        soil_prediction_ML_formatted = soil_prediction_ML.map((data: any) => {
          let time = new Date(data['t'])
          time.setHours(time.getHours())
          return [time, data['v']['v'].toFixed(2)]
        })
      }

      let date_end = new Date()

      if (soil_prediction_no_irrigation.length > 0) {
        soil_prediction_no_irrigation_formatted = soil_prediction_no_irrigation.map((data: any) => {
          let time = new Date(data['t'])
          time.setHours(time.getHours() + 24)
          date_end = time
          return [time, data['v']['v'].toFixed(2)]
        })
      }

      let sens_short = soil_sensor.split('-')[0]

      let calibration_parameters = await getCalibrationParameters(sens_short)

      setSoilCalibrationData(calibration_parameters)


      let target_sm = ((calibration_parameters['field_capactiy'] - calibration_parameters['wilting_point']) * soilMoistureTarget + calibration_parameters['wilting_point'])*100


      //plot a target line for the soil moisture from the start to the end of the prediction
      let target_formatted = [[iso_date, target_sm],[date_end, target_sm]]

      // get the associated irrigation adjustments for plant health
      const soil_adjustments = await getSoilSensorAdjustments(station_id, "")

      let adjustment_dates: any = []
      let adjustment_values: any = []

      if (soil_adjustments.length > 0) {

        soil_adjustments.forEach((data: any) => {

          //convert data['t'] to date object from iso string
          let time = new Date(data['t'])

          // add 12 hours to the time to get the middle of the day
          time.setHours(time.getHours() + 12)

          // convert back to iso string
          let iso_time = time.toISOString()

          adjustment_dates.push(iso_time.split('T')[0])
          adjustment_values.push(data['v']['v'].toFixed(2))


        })
      }




      // get the associated irrigation adjustments for plant cooling
      const soil_cool_adjustments = await getSoilSensorAdjustments(station_id, "cool_")

      let adjustment_cool_values: any = []

      if (soil_cool_adjustments.length > 0) {
        soil_cool_adjustments.forEach((data: any) => {
          adjustment_cool_values.push(data['v']['v'].toFixed(2))

          
        })
      }





      // convert soil moisture adjustments to array with dates and values
      let soil_adjustments_cooling_formatted: any = []
      adjustment_values.forEach((value: any, index: any) => {



        let date = new Date(adjustment_dates[index])

        //set date to midnight the next day
        date.setHours(24)

        //convert back to iso string
        let iso_date_adjusted = date.toISOString()

        //add 15 min
        date.setMinutes(15)

        let iso_date_adjusted_15 = date.toISOString()

        soil_adjustments_cooling_formatted.push([iso_date_adjusted, 0])
        soil_adjustments_cooling_formatted.push([iso_date_adjusted, scheduleToIrrigation(value * 15 * 60, area, station_flow_rate).toFixed(2)])
        soil_adjustments_cooling_formatted.push([iso_date_adjusted_15, scheduleToIrrigation(value * 15 * 60, area, station_flow_rate).toFixed(2)])
        soil_adjustments_cooling_formatted.push([iso_date_adjusted_15, 0])
      })






      // get the past rainfall for the last 30 days
      const rainfall_data = await getRainfallObservations(iso_date)

      let rainfall_data_formatted = rainfall_data.map((data: any) => {
        return [data['t'], data['v']['v'].toFixed(2)]
      })



      // get the past evapotranspiration for the last 30 days
      const evapotranspiration_data = await getEvapObservations(iso_date)

      let evapotranspiration_data_formatted = evapotranspiration_data.map((data: any) => {
        return [data['t'], data['v']['v'].toFixed(2)]
      })

      // get the forecasted rainfall for the next 7 days
      let today = new Date();
      today.setHours(0, 0, 0, 0);
      let today_iso = today.toISOString();

      let rainfall_forcast = await getRainfallForecast(today_iso)

      let rainfall_forcast_formatted = rainfall_forcast.map((data: any) => {
        return [data['t'], data['v']['v'].toFixed(2)]
      })



      // convert the forcasted rainfall from 3 hour intervals to daily
      let rainfall_forcast_daily: any = []

      let current_date = new Date(rainfall_forcast_formatted[0][0])
      let current_date_string = current_date.toISOString().split('T')[0]
      let current_date_value = 0

      rainfall_forcast_formatted.forEach((data: any) => {

        let date = new Date(data[0])
        let date_string = date.toISOString().split('T')[0]

        if (date_string == current_date_string) {
          current_date_value += data[1]
        } else {
          rainfall_forcast_daily.push([current_date_string, current_date_value])
          current_date_string = date_string
          current_date_value = data[1]
        }

      })


      // get the past irrigation history for the last 30 days
      let irrigation_history_formatted: any[] = []


      if (irrigation_stream !== "None") {

        let irrigation_history = await getIrrigationHistory(`${irrigation_stream}.mm`, iso_date)

        irrigation_history.forEach((data: any) => {

          let time = new Date(data['t'])

          // add 1 minute to the time
          time.setMinutes(time.getMinutes() + 1)

          //convert to iso string
          let upper = time.toISOString()

          // subtract 2 minute to the time
          time.setMinutes(time.getMinutes() - 2)

          //convert to iso string
          let lower = time.toISOString()

          // add the upper and lower bounds to the irrigation history
          irrigation_history_formatted.push([lower, 0])
          irrigation_history_formatted.push([data['t'], data['v']['v'].toFixed(2)])
          irrigation_history_formatted.push([upper, 0])


          // return [data['t'], data['v']['v'].toFixed(2)]
        })


      }

      else {
        irrigation_history_formatted = []
      }

      let temp_data_formatted: any = []


      // get the temp data from the nearest temp sensor
      let temp_data = await getTemperatureObservations(closest_station, iso_date)

      if (temp_data) {
        temp_data_formatted = temp_data.map((data: any) => {
          return [data['t'], data['v']['v'].toFixed(2)]
        })

      }

      // I want to get the full soil moisture stream of data to determine:
      // 1. the number of days of observations
      // 2. the max and min values
      // 3. plot a excedance curve for each sensor (or maybe a box plot)

      const soil_data_all = await getSoilSensorObservations(soil_sensor, "")

      // format all the soil data to plot
      let soil_data_all_formatted = soil_data_all.map((data: any) => {
        return [data['t'], data['v']['v'].toFixed(2)]
      })

     

      // get the first date from the soil moisture data
      let first_date = new Date(soil_data_all_formatted[0][0]).toISOString()

      // get the rainfall data for the same period
      const rainfall_data_all = await getRainfallObservations(first_date)

      // add a datapoint for every day in the period between the first_date and date_end
      let all_target_formatted = rainfall_data_all.map((data: any) => {
        return [data['t'],target_sm]})

      // get the irrigation data for the same period
      let irrigation_data_all_formatted: any[] = []

      if (irrigation_stream !== "None") {
        let irrigation_data_all = await getIrrigationHistory(`${irrigation_stream}.mm`, first_date)

        irrigation_data_all.forEach((data: any) => {

          let time = new Date(data['t'])

          // add 1 minute to the time
          time.setMinutes(time.getMinutes() + 1)

          //convert to iso string
          let upper = time.toISOString()

          // subtract 2 minute to the time
          time.setMinutes(time.getMinutes() - 2)

          //convert to iso string
          let lower = time.toISOString()

          // add the upper and lower bounds to the irrigation history
          irrigation_data_all_formatted.push([lower, 0])
          irrigation_data_all_formatted.push([data['t'], data['v']['v'].toFixed(2)])
          irrigation_data_all_formatted.push([upper, 0])


          // return [data['t'], data['v']['v'].toFixed(2)]
        })
      }


      else {
        irrigation_data_all_formatted = []
      }


      let rainfall_data_all_formatted = rainfall_data_all.map((data: any) => {
        return [data['t'], data['v']['v'].toFixed(2)]
      })

      // iterate through the soil data and seperate the time and value data into two arrays
      let soil_values: number[] = []
      let soil_dates: any[] = []

      soil_data_all.forEach((data: any) => {
        soil_values.push(data['v']['v'])
        soil_dates.push(data['t'].split('T')[0])
      })

      // remove the duplicate dates
      let soil_dates_unique = [...new Set(soil_dates)].length

      // get the max and min values
      let soil_max = Math.max(...soil_values)
      let soil_min = Math.min(...soil_values)

      // sort the soil values from high to low
      soil_values.sort((a, b) => b - a)

      // make an arry of the exceedance values
      let soil_exceedance_values: number[] = []

      soil_values.forEach((value: any, index: any) => {
        soil_exceedance_values.push((index + 1) / soil_values.length)
      })

      // joint the soil values and exceedance values into a single array for plotting
      let soil_exceedance_formatted: any = []

      soil_values.forEach((value: any, index: any) => {
        soil_exceedance_formatted.push([soil_exceedance_values[index], value])
      })


      setMaxMinLength([soil_max.toFixed(2), soil_min.toFixed(2), soil_dates_unique])
      setExceedanceData(soil_exceedance_formatted)
      setAllSoilData(soil_data_all_formatted)
      setAllRainfallData(rainfall_data_all_formatted)
      setAllIrrigationData(irrigation_data_all_formatted)

      setTempDataHistoric(temp_data_formatted)
      setSoilDataHistoric(soil_data_formatted)
      setEvapoHistory(evapotranspiration_data_formatted)

      setSoilDataPredictedHealth(soil_prediction_formatted)
      setSoilDataPredictedCooling(soil_prediction_cooling_formatted)
      setSoilDataPredictedNoIrrigation(soil_prediction_no_irrigation_formatted)
      setSoilDataPredictedML(soil_prediction_ML_formatted)

      setIrrigationCoolAmountsFormatted(soil_adjustments_cooling_formatted)

      setIrrigationDates(adjustment_dates)
      setIrrigationAmounts(adjustment_values)

      setIrrigationCoolDates(adjustment_dates)
      setIrrigationCoolAmounts(adjustment_cool_values)

      setRainfallHistoric(rainfall_data_formatted)
      setRainfallForecast(rainfall_forcast_formatted)
      setIrrigationHistory(irrigation_history_formatted)

      setSoilSensor(soil_sensor.split('-')[0])
      setSoilSensors([soil_sensor.split('-')[0]])
      // setSoilSensors([soil_sensor.split('-')[0], "no sensor"])
      
      setTarget(target_formatted)
      setAllTarget(all_target_formatted)

      if (soil_data_formatted.length > 0) {
        setCurrentSoilMoisture(soil_data_formatted[soil_data_formatted.length - 1][1])
        setCurrentTime(soil_data_formatted[soil_data_formatted.length - 1][0])
      }

      else {
        setCurrentSoilMoisture(0)
        setCurrentTime("No Data")
      }

      if (station_id === "C84_20") {
        setSingleRunSoilData(single_run_formatted)
      }
      else {
        setSingleRunSoilData([])
      }

      // get the current time as a string in iso format
      current_date_string = new Date().toISOString();
      setCurrentDate(current_date_string)




      setLoaded(true)
      setLoadedTiles(true)
      setPervValue(values)

    }

    get_data()

  }, [values, guid_modified, stationsLoaded]);

  // 

  // line plot of the soil moisture data past and predicted
  const getOption = () => {

    return {
      tooltip: {
        trigger: 'axis',
        position: function (pt: any) {
          return [pt[0], '10%'];
        }
      },
      title: {
        left: 'center',
        text: "Soil Moisture and Irrigation History",
      },
      legend: {
        layout: 'horizontal',
        bottom: 0,
        selected: {
          'Temperature': false,
          'Evapotranspiration': false,

        }
      },
      grid: {
        y: 30,
        y2: 130,
        x: 50,
        x2: 150
      },

      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },

      xAxis: {
        type: 'time',
        boundaryGap: ['1%', '1%'],
        maxInterval: 3600 * 1000 * 24
      },
      yAxis: [{
        type: 'value',
        name: 'Soil Moisture (%VWC)',
        nameLocation: 'middle',
        nameGap: 30,
        min: 0,
        max: 50,
      },
      {
        type: 'value',
        name: 'Rainfall (mm)',
        position: 'right',
        nameLocation: 'middle',
        nameGap: 40,
        min: 0,
        max: 25,
      },
      {
        type: 'value',
        name: 'Temperature (C)',
        position: 'right',
        offset: 60,
        nameLocation: 'middle',
        nameGap: 40,
        min: 0,
        max: 50,
      }],
      dataZoom: [
        {
          start: 0,
          end: 100,
          bottom: 60
        },
      ],
      series: [
        {
          name: 'Soil Moisture Historic',
          type: 'line',
          smooth: false,
          symbol: 'none',
          data: soilDataHistoric,
          yAxisIndex: 0,
          markLine: {
            symbol: 'none',
            name: 'Today',
            data: [[
              { name: 'Today', xAxis: currentDate, yAxis: 0 },
              { name: 'end', xAxis: currentDate, yAxis: 'max' },
            ]]
          }
        },
        {
          name: 'Soil Moisture Target',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 0,
          data: target,
        },
        {
          name: 'Soil Moisture (Plant Health)',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 0,
          data: soilDataPredictedHealth,
        },
        {
          name: 'Soil Moisture (Cooling)',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 0,
          data: soilDataPredictedCooling,
        },
        {
          name: 'Soil Moisture (No Irrigation)',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 0,
          data: soilDataPredictedNoIrrigation,
        },
        {
          name: 'Soil Moisture (ML)',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 0,
          data: soilDataPredictedML,
        },
        {
          name: 'Soil Moisture (Single Run)',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 0,
          data: singleRunSoilData,
        },
        {
          name: 'Rainfall',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 1,
          data: rainfallHistoric,
        },
        {
          name: 'Rainfall Forecast',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 1,
          data: rainfallForecast,
        },
        {
          name: 'Irrigation History',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 1,
          data: irrigationHistory,
        }
        ,
        {
          name: 'Irrigation Forecast (Plant health)',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 1,
          data: irrigationCoolAmountsFormatted,
        },
        {
          name: 'Evapotranspiration',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 1,
          selected: false,
          data: evapoHistory
        },
        {
          name: 'Temperature',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 2,
          selected: false,
          data: tempDataHistoric,
        }
      ],

    };
  }


  // bar plot of the irrigation adjustments
  const irrigation_adjustments = () => {

    return {

      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        x: 50,
        x2: 50
      },
      legend: {
        data: ['Plant Health', 'Cooling'],
        bottom: 'left'
      },
      title: {
        left: 'center',
        text: "Irrigation Adjustments",
      },
      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          mark: { show: true },
          dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ['line', 'bar'] },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      xAxis: [
        {
          type: 'category',

          axisTick: { show: false },
          data: irrigationDates
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Scaling factor (-)',
          nameLocation: 'middle',
          nameGap: 30,
          min: 0,
          max: 2,
        }
      ],
      series: [
        {
          name: 'Plant Health',
          type: 'bar',
          barGap: 0,
          emphasis: {
            focus: 'series'
          },
          data: irrigationAmounts
        },
        {
          name: 'Cooling',
          type: 'bar',
          barGap: 0.2,
          emphasis: {
            focus: 'series'
          },
          data: irrigationCoolAmounts
        },
      ]
    }
  }

  // exceedance plot of the soil moisture data
  const exceedance_plot = () => {

    return {
      tooltip: {
        trigger: 'axis',
        position: function (pt: any) {
          return [pt[0], '10%'];
        }
      },
      title: {
        left: 'center',
        text: "Full Soil Moisture Exceedance Plot",
      },
      xAxis: {
        name: 'Exceedance',
        nameLocation: 'middle',
        nameGap: 30,

      },
      yAxis: [{
        type: 'value',
        name: 'Soil Moisture (%VWC)',
        nameLocation: 'middle',
        nameGap: 30,
        min: 0,
        max: 50,
      }],
      grid: {
        y: 30,
        y2: 40,
        x: 50,
        x2: 50
      },
      series: [
        {
          symbolSize: 3,
          data: exceedanceData,
          type: 'scatter',
          large: true
        }
      ]
    };

  }

  const soil_moisture_plot = () => {

    return {
      tooltip: {
        trigger: 'axis',
        position: function (pt: any) {
          return [pt[0], '10%'];
        }
      },
      title: {
        left: 'center',
        text: "Full Soil Moisture History",
      },
      legend: {
        layout: 'horizontal',
        bottom: 0,
        selected: {
          'Temperature': false,
        }
      },
      grid: {
        y: 30,
        y2: 90,
        x: 50,
        x2: 100
      },

      toolbox: {
        show: true,
        orient: 'vertical',
        left: 'right',
        top: 'center',
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },

      xAxis: {
        type: 'time',
        boundaryGap: ['1%', '1%'],
        maxInterval: 3600 * 1000 * 24 * 30
      },
      yAxis: [{
        type: 'value',
        name: 'Soil Moisture (%VWC)',
        nameLocation: 'middle',
        nameGap: 30,
        min: 0,
        max: 50,
      },
      {
        type: 'value',
        name: 'Rainfall / Irrigation (mm)',
        position: 'right',
        nameLocation: 'middle',
        nameGap: 40,
        min: 0,
        max: 25,
      },],
      dataZoom: [
        {
          start: 0,
          end: 100,
          bottom: 35
        },
      ],
      series: [
        {
          name: 'Soil Moisture Historic',
          type: 'scatter',
          smooth: false,
          data: allSoilData,
          yAxisIndex: 0,
          symbolSize: 2,
          large: true,
        },
        {
          name: 'Rainfall Historic',
          type: 'line',
          smooth: false,
          data: allRainfallData,
          yAxisIndex: 1,
          symbolSize: 0,
        },
        {
          name: 'Irrigation Historic',
          type: 'line',
          smooth: false,
          data: allIrrigationData,
          yAxisIndex: 1,
          symbolSize: 0,
        },
        {
          name: 'Soil Moisture Target',
          type: 'line',
          smooth: false,
          symbol: 'none',
          yAxisIndex: 0,
          data: allTarget,
        },
      ],

    };

  }


  const setPredictionQuality = (value: string) => {

    if (value === 'Good') {
      return 'green'
    }
    else if (value === 'Fair') {
      return 'orange'
    }
    else if (value === 'Poor') {
      return 'red'
    }
  }

  const setDurationQuality = (value: number) => {

    if (value >= 90) {
      return 'green'
    }
    else {
      return 'red'
    }
  }




  return (
    <>

<SimpleDialog
        open={setTargetDlg}
        onClose={handleCloseTargetDlg}
        selectedValue={0.65}
      />

    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={handleClose}>

      {(guid_modified === values)

        ? <DialogTitle>{values}</DialogTitle>

        : <DialogTitle>{guid_modified}</DialogTitle>

      }


      <DialogContent>
        <DialogContentText>

        </DialogContentText>
        <Grid container >
          <Grid item xs={12}>
            <Grid container alignItems="stretch">

              <Grid item xs={12} lg={6} xl={6} sx={{ height: '400px' }}>
                {loadedTiles

                  ? (<Paper sx={{ m: '10px', textAlign: 'center', padding: '10px', height: '97%' }}>
                    <Grid container justifyContent={'center'}>
                      <Grid item xs={12}>
                        <Typography variant="h5" sx={{ mb: '20px' }}> Current Soil Moisture</Typography>
                      </Grid>
                      <Grid xs={3} sx={{ border: 'solid red 0px' }}>
                        <Box sx={{ backgroundColor: '', height: '300px', position: 'relative' }}>
                          <Typography variant="body2" sx={{ right: 4, bottom: ((curretSoilMoisture) / soilParameters[1][0]) * 300 - 12, position: 'absolute' }}> Current ({(curretSoilMoisture)}%) <br /> at {new Date(currentTime as string).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</Typography>
                        </Box>
                      </Grid>
                      <Grid xs={3} sx={{ display: 'flex', justifyContent: 'right', border: 'solid red 0px' }} >
                        <Box sx={{ backgroundColor: '#e0362d', width: '100%', height: '300px', position: 'relative' }}>
                          <Box sx={{ backgroundColor: '#1e76e8', width: '100%', height: (((soilParameters[1][0] - soilParameters[1][1]) / soilParameters[1][0]) * 300) }}></Box>
                          <Box sx={{ backgroundColor: '#19b52e', width: '100%', height: (((soilParameters[1][1] - soilParameters[1][2]) / soilParameters[1][0]) * 300) }}></Box>
                          <Box sx={{ position: 'absolute', backgroundColor: 'black', width: '100%', height: '5px', bottom: ((curretSoilMoisture) / soilParameters[1][0]) * 300 - 5 }}></Box>
                        </Box>
                      </Grid>
                      <Grid xs={4} sx={{ display: 'flex', justifyContent: 'left', border: 'solid red 0px' }} >
                        <Box sx={{ backgroundColor: '', width: '100%', height: '300px', position: 'relative', pl: '10px' }}>
                          <Typography variant="body2" sx={{ top: -10, position: 'absolute' }}> Saturation ({(soilParameters[1][0]).toFixed(1)}%)</Typography>
                          <Typography variant="body2" sx={{ top: (((soilParameters[1][0] - soilParameters[1][1]) / soilParameters[1][0]) * 300) - 10, position: 'absolute' }}> Field Capacity ({(soilParameters[1][1]).toFixed(1)}%)</Typography>
                          <Typography variant="body2" sx={{ top: (((soilParameters[1][0] - soilParameters[1][2]) / soilParameters[1][0]) * 300) - 10, position: 'absolute' }}> Wiliting Point ({(soilParameters[1][2]).toFixed(1)}%)</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>)

                  : (<Spinner />)}
              </Grid>

              <Grid item xs={12} md={6}>
                <Paper sx={{ m: '10px', textAlign: 'center', padding: '10px', height: '97%' }}>
                  <MapDialogue guid={values} setfunction={setGuid_modified} />
                </Paper>
              </Grid>

              <Grid item xs={12} md={6}>
                <Grid container>



                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    {loadedTiles

                      ? (<Paper sx={{ m: '10px', padding: '10px', height: '97%' }}>
                        <Grid container justifyContent={'center'} sx={{ padding: '10px' }}>
                          <Grid item xs={12}>
                            <Typography variant="h5" sx={{ mb: '10px' }}>  Sensor Quality
                            {/* <Button size="small" variant="text" onClick={handleClickChangeTarget} style={{ marginTop: '-5px' }}>
                              <EditIcon />
                            </Button> */}
                            </Typography>
                          </Grid>
                          <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2">Soil Sensor ID: </Typography>
                          </Grid>
                          <Grid xs={5} sx={{ display: 'flex', justifyContent: 'left' }}>

                                                      <Select
                                value={soilSensor}
                                onChange={handleChange}
                              >
                                {soilSensors.map((sensor) => (
                                  <MenuItem key={sensor} value={sensor}>
                                    {sensor}
                                  </MenuItem>
                                ))}
                              </Select>
                              </Grid>
                              <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                              <br/>
                              <a href="mailto:support@eratos.com?subject=Code Change Request&body=The sensor for the station {station_id} has been removed or needs to be update to Sensor ID xxxxxx;">
                                  Contact Support to update sensor
                                </a>
                                </Grid>
                          
                          <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2">Greenlist Status: </Typography>
                          </Grid>
                          <Grid xs={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2"> {greenlistStatus ? (<><div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: 'green', borderRadius: '10px' }}></div> (True)</>) : (<><div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: 'red', borderRadius: '10px' }}></div> (False)</>)} </Typography>
                          </Grid>
                          <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2">Prediction Quality: </Typography>
                          </Grid>
                          <Grid xs={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2"> <div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: setPredictionQuality(modelPrediction), borderRadius: '10px' }}></div> ({modelPrediction})</Typography>
                          </Grid>
                          <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2">Observations: </Typography>
                          </Grid>
                          <Grid xs={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2"> <div style={{ display: 'inline-block', width: '12px', height: '12px', backgroundColor: setDurationQuality(maxMinLength[2]), borderRadius: '10px' }}></div> ({maxMinLength[2]} Days)</Typography>
                          </Grid>
                          <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2">Max Value: </Typography>
                          </Grid>
                          <Grid xs={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2"> {maxMinLength[0]} %VWC</Typography>
                          </Grid>
                          <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2">Min Value: </Typography>
                          </Grid>
                          <Grid xs={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2"> {maxMinLength[1]} %VWC</Typography>
                          </Grid>
                          <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2">Current Soil Moisture:</Typography>
                          </Grid>
                          <Grid xs={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                            <Typography variant="body2">{curretSoilMoisture} %VWC</Typography>
                          </Grid>


                        </Grid>
                        < Grid xs={12} sx={{ textAlign: 'center' }}>
                          <a target="_blank" href={`https://senaps.eratos.com/#/app/stream/detail/meshnet.bicentennialpark.SoilMonitor.${soilSensor}-SM-SOPA.VWC`}> View on Senaps</a>
                        </Grid>

                      </Paper>)

                      : (<Spinner />)}
                  </Grid>



                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    {loadedTiles

                      ? (<Paper sx={{ m: '10px', textAlign: '', padding: '10px', height: '97%' }}>
                        <Grid container justifyContent={'center'} sx={{ padding: '10px' }}>
                          <Grid item xs={12}>
                            <Typography variant="h5" sx={{ mb: '10px' }}> Station Data 

                            
                            <Button size="small" variant="text"   style={{ marginTop: '-10px' }} >
                              <EditIcon />
                            </Button>
                           
                            </Typography>
                          </Grid>
                          {stationData[0].map((item: any, index: number) => {

                            let value = stationData[1][index]

                            // if value is null, return None
                            if (value === null) {
                              value = 'None'
                            }

                            // if value is a number, round to 2 decimal places
                            if (typeof value === 'number') {
                              value = value.toFixed(2)
                            }

                            return (
                              <>
                                <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                                  <Typography variant="body2">{item}:</Typography>
                                </Grid>
                                <Grid xs={3} sx={{ display: 'flex', justifyContent: 'left' }}>
                                  <Typography variant="body2">{value} {stationData[2][index]}</Typography>
                                </Grid>
                                <Grid xs={2} sx={{ display: 'flex', justifyContent: 'left' }}>
                          </Grid>

                              </>
                            )
                          })}
                        </Grid>
                      </Paper>)

                      : (<Spinner />)}
                  </Grid>

                  <Grid item xs={12} md={12} lg={6} xl={6}>
                    {loadedTiles

                      ? (<Paper sx={{ m: '10px', padding: '10px', height: '97%' }}>
                        <Grid container justifyContent={'center'} sx={{ padding: '10px' }}>
                          <Grid item xs={12}>
                            <Typography variant="h5" sx={{ mb: '10px' }}>  Soil Parameters
                            
                            <Button size="small" variant="text"   style={{ marginTop: '-10px' }} >
                              <EditIcon />
                            </Button>
                            
                            </Typography>
                          </Grid>


                          {soilParameters[0].map((item: any, index: number) => {

                            let value = soilParameters[1][index]

                            // if value is null, return None
                            if (value === null) {
                              value = 'None'
                            }

                            // if value is a number, round to 2 decimal places
                            if (typeof value === 'number') {
                              value = value.toFixed(2)
                            }

                            return (
                              <>
                                <Grid xs={7} sx={{ display: 'flex', justifyContent: 'left' }}>
                                  <Typography variant="body2">{item}:</Typography>
                                </Grid>
                                <Grid xs={5} sx={{ display: 'flex', justifyContent: 'left' }}>
                                  <Typography variant="body2">{value} {soilParameters[2][index]}</Typography>
                                </Grid>
                              </>

                            )

                          })}
                        </Grid>


                      </Paper>)
                      : (<Spinner />)}
                  </Grid>

                  <Grid item xs={12} md={6} lg={6} xl={6}>
                    {loadedTiles

                      ? (<Paper sx={{ m: '10px', padding: '10px', height: '97%' }}>
                        <Grid container justifyContent={'center'} sx={{ padding: '10px' }}>
                          <Grid item xs={12}>
                            <Typography variant="h5" sx={{ mb: '10px' }}>  Soil Trends</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" sx={{ mb: '10px' }}>The change in soil moisture over a given period in time from 9:00am this morning</Typography>
                          </Grid>

                          {trendData.map((item: any, index: number) => {

                            let message = ""

                            if (index === 0) {
                              message = "12 hour period "
                            }
                            else if (index === 1) {
                              message = "24 hour period "
                            }
                            else if (index === 2) {
                              message = "3 Days "
                            }
                            else if (index === 3) {
                              message = "7 Days "
                            }





                            return (<>< Grid item xs={7}>
                              <Typography variant="body2">{message}</Typography>

                            </Grid>

                            < Grid item xs={5}>
                              <Typography variant="body2">{item['v']['v'].toFixed(2)} %VWC</Typography>

                            </Grid>
                            </>
                            )
                          })}

                        </Grid>


                      </Paper>)
                      : (<Spinner />)}
                  </Grid>
                  </Grid>
                  </Grid>

                  
                  <Grid item xs={12} md={6} lg={6} xl={6}>
                  <Grid container>
                    
                  <Grid item xs={12}>
                    {loadedTiles

                      ? (<Paper sx={{ m: '10px', padding: '10px', height: '97%' }}>
                        <Grid container justifyContent={'center'} sx={{ padding: '10px' }}>
                          <Grid item xs={12}>
                            <Typography variant="h5" sx={{ mb: '10px' }}>Irrigation Target</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2" sx={{ mb: '10px' }}>The target is set as the fraction betweeen wilting point and field capacity</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2">Target: {soilMoistureTarget}

                            <Button size="small" variant="text" onClick={handleClickChangeTarget} style={{ marginTop: '-5px' }}>
                              <EditIcon />
                            </Button></Typography>
                          </Grid>
                        </Grid>
                      </Paper>)
                      : (<Spinner />)}
                  
                  </Grid>
                
              

              <Grid item xs={12}>
                {loadedTiles
                  ? (<Paper sx={{ m: '10px', textAlign: 'center', padding: '10px', height: '100%' }}>
                    <EChartsReact option={irrigation_adjustments()} />
                  </Paper>)
                  : (<Spinner />)}
              </Grid>
              </Grid>
              </Grid>

            </Grid>
          </Grid>




          <Grid item xs={12}>
            {loadedTiles
              ? (<Paper sx={{ m: '10px', textAlign: 'center', padding: '10px' }}>
                <EChartsReact option={getOption()} style={{ height: "500px" }} />
              </Paper>)
              : (<Spinner />)}
          </Grid>

          <Grid item xs={12} md={12}>
            {loadedTiles
              ? (<Paper sx={{ m: '10px', textAlign: 'center', padding: '10px', height: '97%' }}>
                <EChartsReact option={soil_moisture_plot()} />
              </Paper>)
              : (<Spinner />)}
          </Grid>
          <Grid item xs={12} md={12}>
            {loadedTiles

              ? (<Paper sx={{ m: '10px', textAlign: 'center', padding: '10px', height: '97%' }}>
                <EChartsReact option={exceedance_plot()} />
              </Paper>)
              : (<Spinner />)}
          </Grid>

        </Grid>


      </DialogContent >
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog >
    </>

  );

}
