import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import stations_to_sens from '../sens_to_station.json';
import station_to_irrigation from '../station_to_irrigation.json';
import zones from '../All_zones_test.json';
import { getMap, getSoilSensorObservations, getSoilMoistureStreams, getSoilSensorAdjustments, getSoilScalingStreams, getAllSoilSensorAdjustments } from '../SenapsCalls'
import EChartsReact from "echarts-for-react";
import * as echarts from 'echarts/core';
import '../style.css'
import ZoneDialogue from './zone_dialogue';
import { useParams } from 'react-router-dom';

import { Box, Paper, Typography, Switch, Grid, IconButton, Button, Drawer } from '@mui/material';
import { keys } from '@mui/system';
import { Console } from 'node:console';


interface props {
  open: boolean,
  openfunction: any
  setDataFunction: any
}


export default function StationSummary({ open, openfunction, setDataFunction }: props) {

  const [stationSummaryData, setStationSummaryData] = React.useState<any[]>([])
  const [stationSummaryTitles, setStationSummaryTitles] = React.useState<any[]>([])

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [currentDate, setCurrentDate] = React.useState("");
  const [stations, setStations] = React.useState<any>([]);
  const [irrigations, setIrrigations] = React.useState<any>([]);

  const [stationData, setStationData] = React.useState<any>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  const params = useParams();

  let guid = ""
  let has_guid = false
  if (params["guid"]) {
    guid = params["guid"]
    has_guid = true

  }

  let [open_zone_dialogue, setOpenZoneDialogue] = React.useState<boolean>(has_guid)
  let [zone_dialogue_data, setZoneDialogueData] = React.useState<any>(guid)


  const handleClickOpen = () => {
    openfunction(true);
  };

  const handleClose = () => {
    openfunction(false);
  };

  const handleZoneDialogueOpen = () => {

    if (open_zone_dialogue) {
      setOpenZoneDialogue(false)
    } else {
      setOpenZoneDialogue(true)
    }
  }

  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullWidth(event.target.checked);
  };

  const getNetworkStatus = async () => {

    let data = await getMap('7a4b4314-86b8-43a8-a2dd-047908cf52e1')


    //first the titles of the columns from the first row of data
    let titles = Object.keys(data[0])

    //drop the first element of title
    titles.shift()

    // in each title, replace the _ with a space
    titles = titles.map((title: any) => {
      return title.replace('_', ' ')
    })

    // filter only including the string 'scaling factor'
    titles = titles.filter((title: any) => {
      return title.includes('scaling factor')
    })

    //for each title split the string by space and take the last element
    titles = titles.map((title: any) => {

      let month = title.split(' ')[title.split(' ').length - 1].split('-')[1]
      let day = title.split(' ')[title.split(' ').length - 1].split('-')[2] 

      let date = [month, day].join('-')

      return date
    })


    let formated_data: any = []

    //loop through the data and format it
    data.forEach((station: any, index: any) => {

      let values = Object.values(station)

      values.shift()

      // insert second last element before 3rd element
      values.splice(2, 0, values[values.length - 2])


      
      //remove 4th last and 3rd last element
      values.splice(values.length - 6, 1)
      values.splice(values.length - 5, 1)
      values.splice(values.length - 4, 1)
      values.splice(values.length - 2, 1)
      values.splice(values.length - 1, 1)
      


      formated_data.push(values)
    })


    //sort the formated data by station id
    formated_data.sort((a: any, b: any) => {

      let a_console = a[0].split('_')[0].split('C')[1]
      let b_console = b[0].split('_')[0].split('C')[1]

      let a_sensor = a[0].split('_')[1]
      let b_sensor = b[0].split('_')[1]

      if (a_console === b_console) {
        return a_sensor - b_sensor
      }
      else {
        return a_console - b_console
      }

    })

    setStationSummaryData(formated_data)
    setStationSummaryTitles(titles)


  }

  React.useEffect(() => {

    let data = getNetworkStatus()

    setLoaded(true)



  }, [])


  const getColor = (value: number) => {

    if (value === 1) {
      return '#0d8c2f'
    } else if (value === 2) {
      return '#FFFF00'
    }
    else if (value === 0) {
      return '#FF0000'
    }
  }

  const getSoilwetness = (value: number) => {

    if (value === 1) {
      return '#f00e02'
    } else if (value === 2) {
      return '#f5a50f'
    }
    else if (value === 3) {
      return '#0d8c2f'
    }
    else if (value === 4) {
      return '#0fe2f5'
    }
    else if (value === 5) {
      return '#0c14f5'
    }
    else if (value === 6) {
      return 'grey'
    }
  }

  const getDayColour = (value: number) => {

    if (value < 90) {
      return '#FF0000'
    } else if (value > 90) {
      return '#0d8c2f'
    }
  }

  const getAdjustmentColour = (value: number) => {

    if (value > 1.5) {
      return '#00008B'
    } else if (value > 1) {
      return '#0000CD'
    }
    else if (value > 0.5) {
      return '#6495ED'
    }
    else if (value > 0) {
      return '#87CEEB'
    }
  }

  let console_id = 81

  const renderRow = (station: any) => {

    return (station.map((item: any, index: any) => {

      if (index > 15) {
        return
      }

      // if the item is a boolean then set to 1 or 0
      if (typeof item === 'boolean') {
        if (item) {
          item = 1
        }
        else {
          item = 0
        }
      }


      if (index === 0) {
        return (
          <div className="Item Link" onClick={() => {

            setZoneDialogueData(item)
            handleZoneDialogueOpen()

          }} style={{ cursor: 'pointer', }}>
            <a>{item}</a>
          </div>
        )
      }


      if ((index === 14) || (index === 12) || (index === 10) || (index === 8)) {  

        return (
          <div className="Item" style={{backgroundColor:'#f2df7e'}}>
            {item}
          </div>
        )

      }

      if (index === 14)  {

        return (
          <div className="Item" style={{borderRight: '1px solid lightgray'}}>
            {item}
          </div>
        )

      }

      return (
        <div className="Item" style={{}}>
          {item}
        </div>
      )

    }))

  }

  return (
    <>

      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Station Summary</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>

          {loaded

            ? (

              


              <div style={{ position: 'relative' }} >
                <div style={{ position: 'sticky', top: '0px', display: 'grid', width: 'inherit', gridTemplateColumns: 'repeat(16,1fr)', padding: '10px 0% 5px 0%', justifyContent: 'center', backgroundColor: 'white', zIndex: '10' }}>

                  <div className="Heading" style={{ gridRow: '1/span 3', borderTop: '1px solid black' }}> Station </div>
                  <div className="Heading" style={{ gridRow: '1/span 3',borderTop: '1px solid black' }}> Current Soil Conditions <br />(% VWC) </div>
                  <div className="Heading" style={{ gridRow: '1/span 3',borderTop: '1px solid black' }}> Scheduled Volume in the last 24 hours (m3)</div>
                  <div className="Heading" style={{ gridRow: '1/span 3',borderTop: '1px solid black' }}> Irrigation Volume Applied in the last 24 hours (m3)</div>
                  <div className="Heading" style={{ gridRow: '1/span 3',borderTop: '1px solid black' }}> Rainfall in the last 24 hours (mm) </div>

                  <div className="Heading" style={{ borderTop: '1px solid black', gridRow: '1/span 2', gridColumn: '6/span 2' }}> Soil Moisture Change From</div>
                  <div className="Heading" style={{ gridRow: '3/span 1' }}> Yesterday </div>
                  <div className="Heading" style={{ gridRow: '3/span 1' }}> 3 Days</div>
                  <div className="Heading" style={{ borderTop: '1px solid black', borderLeft: '1px solid black', gridRow: '1/span 1', gridColumn: '8/span 8' }}> Proposed Schedule</div>
                  <div className="Heading" style={{ gridRow: '1/span 3', borderRight: '1px solid black',borderTop: '1px solid black' }}> Issue </div>

                  {stationSummaryTitles.map((title: any, index: any) => {

                    if (index === 4) {

                      return (<>

                        <div className="Heading" style={{ borderRight: '1px solid black', gridRow: '2/span 1', gridColumn: 'span 2' }}>Night of {title}</div>
                        <div className="Heading" style={{ gridRow: '3/span 1', gridColumn: 'span 1' }}>Forecast Rainfall (mm)</div>
                        <div className="Heading" style={{ backgroundColor:'#f2df7e',borderRight: '1px solid black', gridRow: '3/span 1', gridColumn: 'span 1' }}>Required Irrigation</div>

                      </>

                      )


                    } else {

                      return (<>

                        <div className="Heading" style={{ gridRow: '2/span 1', gridColumn: 'span 2' }}>Night of {title}</div>
                        <div className="Heading" style={{gridRow: '3/span 1', gridColumn: 'span 1' }}>Forecast Rainfall (mm)</div>
                        <div className="Heading" style={{ backgroundColor:'#f2df7e',gridRow: '3/span 1', gridColumn: 'span 1' }}>Required Irrigation</div>

                      </>

                      )


                    }


                  })
                  }



                </div>

                <div style={{ position: 'relative', display: 'grid', width: '100%', gridTemplateColumns: 'repeat(16,1fr) ', padding: '0px 0% 0px 0%', justifyContent: 'left' }}>
                  {stationSummaryData.map((station: any) => {

                    

                    let console = station[0].split('_')[0].split('C')[1]

                    if ((console_id !== console) && (station[0] !== null)) {
                      console_id = console
                      return (
                        <>
                          <div className="Heading" style={{ borderTop: '1px solid black', borderRight: '1px solid black',gridColumn: "1/-1", marginTop: '10px', marginBottom: '2px' }}> Console {console}</div>

                          {renderRow(station)}
                        </>)
                    }

                    else {

                      return (
                        <>
                          {renderRow(station)}
                        </>)
                    }

                  })}
                </div>
              </div>

            )

            : (<></>)
          }






        </DialogContent >
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog >
      {loaded && 
      <ZoneDialogue open={open_zone_dialogue} openfunction={handleZoneDialogueOpen} values={zone_dialogue_data} />
      }
    </>

  );
}
