import React from 'react';
import logo from './logo.svg';
import './App.css';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Main from './screens/main'

import Location from './screens/location';
import Humidity from './screens/humidity';
import CoolTemp from './screens/feels_like_temp';
import CoolestLocation from './screens/coolest_temp';
import WarmestLocation from './screens/warmest_temp';
import HeatStress from './screens/heat_stress';
import WindSpeed from './screens/wind_speed';
import SoilMoisture from './screens/soil_moisture';
import ParkCooling from './screens/park_cooling';
import ZoneSummary from './screens/zone_info';
import Login from './screens/login';

let MainMemo = React.memo(Main);

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainMemo />} />
        <Route path="/location" element={<Location />} />
        <Route path="/humidity" element={<Humidity />} />
        <Route path="/feelslike" element={<CoolTemp />} />
        <Route path="/coolest" element={<CoolestLocation />} />
        <Route path="/heatstress" element={<HeatStress />} />
        <Route path="/windspeed" element={<WindSpeed />} />
        <Route path="/warmest" element={<WarmestLocation />} />
        <Route path="/soilmoisture" element={<SoilMoisture />} />
        <Route path="/parkcooling" element={<ParkCooling />} />
        <Route path="/stationSummary/:guid" element={<MainMemo />} />
        <Route path='/login' element={<Login/ >}/>


      </Routes>
    </BrowserRouter>
  );
}

export default App;
