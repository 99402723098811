import React, { useRef, useEffect, useState } from 'react';
import { getSoilMoistureStreams, getSoilMoistureData, getZoneMoistureStreams, getHistoricRain, getHistoricTempData, getMap, getTemperatureSensors, getLuftTempSensors, getHumiditySensors, getWindSpeedSensors } from '../SenapsCalls'

import ThermostatIcon from '@mui/icons-material/Thermostat';

import Tile from '../componants/tile';

function CoolestLocation() {

    let [minTemp, setMinTemp] = useState<any>()
    let [loaded, setLoaded] = useState<boolean>(false)


    let [parkSensors, setParkSensors] = useState<string[]>([
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.457_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.042_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.456_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.442_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.472_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.040_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.426_1221.Temperature',
    ])


    let [lastObservation, setLastObservation] = useState<Date>()


    // find min temperature and the location of that sensor
    let find_min = async (data: any[]) => {

        let date = Date.now()
        date = date - (60 * 60000)

        let min_temp: number = 100000000
        let min_location = ""
        let min_time = ""
        let min_sensor = ""

        let max_temp: number = -1000000
        let max_location = ""
        let max_time = ""
        let max_sensor = ""


        let all_sensors: any[] = []


        data.forEach(element => {


            let sensor_id = element['id']

            let senaps_date = new Date(element['resultsSummary']['last']['t'])
            let senaps_date_mili = senaps_date.getTime()

            let sensor_location = element['_embedded']['location'][0]['geojson']['coordinates']

            if (parkSensors.includes(sensor_id)) {
                all_sensors.push(sensor_location)
            }


            // ensure data is taken from the last 30 min
            if (senaps_date_mili > date) {

                if (parkSensors.includes(sensor_id)) {

                    if (element['resultsSummary']['last']['v']['v'] < min_temp) {

                        min_temp = parseFloat(element['resultsSummary']['last']['v']['v'].toFixed(2))
                        min_location = sensor_location
                        min_time = element['resultsSummary']['last']['t']
                        min_sensor = sensor_id
                    }

                    if (element['resultsSummary']['last']['v']['v'] > max_temp) {

                        max_temp = parseFloat(element['resultsSummary']['last']['v']['v'].toFixed(2))
                        max_location = sensor_location
                        max_time = element['resultsSummary']['last']['t']
                        max_sensor = sensor_id
                    }

                }

            }
        })


        var locations: { [key: string]: string; } = {

            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.457_1221.Temperature': 'Treillage',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.042_0222.Temperature': 'Treillage',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.456_0222.Temperature': 'Lake South',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.442_0222.Temperature': 'Lake South',
            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.472_1221.Temperature': 'Lake South',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.040_0222.Temperature': 'Concorde West',
            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.426_1221.Temperature': 'Village Green',

        };


        let all_data: any[] = await getHistoricTempData(min_sensor)

        let data_values: any[] = []

        all_data.forEach(element => {

            data_values.push([element['t'], element['v']['v']])

        })


        let reversed = data_values.reverse()

        return [parseFloat(min_temp.toFixed(2)), min_location, min_time, min_sensor, locations[min_sensor], reversed, all_sensors, max_location, max_time, max_sensor, locations[max_sensor], parseFloat(max_temp.toFixed(2))]

    }

    useEffect(() => {

        let setStreamData = async () => {

            let luft_temp_data: any[] = await getLuftTempSensors()
            let min_temp = await find_min(luft_temp_data)

            let utc_time = new Date(min_temp[2] as string)
            setLastObservation(utc_time)

            setMinTemp(min_temp)

            setLoaded(true)
        }

        setStreamData()

    }, [])

    if (!loaded) {
        return (<></>)
    }


    return (
        <>
            <Tile title='Coolest Temperature'
                data={`${minTemp[0]} \u00b0C`}
                message={`Coolest current temperature in Bicentennial Park observed in ${minTemp[4]} at ${lastObservation?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                icon={<ThermostatIcon />}
                loaded={loaded}
                layout={'widget'} />

        </>

    );
}

export default CoolestLocation;