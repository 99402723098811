import React, { useRef, useEffect, useState } from 'react';
import { getSoilMoistureStreams, getSoilMoistureData, getZoneMoistureStreams, getHistoricRain, getHistoricTempData, getMap, getTemperatureSensors, getLuftTempSensors, getHumiditySensors, getWindSpeedSensors } from '../SenapsCalls'

import AirIcon from '@mui/icons-material/Air';


import Tile from '../componants/tile';

function WindSpeed() {


    const mapContainer = useRef(null);
    const map_instance = useRef<any>(null);

    let [avgTemp, setAvgTemp] = useState<number>()
    let [avgHumid, setAvgHumid] = useState<number>()
    let [avgWind, setAvgWind] = useState<number>()
    let [avgSoil, setAvgSoil] = useState<number>()
    let [minTemp, setMinTemp] = useState<any>()
    let [loaded, setLoaded] = useState<boolean>(false)
    let [soilMoistureFeatures, setSoilMoistureFeatures] = useState<any[]>()
    let [temperatureFeatures, setTemperatureFeatures] = useState<any[]>()
    const [lng, setLng] = useState(151.077);
    const [lat, setLat] = useState(-33.849);
    const [zoom, setZoom] = useState(15);

    let [coolestFL, setCoolestFl] = useState<number>()
    let [parkFL, setParkFl] = useState<number>()

    let [showTempMap, setShowTempMap] = useState<boolean>(false)
    let [showSoilMap, setShowSoilMap] = useState<boolean>(false)
    let [feelsLikeMessage, setFeelsLikeMessage] = useState<string>()
    let [coolingMessage, setCoolingMessage] = useState<string>()
    let [tempDiff, setTempDiff] = useState<number>()

    let [parkSensors, setParkSensors] = useState<string[]>([
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.457_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.042_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.456_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.442_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.472_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.040_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.426_1221.Temperature',
    ])


    let [lastObservation, setLastObservation] = useState<Date>()


    let calculate_average = async (data: any[]) => {

        let temp_sens_list: any[] = []
        let temp_sens_data: number[] = []

        let date = Date.now()
        date = date - (30 * 60000)


        data.forEach(stream => {

            let senaps_date = new Date(stream['resultsSummary']['last']['t'])
            let senaps_date_mili = senaps_date.getTime()

            if (senaps_date_mili > date) {
                temp_sens_data.push(stream['resultsSummary']['last']['v']['v'])
                temp_sens_list.push(stream['id'])
            }
        })

        let temp_sens_list_string = temp_sens_list.toString()

        const average = temp_sens_data.reduce((a, b) => a + b, 0) / temp_sens_data.length;
        return parseFloat(average.toFixed(1))

    }


    const temp_diff = (data: any[]) => {

        let date = Date.now()
        date = date - (60 * 60000)

        let town_streams: number[] = []
        let park_streams: number[] = []

        data.forEach(stream => {

            let senaps_date = new Date(stream['resultsSummary']['last']['t'])
            let senaps_date_mili = senaps_date.getTime()

            if (senaps_date_mili > date) {

                if (parkSensors.includes(stream['id'])) {

                    park_streams.push(stream['resultsSummary']['last']['v']['v'])
                }

                else {
                    town_streams.push(stream['resultsSummary']['last']['v']['v'])
                }

            }

        })


        let park_avg = park_streams.reduce((a, b) => a + b, 0) / park_streams.length;
        let town_avg = town_streams.reduce((a, b) => a + b, 0) / town_streams.length;

        return parseFloat((town_avg - park_avg).toFixed(2))
    }

    // find min temperature and the location of that sensor
    let find_min = async (data: any[]) => {

        let date = Date.now()
        date = date - (60 * 60000)

        let min_temp: number = 100000000
        let min_location = ""
        let min_time = ""
        let min_sensor = ""

        let max_temp: number = -1000000
        let max_location = ""
        let max_time = ""
        let max_sensor = ""


        let all_sensors: any[] = []


        data.forEach(element => {


            let sensor_id = element['id']

            let senaps_date = new Date(element['resultsSummary']['last']['t'])
            let senaps_date_mili = senaps_date.getTime()

            let sensor_location = element['_embedded']['location'][0]['geojson']['coordinates']

            if (parkSensors.includes(sensor_id)) {
                all_sensors.push(sensor_location)
            }


            // ensure data is taken from the last 30 min
            if (senaps_date_mili > date) {

                if (parkSensors.includes(sensor_id)) {

                    if (element['resultsSummary']['last']['v']['v'] < min_temp) {

                        min_temp = parseFloat(element['resultsSummary']['last']['v']['v'].toFixed(2))
                        min_location = sensor_location
                        min_time = element['resultsSummary']['last']['t']
                        min_sensor = sensor_id
                    }

                    if (element['resultsSummary']['last']['v']['v'] > max_temp) {

                        max_temp = parseFloat(element['resultsSummary']['last']['v']['v'].toFixed(2))
                        max_location = sensor_location
                        max_time = element['resultsSummary']['last']['t']
                        max_sensor = sensor_id
                    }

                }

            }
        })


        var locations: { [key: string]: string; } = {

            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.457_1221.Temperature': 'Treillage',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.042_0222.Temperature': 'Treillage',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.456_0222.Temperature': 'Lake South and Waterview',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.442_0222.Temperature': 'Lake South and Waterview',
            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.472_1221.Temperature': 'Lake South and Waterview',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.040_0222.Temperature': 'Concorde West',
            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.426_1221.Temperature': 'Village Green and Approach',

        };


        let all_data: any[] = await getHistoricTempData(min_sensor)

        let data_values: any[] = []

        all_data.forEach(element => {

            data_values.push([element['t'], element['v']['v']])

        })


        let reversed = data_values.reverse()

        return [parseFloat(min_temp.toFixed(2)), min_location, min_time, min_sensor, locations[min_sensor], reversed, all_sensors, max_location, max_time, max_sensor, locations[max_sensor], parseFloat(max_temp.toFixed(2))]

    }


    const feelsLike = (temp: any, humidity: number, wind_speed: number) => {

        let pressure = (humidity / 100) * 6.105 * Math.exp((17.27 * temp) / (237.7 + temp))

        let feels_like_temp = temp + 0.33 * pressure - 0.7 * wind_speed - 4

        return parseFloat(feels_like_temp.toFixed(1))

    }


    useEffect(() => {

        let setStreamData = async () => {

            let wind_data: any[] = await getWindSpeedSensors()
            let wind_avg = await calculate_average(wind_data)
            setAvgWind(wind_avg)
            setLoaded(true)
        }

        setStreamData()

    }, [])

    if (!loaded) {
        return (<></>)
    }


    return (
        <>
            <Tile title='WindSpeed'
                            data={`${avgWind} m/s`}
                            message={'Average wind speed across the park'}
                            icon={<AirIcon />}
                            loaded={loaded}
                            layout={'widget'} />

        </>

    );
}

export default WindSpeed;