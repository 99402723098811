import * as React from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import stations_to_sens from '../sens_to_station.json';
import station_to_irrigation from '../station_to_irrigation.json';
import zones from '../All_zones_test.json';
import { getMap,getSoilSensorObservations, getSoilMoistureStreams, getSoilSensorAdjustments, getSoilScalingStreams, getAllSoilSensorAdjustments } from '../SenapsCalls'
import EChartsReact from "echarts-for-react";
import * as echarts from 'echarts/core';
import '../style.css'
import ZoneDialogue from './zone_dialogue';


import { Box, Paper, Typography, Switch, Grid, IconButton, Button, Drawer } from '@mui/material';
import { keys } from '@mui/system';

// const useStyles = makeStyles({

//   grid: {
//       display: 'grid',
//       gridTemplateColumns: 'max-content repeat(6,1fr)',
//       padding: '10px 15% 10px 15%',
//       justifyContent: 'center',

//   },

//   item: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       borderBottom: '1px solid lightgrey',
//       fontSize: '12px',
//       padding: '0px 2px',



//   },
//   item2: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       borderRadius: '50%',
//       width: '17px',
//       height: '17px',
//       margin: '2px 0px',
//       '&:hover': {
//           transform: 'scale(1.0)',
//           boxShadow: '0px 0px 5px 0px black',

//       }
//   },
//   title: {
//       display: 'flex',
//       justifyContent: 'right',
//       alignItems: 'center',

//       fontSize: '12px',
//       padding: '0px 2px',

//   },

//   label: {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       borderTop: '1px solid grey',
//       borderRight: '1px solid grey',
//       borderBottom: '1px solid grey',
//       fontSize: '12px',
//       padding: '10px 5px',
//       // writingMode: 'vertical-rl',
//       // WebkitTransform: 'rotate(180deg)',




//   },


//   label2: {
//       borderLeft: '1px solid black',
//       borderRight: '1px solid black',
//       borderTop: '1px solid black',
//       padding: '10px 0px',
//       fontSize: '12px',
//       fontWeight: 'bold',
//   },
//   overlap: {
//       gridArea: '1 / 1 / 10 / 10',
//       position: 'relative',
//       ZIndex: '1',
//   }

// });


interface props {
  open: boolean,
  openfunction: any
  setDataFunction: any
}


export default function NetworkStatus({ open, openfunction, setDataFunction }: props) {

  const [networkData, setNetworkData] = React.useState<any[]>([])

  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');
  const [currentDate, setCurrentDate] = React.useState("");
  const [stations, setStations] = React.useState<any>([]);
  const [irrigations, setIrrigations] = React.useState<any>([]);

  const [stationData, setStationData] = React.useState<any>([]);
  const [loaded, setLoaded] = React.useState<boolean>(false);

  let [open_zone_dialogue, setOpenZoneDialogue] = React.useState<boolean>(false)
  let [zone_dialogue_data, setZoneDialogueData] = React.useState<any>("")


  const handleClickOpen = () => {
    openfunction(true);
  };

  const handleClose = () => {
    openfunction(false);
  };

  const handleZoneDialogueOpen = () => {

    if (open_zone_dialogue) {
      setOpenZoneDialogue(false)
    } else {
      setOpenZoneDialogue(true)
    }
  }

  const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullWidth(event.target.checked);
  };

  const getNetworkStatus = async () => {

    let data = await getMap('ccf04e33-37f8-4250-bc31-8e2deaaa13ed')


    let formated_data: any = []

    data.forEach((station: any) => {

        let station_id: string = station['console']
        let sensor_id = station['sensor_id']
        let greenlist = station['greenlist']
        let current = station['current']
        let calibration = station['nse']
        let voltage = station['battery_voltage']
        let current_2_weeks = station['current_14_days'];

        let formated_station: any = [station_id,sensor_id,greenlist,current,current_2_weeks,calibration,voltage]
        formated_data.push(formated_station)

    
    })

    //sort the formated data by station id
    formated_data.sort((a: any, b: any) => {

        let a_console = a[0].split('_')[0].split('C')[1]
        let b_console = b[0].split('_')[0].split('C')[1]

        let a_sensor = a[0].split('_')[1]
        let b_sensor = b[0].split('_')[1]

        if (a_console === b_console) {
          return a_sensor - b_sensor
        }
        else {
          return a_console - b_console
        }

      })


    setNetworkData(formated_data)
   
  }

  React.useEffect(() => {

    let data = getNetworkStatus()

    setLoaded(true)

  },[])


  const getColor = (value: number) => {

    if (value === 1) {
      return '#0d8c2f'
    } else if (value === 2) {
      return '#FFFF00'
    }
    else if (value === 0) {
      return '#FF0000'
    }
  }

  const getCalibColor = (value: number) => {

    if (value > 0.7) {
      return '#0d8c2f'
    } else if (value > 0.5) {
      return '#f5a50f'
    }
    else {
      return '#FF0000'
    }
  }

  const getBatteryColor = (value: number) => {

    if (value > 2.5) {
      return '#0d8c2f'
    } else if (value > 2.3) {
      return '#f5a50f'
    }
    else {
      return '#FF0000'
    }
  }
  const getSoilwetness = (value: number) => {

    if (value === 1) {
      return '#f00e02'
    } else if (value === 2) {
      return '#f5a50f'
    }
    else if (value === 3) {
      return '#0d8c2f'
    }
    else if (value === 4) {
      return '#0fe2f5'
    }
    else if (value === 5) {
      return '#0c14f5'
    }
    else if (value === 6) {
      return 'grey'
    }
  }

  const getDayColour = (value: number) => {

    if (value < 90) {
      return '#FF0000'
    } else if (value > 90) {
      return '#0d8c2f'
    }
  }

  const getAdjustmentColour = (value: number) => {
     
      if (value > 1.5) {
        return '#00008B'
      } else if (value > 1) {
        return '#0000CD'
      }
      else if (value > 0.5) {
        return '#6495ED'
      }
      else if (value > 0) {
        return '#87CEEB'
      }
  }

  let console_id = 81

  const renderRow = (station: any) => {

    return (station.map((item: any, index: any) => {

        // console.log(item)

        // if the item is a boolean then set to 1 or 0
        if (typeof item === 'boolean') {
            if (item) {
                item = 1
            }
            else {
                item = 0
            }
        }

    //   if ((item === null) || (index === 0) || (index === 1)) {
    //     return (<></>)
    //   }

      if (index === 0) {
        return (
          <div className="Item Link" onClick={() => {

            setZoneDialogueData(item)
            handleZoneDialogueOpen()

          }} style={{ cursor: 'pointer', }}>
            <a>{item}</a>
          </div>
        )
      }


      if (((index >= 2) && (index <= 3))) {
        return (
          <div className="Item" style={{}}>
            <div className='TrafficLight' style={{ backgroundColor: getColor(item) }}></div>
          </div>
        )
      }
      else if (index === 4) {
        return (
          <div className="Item" style={{}}>
            <div className='TrafficLight' style={{ backgroundColor: getColor(item) }}></div>
          </div>
        )
      }
      else if (index === 5) {
        return (
          <div className="Item" style={{}}>
            <div className='TrafficLight' style={{ backgroundColor: getCalibColor(item) }}></div>
          </div>
        )
      }
      else if (index == 6){
        return (
          <div className="Item" style={{}}>
            <div className='TrafficLight' style={{ backgroundColor: getBatteryColor(item) }}></div>
          </div>
        )
 
      }
      // check if the item is a number and format to 2 decimal points
      else if (typeof item === 'number') {

        return (
          <div className="Item" style={{}}>
            {Number(item).toFixed(2)}
          </div>
        )
      }

      return (
        <div className="Item" style={{}}>

          {item}
        </div>
      )
      
    }))

  }

  return (
    <>

      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Network Status</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>

          {loaded

            ? (


              <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }} >
                <div style={{ position: 'fixed', display: 'grid', height:'70px', gridTemplateColumns: 'repeat(7,150px)', padding: '10px 5% 5px 5%', justifyContent: 'center', backgroundColor: 'white', zIndex: '10' }}>
                  <div className="Heading" style={{borderTop: '1px solid black'}}> Station </div>
                  <div className="Heading" style={{borderTop: '1px solid black'}}> Soil Sensor </div>
                  <div className="Heading" style={{borderTop: '1px solid black'}}> Green List </div>
                  <div className="Heading" style={{borderTop: '1px solid black'}}> Reported in 24 hours </div>
                  <div className="Heading" style={{borderTop: '1px solid black'}}> Reported in last 2 weeks </div>
                  <div className="Heading" style={{borderTop: '1px solid black'}}> Calibration Quality </div>
            
                  <div className="Heading" style={{borderTop: '1px solid black',borderRight: '1px solid black'}}> Battery Voltage</div>
                  

            
                </div>


                <div style={{ position: 'relative', display: 'grid', gridTemplateColumns: 'repeat(7,150px) ', padding: '65px 5% 0px 5%', justifyContent: 'left' }}>

                  {networkData.map((station: any) => {

                    let console = station[0].split('_')[0].split('C')[1]

                    if ((console_id !== console) && (station[0] !== null)) {
                      console_id = console
                      return (
                        <>
                          <div className="Heading" style={{ borderTop: '1px solid black', borderRight: '1px solid black', gridColumn: "1/-1", marginTop: '10px', marginBottom: '2px' }}> Console {console}</div>

                          {renderRow(station)}
                        </>)
                    }

                    else {

                      return (
                        <>
                          {renderRow(station)}
                        </>)
                    }

                  })}
                </div>
              </div>

            )

            : (<></>)
          }






        </DialogContent >
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog >
      <ZoneDialogue open={open_zone_dialogue} openfunction={handleZoneDialogueOpen} values={zone_dialogue_data} />
    </>

  );
}
