import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Switch } from '@mui/material';
import SummaryDialogue from './summary_dialogue';


let drawerWidth = 400;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: -drawerWidth,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));

const isLoggedIn = (): boolean  => {
  let token = localStorage.getItem("token");
  if (!token) {
      return false
  }
  return true
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));


interface props {
  slider_width: number,
  open: boolean,
  setOpen: any,
  tempmap: boolean,
  setTempmap: any,
  soilmap: boolean,
  setSoilmap: any,
  soilSensor: boolean,
  setSoilSensor: any,
  tempMeshnetSensor: boolean,
  setTempMeshnetSensor: any,
  tempLuftSensor: boolean,
  setTempLuftSensor: any,
  irrigation: boolean,
  setIrrigation: any,
  contours: boolean,
  setContours: any,
  summary: boolean,
  setSummary: any,
  soilConditions: boolean,
  setSoilConditions: any,
  senorPredictions: boolean,
  setSensorPredictions: any,
  networkStatus: boolean,
  setNetworkStatus: any,
  stationSummary: boolean,
  setStationSummary: any,

}

export default function PersistentDrawerRight({ 
  slider_width,
  open, setOpen,
  tempmap, setTempmap,
  soilmap, setSoilmap,
  soilSensor, setSoilSensor,
  tempMeshnetSensor, setTempMeshnetSensor,
  tempLuftSensor, setTempLuftSensor, 
  irrigation, setIrrigation,
  contours, setContours,
  summary, setSummary,
  soilConditions, setSoilConditions,
  senorPredictions,setSensorPredictions,
  networkStatus,setNetworkStatus,
  stationSummary,setStationSummary}: props) {



  const theme = useTheme();
  drawerWidth = slider_width;

  


  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  

  return (
    <Box sx={{}}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Typography variant="h6">Maps</Typography>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setSoilmap}
              checked={soilmap} />
          } >
            <ListItemText primary="Soil Moisture Map" secondary={"Interpolated soil moisture map using sensor data from the last hour."} />
          </ListItem>
          <Divider sx={{ mb: '5px' }} />
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setTempmap}
              checked={tempmap} />
          } >
            <ListItemText primary="Temperature Map" secondary={"Interpolated temperature map using sensor data from the last hour. "} />
          </ListItem>
          {isLoggedIn() &&
          <div>
              <Divider sx={{ mb: '5px' }} />
              <ListItem secondaryAction={
                <Switch edge="end"
                  onClick={setIrrigation}
                  checked={irrigation} />
              } >
                <ListItemText primary="Irrigation Zone Map" secondary={"Park irrigation zones."} />
              </ListItem>
            </div>
          }

          <Divider sx={{ mb: '5px' }} />
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setContours}
              checked={contours} />
          } >
            <ListItemText primary="Contour Map" secondary={"2m contour map of the park."} />
          </ListItem>
          <Divider sx={{ mb: '5px' }} />
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setSoilSensor}
              checked={soilSensor} />
          } >
            <ListItemText primary="Soil Sensors" secondary={"All sensors that have reported a value in the last hour."} sx={{ pr: '50px' }} />
          </ListItem>
          <Divider sx={{ mb: '5px' }} />
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setTempLuftSensor}
              checked={tempLuftSensor} />
          } >
            <ListItemText primary="Lufft Temperature Sensors" secondary={"All sensors that have reported a value in the last hour."} />
          </ListItem>
          <Divider sx={{ mb: '5px' }} />
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setTempMeshnetSensor}
              checked={tempMeshnetSensor} />
          } >
            <ListItemText primary="Senstick Temperature Sensors" secondary={"All sensors that have reported a value in the last hour"} />
          </ListItem>
          {isLoggedIn() &&
            <div>
            <Divider sx={{ mb: '5px' }} />
            <ListItem secondaryAction={
              <Switch edge="end"
                onClick={setNetworkStatus}
                checked={networkStatus} />
            } >
              <ListItemText primary="Network Status" secondary={"Show current state of soil sensors network"} />
            </ListItem>

          <Divider sx={{ mb: '5px' }} />
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setStationSummary}
              checked={stationSummary} />
          } >
            <ListItemText primary="Station Summary" secondary={"Show a summary table of live data and planned irrigation adjustments"} />
          </ListItem>
          {/* <Divider sx={{ mb: '5px' }} />
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setSummary}
              checked={summary} />
          } >
            <ListItemText primary="Show Park Summary" secondary={"Show a summary table of live summary data and planned irrigation adjustments"} />
          </ListItem> */}
          <Divider sx={{ mb: '5px' }} />
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setSoilConditions}
              checked={soilConditions} />
          } >
            <ListItemText primary="Show Soil Conditions" secondary={"Show live soil moisture conditions"} />
          </ListItem>
          <Divider sx={{ mb: '5px' }} />
          <ListItem secondaryAction={
            <Switch edge="end"
              onClick={setSensorPredictions}
              checked={senorPredictions} />
          } >
            <ListItemText primary="Zone Predictions" secondary={""} />
          </ListItem>
          </div>
          }
          
        </List>
      </Drawer>
      
    </Box>
    
  );
}