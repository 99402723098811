import React, { FC, useEffect, useState, } from "react";
import { Box, Paper, Typography, Switch, Grid, IconButton, Button } from '@mui/material';
import { Oval } from 'react-loader-spinner'

interface IFMChartProps {

}

const Spinner: FC<IFMChartProps> = () => {

    return (

        <Paper sx={{ m: '10px', textAlign: '', padding: '10px', height: '97%' }}><Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <Oval
                      height={80}
                      width={80}
                      color="#76C7F0"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel='oval-loading'
                      secondaryColor="#1BB4FF"
                      strokeWidth={6}
                      strokeWidthSecondary={6}

                    />

                  </Box></Paper>
    )


}


export default Spinner;