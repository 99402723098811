import axios from 'axios';

const {REACT_APP_API_URL} = process.env;
const getAuth = (): string  => {
    let token = localStorage.getItem("token");
    if (!token) {
        token = ""
    }

    return  "Bearer " + token
}
export async function get<T>(
    path: string,
    params?: {[key: string]: string}
): Promise<T> {
    let query = "";
    if (params) {
        const urlParams = new URLSearchParams(params);
        query = `?${urlParams.toString()}`;
    }
    const {data} = await axios.get(path + query, {
        headers: {
            Authorization: getAuth()
        }
    });
    return data;
}


export async function post<T>(path: string, json: T):
    Promise<T> {
    const {data} = await axios.post(path, json,
        {
            headers: {
                Authorization: getAuth()
            }
        });
    return data;
}

export async function postv2<T, RT>(path: string, json: T):
    Promise<RT> {
    const {data} = await axios.post(path, json,
        {
            headers: {
                Authorization: getAuth()
            }
        });
    return data;
}



export async function login<T>(path: string, json: T):
    Promise<T> {
    const {data} = await axios.post(path, json,
        {
        });
    return data;
}


export async function download(path: string): Promise<{ data: Blob, filename: string }> {
    const response = await axios.get(path,
        {
            headers: {
                Authorization: getAuth()

            },
            responseType: 'blob'
        });
    // get filename from Content-Disposition header
    let filename = 'file';
    const contentDisposition = response.headers['content-disposition'];
    if (contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(contentDisposition);
        if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }

    return { data: response.data, filename };

}

export async function checkAuth(): Promise<boolean> {
    const {data} = await axios.get(REACT_APP_API_URL + "user/check",
        {
            headers: {
                Authorization: getAuth()
            }
        });
    return data;
}



export async function upload<T>(path: string, file: T):
    Promise<T> {
    const {data} = await axios.post(path, file,
        {
            headers: {
                Authorization: "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTYxNzgzODk4OSwianRpIjoiMTkyNzZmMjctOTJhOC00ZGE3LWIzY2ItYzgzY2Q4NjkyZjZmIiwibmJmIjoxNjE3ODM4OTg5LCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoxfQ.3kUM5VPQsjYQgi92lgJJFFsGIDZ1Bx35mRmNPiABAUxBnuewKjMUcPg-IVa3SBS9rtMyhMXCgowVLn511FbG_g",
                'Content-Type': 'multipart/form-data'
            }
        });
    return data;
}