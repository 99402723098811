import React, { useRef, useEffect, useState, useMemo } from 'react';
import logo from './logo.svg';
import ButtonAppBar from '../componants/appBar';
import { Box, Paper, Typography, Switch, Grid, IconButton, Button, Drawer } from '@mui/material';
import Slider from '@mui/material/Slider';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { getSoilMoistureStreams, getData, getSoilMoistureData, getZoneMoistureStreams, getHistoricRain, getRainfallForecast, getHistoricTempData, getMap, getTemperatureSensors, getLuftTempSensors, getHumiditySensors, getWindSpeedSensors } from '../SenapsCalls'
import { MapSharp, SettingsInputAntennaTwoTone, StreamSharp } from '@mui/icons-material';
import EChartsReact from "echarts-for-react";
import data from '../all_stations.json'
import ThermostatIcon from '@mui/icons-material/Thermostat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WavesIcon from '@mui/icons-material/Waves';
import AirIcon from '@mui/icons-material/Air';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import MovingIcon from '@mui/icons-material/Moving';
import CloudIcon from '@mui/icons-material/Cloud';
import AlertDialogSlide from '../componants/dialogue';
import Loading from '../componants/loading';
import MenuIcon from '@mui/icons-material/Menu';
import FilterListIcon from '@mui/icons-material/FilterList';
import CssBaseline from '@mui/material/CssBaseline';
import { parse } from 'node:path/win32';
import { features } from 'node:process';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ZoneDialogue from '../componants/zone_dialogue';


import Tile from '../componants/tile';
import PersistentDrawerRight from '../componants/drawer';
import { positions } from '@mui/system';
import zones from '../All_zones.json';
import contours from '../contours.json';

interface props {
    format: string
}



function TileBar({ format }: props) {

    let [loaded, setLoaded] = useState(false)
    let [loadedSoil, setLoadedSoil] = useState(false)
    let [loadedTemp, setLoadedTemp] = useState(false)
    let [loadedWindHumidity, setLoadedWindHumidity] = useState(false)


    let [avgTemp, setAvgTemp] = useState<number>(0)
    let [avgHumid, setAvgHumid] = useState<number>(0)
    let [avgWind, setAvgWind] = useState<number>(0)
    let [avgSoil, setAvgSoil] = useState<number>(0)
    let [minTemp, setMinTemp] = useState<any>([0])
    let [coolestFL, setCoolestFl] = useState<number>(0)
    let [parkFL, setParkFl] = useState<number>(0)
    let [feelsLikeMessage, setFeelsLikeMessage] = useState<string>()
    let [heatStress, setHeatStress] = useState<{ [key: string]: string }>({
        'level': 'No stress',
        'description': 'The current heat stress level is low.'
    })
    let [coolingMessage, setCoolingMessage] = useState<string>()
    let [tempDiff, setTempDiff] = useState<number>(0)
    let [lastObservation, setLastObservation] = useState<Date>()
    let [rainfall, setRainfall] = useState<string>("")

    let [parkSensors, setParkSensors] = useState<string[]>([
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.457_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.042_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.456_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.442_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.472_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.040_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.426_1221.Temperature',
        "meshnet.bicentennialpark.TempHumidity.SENS0001-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0002-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0003-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0004-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0005-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0006-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0007-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0008-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0009-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0010-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0011-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0012-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0013-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0014-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0015-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0016-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0017-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0018-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0019-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0020-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0021-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0022-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0023-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0024-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0025-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0026-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0027-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0028-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0029-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0030-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0031-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0032-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0033-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0034-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0035-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0036-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0037-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0038-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0039-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0040-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0041-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0042-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0043-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0044-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0045-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0046-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0047-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0048-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0049-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0050-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0052-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0053-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0054-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0055-TRH-SOPA.Temperature"
    ])

    //////////////////// Functions to process the data //////////////////////

    let calculate_average = async (data: any[]) => {

        let temp_sens_list: any[] = []
        let temp_sens_data: number[] = []
        let temp_sens_location: number[][] = []

        let date = Date.now()
        date = date - (30 * 60000)


        data.forEach(stream => {

            let senaps_date = new Date(stream['resultsSummary']['last']['t'])
            let senaps_date_mili = senaps_date.getTime()

            if (senaps_date_mili > date) {
                temp_sens_data.push(stream['resultsSummary']['last']['v']['v'])
                temp_sens_list.push(stream['id'])

                // get the location of the sensor
                // let sensor_location = stream['_embedded']['location'][0]['geojson']['coordinates']
                // temp_sens_location.push(sensor_location)



            }
        })


        let temp_sens_list_string = temp_sens_list.toString()

        const average = temp_sens_data.reduce((a, b) => a + b, 0) / temp_sens_data.length;
        return parseFloat(average.toFixed(1))

    }

    const temp_diff = (data: any[]) => {

        let date = Date.now()
        date = date - (60 * 60000)

        let town_streams: number[] = []
        let park_streams: number[] = []

        data.forEach(stream => {

            let senaps_date = new Date(stream['resultsSummary']['last']['t'])
            let senaps_date_mili = senaps_date.getTime()

            if (senaps_date_mili > date) {

                if (parkSensors.includes(stream['id'])) {

                    park_streams.push(stream['resultsSummary']['last']['v']['v'])
                }

                else {
                    town_streams.push(stream['resultsSummary']['last']['v']['v'])
                }

            }

        })


        let park_avg = park_streams.reduce((a, b) => a + b, 0) / park_streams.length;
        let town_avg = town_streams.reduce((a, b) => a + b, 0) / town_streams.length;

        let park_min = Math.min(...park_streams)
        let town_max = Math.max(...town_streams)

        return parseFloat((town_max - park_min).toFixed(2))
    }


    // find min temperature and the location of that sensor
    let find_min = async (data: any[]) => {



        let date = Date.now()
        date = date - (60 * 60000)

        let min_temp: number = 100000000
        let min_location = ""
        let min_time = ""
        let min_sensor = ""

        let max_temp: number = -1000000
        let max_location = ""
        let max_time = ""
        let max_sensor = ""


        let all_sensors: any[] = []


        data.forEach(element => {


            let sensor_id = element['id']

            let senaps_date = new Date(element['resultsSummary']['last']['t'])
            let senaps_date_mili = senaps_date.getTime()

            let sensor_location = element['_embedded']['location'][0]['geojson']['coordinates']

            if (parkSensors.includes(sensor_id)) {
                all_sensors.push(sensor_location)
            }


            // ensure data is taken from the last 30 min
            if (senaps_date_mili > date) {

                if (parkSensors.includes(sensor_id)) {

                    if (element['resultsSummary']['last']['v']['v'] < min_temp) {

                        min_temp = parseFloat(element['resultsSummary']['last']['v']['v'].toFixed(2))
                        min_location = sensor_location
                        min_time = element['resultsSummary']['last']['t']
                        min_sensor = sensor_id
                    }

                    if (element['resultsSummary']['last']['v']['v'] > max_temp) {

                        max_temp = parseFloat(element['resultsSummary']['last']['v']['v'].toFixed(2))
                        max_location = sensor_location
                        max_time = element['resultsSummary']['last']['t']
                        max_sensor = sensor_id
                    }

                }

            }
        })


        var locations: { [key: string]: string; } = {

            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.457_1221.Temperature': 'Treillage',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.042_0222.Temperature': 'Treillage',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.456_0222.Temperature': 'Lake South and Waterview',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.442_0222.Temperature': 'Lake South and Waterview',
            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.472_1221.Temperature': 'Lake South and Waterview',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.040_0222.Temperature': 'Concorde West',
            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.426_1221.Temperature': 'Village Green and Approach',

        };


        let all_data: any[] = await getHistoricTempData(min_sensor)

        let data_values: any[] = []

        all_data.forEach(element => {

            data_values.push([element['t'], element['v']['v']])

        })


        let reversed = data_values.reverse()

        return [parseFloat(min_temp.toFixed(2)), min_location, min_time, min_sensor, locations[min_sensor], reversed, all_sensors, max_location, max_time, max_sensor, locations[max_sensor], parseFloat(max_temp.toFixed(2))]

    }

    const feelsLike = (temp: any, humidity: number, wind_speed: number) => {

        let pressure = (humidity / 100) * 6.105 * Math.exp((17.27 * temp) / (237.7 + temp))

        let feels_like_temp = temp + 0.33 * pressure - 0.7 * wind_speed - 4

        return parseFloat(feels_like_temp.toFixed(1))

    }

    const calculate_heat_stress = (temp: any, humidity: number, wind_speed: number) => {
        const fl = feelsLike(temp, humidity, wind_speed)
        let heat_stress = {
            'level': 'No stress',
            'description': 'The current heat stress level is low.'
        }

        if (fl > 46) {
            heat_stress['level'] = 'Extreme'
            heat_stress['description'] = 'Take precautions to avoid heat stroke.'

        }
        else if (fl > 38) {
            heat_stress['level'] = 'Very strong'
            heat_stress['description'] = 'Severely curtail physical activity and drink plenty of water.'
        }
        else if (fl > 32) {
            heat_stress['level'] = 'Strong'
            heat_stress['description'] = 'Reduce physical activity and drink plenty of water.'
        }
        else if (fl > 26) {
            heat_stress['level'] = 'Moderate'
            heat_stress['description'] = 'Reduce physical activity and drink plenty of water.'
        }
        else {
            heat_stress['level'] = 'No stress'
            heat_stress['description'] = 'The current heat stress level is low.'
        }

        return heat_stress

    }


    let setStreamData = async () => {

        let combined_temp_sensors: any[] = []

        // we need to get the park average temperature by combining the meshnet and luft temp sensors
        // luft_temp_data.forEach((sensor, index) => {

        //     if (parkSensors.includes(sensor['id'])) {
        //         combined_temp_sensors.push(sensor)
        //     }

        // })

        // combined_temp_sensors = [...combined_temp_sensors, ...temp_data]

        // let temp_avg = await calculate_average(combined_temp_sensors)
        // let humid_avg = await calculate_average(humid_data)
        // let wind_avg = await calculate_average(wind_data)
        // let min_temp = await find_min(luft_temp_data)
        // let soil_avg = await calculate_average(soil_data)


        let coolest_feels_like = feelsLike(minTemp[0], avgHumid, avgWind)
        let message = "Wind is making it feel cooler"

        if (minTemp[0] < coolest_feels_like) {
            message = "High humidity is making it feel warmer"
        }
        setFeelsLikeMessage(message)
        setHeatStress(calculate_heat_stress(minTemp[0], avgHumid, avgWind))
        setCoolestFl(coolest_feels_like)

        // setAvgSoil(soil_avg)

        setLoaded(true)


    }

    let soilMoisture = async () => {
        let soil_data: any[] = await getSoilMoistureStreams()
        let soil_avg = await calculate_average(soil_data)
        setAvgSoil(soil_avg)
        setLoadedSoil(true)
    }

    let min_temp = async () => {
        let luft_temp_data: any[] = await getLuftTempSensors()
        let min_temp = await find_min(luft_temp_data)

        let town_to_park_temp_diff = temp_diff(luft_temp_data)

        let cooling_message = `The park is ${town_to_park_temp_diff} \u00b0C cooler than the town centre`

        if (town_to_park_temp_diff < 0) {
            cooling_message = `The park is ${town_to_park_temp_diff * -1} \u00b0C warmer than the town centre`
        }

        let utc_time = new Date(min_temp[2] as string)
        setLastObservation(utc_time)

        setTempDiff(town_to_park_temp_diff)
        setCoolingMessage(cooling_message)
        setMinTemp(min_temp)
        setLoadedTemp(true)
    }

    let wind_and_humidity = async () => {
        let humid_data: any[] = await getHumiditySensors()
        let wind_data: any[] = await getWindSpeedSensors()

        let humid_avg = await calculate_average(humid_data)
        let wind_avg = await calculate_average(wind_data)

        setAvgHumid(humid_avg)
        setAvgWind(wind_avg)
        setLoadedWindHumidity(true)
    }

    let getForecastRainfall = async () => {

        // get the date right now and convert to iso string
        let now = new Date()
        let now_iso = now.toISOString()

        // get the date 24 hours from now and convert to iso string
        let tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000)
        let tomorrow_iso = tomorrow.toISOString()

        let forecast_data = await getRainfallForecast(now_iso, tomorrow_iso)

        // get the rainfall forecast for the next 24 hours
        let rainfall_predicted: number = 0

        forecast_data.forEach((element: any) => {
         
            rainfall_predicted += element['v']['v']
        })

        setRainfall(rainfall_predicted.toFixed(2))

    }

    // useMemo(() => { setStreamData() }, [])

    useEffect(() => {
        min_temp()
        soilMoisture()
        wind_and_humidity()
        getForecastRainfall()
    }, [])

    useEffect(() => {

        if (loadedTemp && loadedWindHumidity) {
            setStreamData()
        }

    }, [loadedWindHumidity, loadedTemp])




    return (
        <>
            <Tile title='Coolest Location'
                data={minTemp[4]}
                message={'Currently the coolest location in Bicentennial Park.'}
                icon={<LocationOnIcon />}
                loaded={loadedTemp}
                layout={format} />

            <Tile title='Warmest Location'
                data={minTemp[10]}
                message={'Currently the warmest location in Bicentennial Park.'}
                icon={<LocationOnIcon />}
                loaded={loadedTemp}
                layout={format} />

            <Tile title='Coolest Temperature'
                data={`${minTemp[0]} \u00b0C`}
                message={`Coolest current temperature in Bicentennial Park observed in ${minTemp[4]} at ${lastObservation?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                icon={<ThermostatIcon />}
                loaded={loadedTemp}
                layout={format}
            />

            <Tile title='Warmest Temperature'
                data={`${minTemp[11]} \u00b0C`}
                message={`Warmest current temperature in Bicentennial Park observed in ${minTemp[10]} at ${lastObservation?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}
                icon={<ThermostatIcon />}
                loaded={loadedTemp}
                layout={format}
            />

            <Tile title='Heat Stress'
                data={heatStress["level"]}
                message={heatStress["description"]}
                icon={<WbSunnyIcon />}
                loaded={loaded}
                layout={format}
                additional={<Grid item xs={12}> <div style={{ width: "100%", height: 5, backgroundImage: "linear-gradient(to right, blue, green, yellow, orange, red)" }}></div></Grid>} />

            <Tile title='Feels Like Temperature'
                data={`${coolestFL} \u00b0C`}
                message={feelsLikeMessage}
                icon={<ThermostatIcon />}
                loaded={loaded}
                layout={format} />

            <Tile title='Park Cooling'
                data={`${tempDiff} \u00b0C`}
                message={coolingMessage}
                icon={<WavesIcon />}
                loaded={loadedTemp}
                layout={format} />

            <Tile title='Humidity'
                data={`${avgHumid} %`}
                message={'Average humidity across the park'}
                icon={<WavesIcon />}
                loaded={loadedWindHumidity}
                layout={format} />

            <Tile title='WindSpeed'
                data={`${avgWind} m/s`}
                message={'Average wind speed across the park'}
                icon={<AirIcon />}
                loaded={loadedWindHumidity}
                layout={format} />

            <Tile title='Forcast Rainfall'
                data={`${rainfall} mm`}
                message={'Forecast rainfall for the next 24 hours'}
                icon={<CloudIcon />}
                loaded={loadedSoil}
                layout={format} />

            <Tile title='Soil Moisture'
                data={`${avgSoil} %`}
                message={'Average volumetric soil moisture across the park'}
                icon={<MovingIcon />}
                loaded={loadedSoil}
                layout={format}
            />
        </>
    )

}

export default TileBar