import React, { useRef, useEffect, useState } from 'react';
import logo from './logo.svg';
import ButtonAppBar from '../componants/appBar';
import { Box, Paper, Typography, Switch, Grid, IconButton, Button, Drawer } from '@mui/material';
import Slider from '@mui/material/Slider';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { getSoilMoistureStreams, getData, getSoilMoistureData, getZoneMoistureStreams, getHistoricRain, getHistoricTempData, getMap, getTemperatureSensors, getLuftTempSensors, getHumiditySensors, getWindSpeedSensors } from '../SenapsCalls'
import { MapSharp, SettingsInputAntennaTwoTone, StreamSharp } from '@mui/icons-material';
import EChartsReact from "echarts-for-react";
import data from '../all_stations.json'
import ThermostatIcon from '@mui/icons-material/Thermostat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WavesIcon from '@mui/icons-material/Waves';
import AirIcon from '@mui/icons-material/Air';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import MovingIcon from '@mui/icons-material/Moving';
import AlertDialogSlide from '../componants/dialogue';
import Loading from '../componants/loading';
import MenuIcon from '@mui/icons-material/Menu';
import FilterListIcon from '@mui/icons-material/FilterList';
import CssBaseline from '@mui/material/CssBaseline';
import { parse } from 'node:path/win32';
import { features } from 'node:process';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ZoneDialogue from '../componants/zone_dialogue';


import Tile from '../componants/tile';
import PersistentDrawerRight from '../componants/drawer';
import TileBar from '../componants/tilebar';
import { positions } from '@mui/system';
import zones from '../All_zones_test.json';
import contours from '../contours.json';
import SummaryDialogue from './summary_dialogue';

import { Oval } from 'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

interface props {
    guid: string,
    setfunction: any
}


function MapDialogue({ guid, setfunction }: props) {

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXN0aWFudXJpY2giLCJhIjoiY2tpaWx2dnZpMDN5YTJ5cDBxajNibjBtNSJ9.sd3Q-4QrSLopA-WTs0Fc2g';

    

    const mapContainer = useRef(null);
    const map_instance = useRef<any>(null);

    const [openDrawer, setOpenDrawer] = React.useState(false);

    const [lng, setLng] = useState(151.077);
    const [lat, setLat] = useState(-33.849);
    const [zoom, setZoom] = useState(15);

    let [loaded, setLoaded] = useState(false);

    var zoomToFeat = function (feature: any, map: any) {
        // based on this: https://www.mapbox.com/mapbox-gl-js/example/zoomto-linestring/

        // Geographic coordinates of the LineString
        var coordinates = feature.geometry.coordinates;

        // for each element drop the last item in the array
        coordinates = coordinates.map((coord: any) => {

            return coord.slice(0, -1)
        })

      

        const bounds = new mapboxgl.LngLatBounds(
            coordinates[0][0],
            coordinates[0][0]
        );

        for (const coord of coordinates) {
            for (const test of coord) {
                bounds.extend(test)
            }
        }
    

        map_instance.current.fitBounds(bounds, {
            padding: 100
        });
    };

    useEffect(() => {

        setfunction(guid)

        if (true) {

            if (map_instance.current) {

                return
            }; // initialize map only once

            let map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-v9', // style URL
                center: [lng, lat],
                zoom: zoom
            })

            map_instance.current = map

            map.on('load', () => {

                map_instance.current.addSource('irrigation_zones', {
                    type: 'geojson',
                    data: {
                        "type": "FeatureCollection",
                        "features": zones['features']
                    }
                })

                map_instance.current.addLayer(
                    {
                        'id': 'irrigation_zones',
                        'type': 'fill',
                        'source': 'irrigation_zones',
                        'layout': {
                        },
                        'paint': {
                            'fill-color': 'rgba(201, 38, 26, 0.5)',
                            'fill-opacity': 1,
                            'fill-outline-color': 'red'

                        },
                        'filter': ['==', ["get", "guid"], guid]
                    }
                )

                map_instance.current.addLayer(
                    {
                        'id': 'irrigation_zones_all',
                        'type': 'fill',
                        'source': 'irrigation_zones',
                        'layout': {
                        },
                        'paint': {
                            'fill-color': 'rgba(201, 38, 26, 0)',
                            'fill-opacity': 1,
                            'fill-outline-color': 'red'

                        }
                        
                    }
                )

                map_instance.current.addLayer(
                    {
                        'id': 'irrigation_zones_text',
                        'type': 'symbol',
                        'source': 'irrigation_zones',
                        'layout': {
                            "text-field": ["get", "guid"],
                            "text-offset": [0, -1],
    
                        },
                        'paint': {
                            'text-color': 'black',
                            'text-halo-color': 'white',
                            'text-halo-width': 200
                        }
                    }
                )

                // find the feature with the guid
                let feature = zones['features'].find((feature: any) => feature.properties.guid == guid)
                zoomToFeat(feature, map)

                map_instance.current.on('click', 'irrigation_zones_all', (e: any) => {

                    setfunction(e.features[0].properties['guid'])
                    changeFilter(e.features[0].properties['guid'])
                    
                    
                })

                map.on('mousemove', 'irrigation_zones_all', (e: any) => {

                    // popup.setLngLat(e.lngLat)
                    //     .setText(e.features[0].properties['guid'])
                    //     .addTo(map);

                    map.getCanvas().style.cursor = 'pointer';
                });

                map.on('mouseleave', 'irrigation_zones_all', () => {
                    map.getCanvas().style.cursor = '';
                    // popup.remove();
                });

            })

            setLoaded(true)

        }

       


    }, [])

    const changeFilter = (value:string) => {

        // // remove the old irrigation zone layer
        // map_instance.current.removeLayer('irrigation_zones')


        //change the filter
        map_instance.current.setFilter('irrigation_zones', ['==', ["get", "guid"], value])


    }


   

    return (<>


        <div ref={mapContainer} className="map-container" style={{ height: '100%' }} />

    </>)

}

export default MapDialogue;