import * as dotenv from "dotenv";
import { json } from "stream/consumers";
import { isConstructorDeclaration } from "typescript";


const { REACT_APP_SENAPS_API_KEY } = process.env;

export const getMap = async (id: string) => {

    try {
        const response = await fetch(`https://senaps.eratos.com/api/analysis/documentnodes/${id}/value`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        // need to clean nan values
        let t = await response.text();
        t = t.replace(/NaN/g, '""')
        let data = JSON.parse(t)

        return data

    } catch (error) {
      
    }

}


export const getData = async (documentID: string) => {

    try {
        const response = await fetch(`https://senaps.eratos.com/api/analysis/documentnodes/${documentID}/value`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data = await response.json();

        let location_data = data[1]

        location_data = location_data.map((item: any) => {
            return JSON.parse(item)
        })

        let time_data = data[0]


        return [location_data, time_data]

    } catch (error) {
        return [];
    }

}

export const getSoilSensorObservations = async (senor: string, start: string) => {

    let start_date = start.replace(":", "%3A")

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=meshnet.bicentennialpark.SoilMonitor.${senor}.VWC&start=${start_date}&limit=1000000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        let data = await response.json();

        // data = JSON.parse(data)

        return data.results

    } catch (error) {
        // return [];
    }
}

export const getSoilSensorPredictions = async (station: string, prefix: string) => {

    try {

        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=simpact.toolbox.test.` + station + `.` + prefix + `soil_moisture&limit=10000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        if (response.ok) {
            let data = await response.json();

            // data = JSON.parse(data)

            return data.results
        }
        else {
            throw new Error('Something went wrong');
        }

    } catch {

        return [];
    }

}

export const getIrrigationHistory = async (stream: string, start: string) => {

    let start_date = start.replace(":", "%3A")

    console.log('hello')

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=${stream}&start=${start_date}&limit=10000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        

        let data = await response.json();

        console.log('here',data)

        // data = JSON.parse(data)

        return data.results

    } catch (error) {
        // return [];
    }
}



export const getLuftTempSensors = async () => {

    try {
        const response = await fetch('https://senaps.eratos.com/api/sensor/v2/streams?id=fieldmouse.bicentennialpark.external_weather_stations.%2A.Temperature&expand=true&recursive=true',
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data = await response.json();

        return data["_embedded"]["streams"]


    } catch (error) {
        return [];
    }

}

export const getLuftTempSensorsRain = async () => {

    try {
        const response = await fetch('https://senaps.eratos.com/api/sensor/v2/streams?id=fieldmouse.bicentennialpark.external_weather_stations%2A.Temperature&properties=resultsSummary.last',
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data = await response.json();




        return data["_embedded"]["streams"]





    } catch (error) {
        return [];
    }

}

export const getWindSpeedSensors = async () => {

    try {
        const response = await fetch('https://senaps.eratos.com/api/sensor/v2/streams?id=fieldmouse.bicentennialpark.external_weather_stations.%2A.AvgWindSpeed&properties=resultsSummary.last',
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data = await response.json();

        return data["_embedded"]["streams"]


    } catch (error) {
        return [];
    }

}


export const getTemperatureSensors = async () => {

    let api_key: string = ""

    try {
        const response = await fetch('https://senaps.eratos.com/api/sensor/v2/streams?id=meshnet.bicentennialpark.TempHumidity.SENS%2ATRH-SOPA.Temperature&expand=true&recursive=true',
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data = await response.json();

        return data["_embedded"]["streams"]


    } catch (error) {
        return [];
    }
}

export const getTemperatureObservations = async (senor: string, start: string) => {

    let start_date = start.replace(":", "%3A")

    // if the first letter of the sensor is an S
    if (senor[0] === "S") {

        try {
            const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=meshnet.bicentennialpark.TempHumidity.${senor}.Temperature&start=${start_date}&limit=10000000`,
                {
                    headers: {
                        'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                    }
                });

            let data = await response.json();

            // data = JSON.parse(data)

            return data.results

        } catch (error) {
            // return [];
        }

    } else {

        try {
            const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=fieldmouse.bicentennialpark.external_weather_stations.${senor}.Temperature&start=${start_date}&limit=10000000`,
                {
                    headers: {
                        'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                    }
                });

            let data = await response.json();

            // data = JSON.parse(data)

            return data.results

        } catch (error) {
            // return [];
        }

    }
}

export const getRainfallObservations = async (start: string) => {

    let start_date = start.replace(":", "%3A")

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=simpact.bom_gov_au.95765.rain.rain_trace.delta&start=${start_date}&limit=10000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        let data = await response.json();

        // data = JSON.parse(data)

        return data.results

    } catch (error) {
        // return [];
    }
}


export const getHumiditySensors = async () => {

    let api_key: string = ""


    try {
        const response = await fetch('https://senaps.eratos.com/api/sensor/v2/streams?id=meshnet.bicentennialpark.TempHumidity.SENS%2ATRH-SOPA.Humidity&properties=resultsSummary.last',
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data = await response.json();

        return data["_embedded"]["streams"]


    } catch (error) {
        return [];
    }
}


// get all the soil moisture streams to plot the locations on the map.
export const getSoilMoistureStreams = async () => {

    let api_key: string = ""
    
    try {
        const response = await fetch('https://senaps.eratos.com/api/sensor/v2/streams?id=meshnet.bicentennialpark.SoilMonitor.SENS%3F%3F%3F%3F-SM-SOPA.VWC&limit=100000&expand=true&recursive=true&groupids=meshnet.bicentennialpark.SoilMonitor',
         {
            headers: {'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                'Content-Type': 'application/json',
                        }
          });

        let data = await response.json();

        return data["_embedded"]["streams"]


    } catch (error) {
        return [];
    }
}

export const getZoneMoistureStreams = async () => {

    let api_key: string = ""
    
    try {
        const response = await fetch('https://senaps.eratos.com/api/sensor/v2/streams?id=simpact.toolbox.test.%2A.soil_moisture&limit=1000000&expand=true&recursive=true',
         {
            headers: {'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                'Content-Type': 'application/json',
                        }
          });

        let data = await response.json();

        return data["_embedded"]["streams"]


    } catch (error) {
        return [];
    }
}


// with an individual sensor get the correct soil moisute data to plot

export const getSoilMoistureData = async (stream_id:string) => {

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=${stream_id}&limit=1000000`,
         {
            headers: {'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                'Content-Type': 'application/json',
                        }
          });

        let data: any = await response.json();

        return data['results']

    } catch (error) {
        return [];
    }
}

export const getHistoricRain = async () => {

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=simpact.bom_gov_au.95765.rain.rain_trace&limit=1000000`,
         {
            headers: {'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                'Content-Type': 'application/json',
                        }
          });

        let data: any = await response.json();

        return data['results']

    } catch (error) {
        return [];
    }
}

export const getHistoricTempData = async (stream_id:string) => {

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=${stream_id}&limit=100&sort=descending`,
         {
            headers: {'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                'Content-Type': 'application/json',
                        }
          });

        let data: any = await response.json();

        return data['results']

    } catch (error) {
        return [];
    }
}

export const getSoilSensorAdjustments = async (station: string, prefix: string) => {

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=simpact.toolbox.test.` + station + `.` + prefix + `irrigation_schedule_scaling&limit=10000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        if (response.ok) {
            let data = await response.json();

            // data = JSON.parse(data)

            return data.results
        }
        else {
            throw new Error('Something went wrong');
        }

    } catch (error) {
        return [];
    }
}

export const getRainfallForecast = async (start: string, end: string = "") => {

    let start_date = start.replace(":", "%3A")
    let end_date = end.replace(":", "%3A")

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=simpact.bom_gov_au.95765.adfd.forecast.Precip_SFC&start=${start_date}&end=${end_date}&limit=10000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        let data = await response.json();

        // data = JSON.parse(data)

        return data.results

    } catch (error) {
        // return [];
    }
}

export const getCalibrationParameters = async (sensor: string) => {

    try {
        const response = await fetch(`https://senaps.eratos.com/api/analysis/workflows?limit=1000&name=calibration_model_${sensor}`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data: any = await response.json();



        let workflow_id = data['_embedded']['workflows'][0]['id']

        // get the workflow nodes
        const response2 = await fetch(`https://senaps.eratos.com/api/analysis/workflows/${workflow_id}`,

            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data2: any = await response2.json();

        let nodes = data2['_embedded']['graph']['_embedded']['nodes']

    

        let document_id = ""

        nodes.forEach((node: any) => {

          

            if (node['label'] === "best_calibration_results") {
                
                document_id = node['documentid']
            }

        })

    

        // get the document
        const response3 = await fetch(`https://senaps.eratos.com/api/analysis/documentnodes/${document_id}/value`,

        {
            headers: {
                'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                'Content-Type': 'application/json',
            }
        });

        let data3: any = await response3.json();

        return data3

    } catch (error) {
        return [];
    }


}

// with an individual sensor get the correct soil moisute data to plot
export const getSoilMoistureChanges = async (stream_id: string) => {

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=${stream_id}&limit=1000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data: any = await response.json();

        return data['results']

    } catch (error) {
        return [];
    }
}

export const getEvapObservations = async (start: string) => {

    let start_date = start.replace(":", "%3A")

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=simpact.bom_gov_au.95765.silo.et_morton_wet&start=${start_date}&limit=10000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        let data = await response.json();

        // data = JSON.parse(data)

        return data.results

    } catch (error) {
        // return [];
    }
}

export const getMLSoilSensorPredictions = async (sensor: string, start_date: string) => {

    try {

        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=meshnet.bicentennialpark.SoilMonitor.${sensor}.VWC.KNN-Predictions&start=${start_date}&limit=10000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        if (response.ok) {
            let data = await response.json();

       

            // data = JSON.parse(data)

            return data.results
        }
        else {
            throw new Error('Something went wrong');
        }

    } catch {

        return [];
    }

}

export const getAllSoilSensorAdjustments = async (stations: string) => {

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=${stations}&limit=100000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        if (response.ok) {
            let data = await response.json();

            // data = JSON.parse(data)

            return data.results
        }
        else {
            throw new Error('Something went wrong');
        }

    } catch (error) {
        return [];
    }
}

export const getSoilScalingStreams = async () => {

    try {
        const response = await fetch('https://senaps.eratos.com/api/sensor/v2/streams?id=%2A_scaling',
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2',
                    'Content-Type': 'application/json',
                }
            });

        let data = await response.json();

        return data["_embedded"]["streams"]


    } catch (error) {
        return [];
    }

}
export const getSingleRunObservations = async (senor: string, start: string) => {

    let start_date = start.replace(":", "%3A")

    try {
        const response = await fetch(`https://senaps.eratos.com/api/sensor/v2/observations?streamid=Ben.test.predictedSoilMoisture&start=${start_date}&limit=1000000000`,
            {
                headers: {
                    'apikey': '4bs5Q2ub5Ux3Qb4lrvMxEHHpeEt7d3G2'
                }
            });

        let data = await response.json();

        // data = JSON.parse(data)

        return data.results

    } catch (error) {
        // return [];
    }
}