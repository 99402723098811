import React, { useRef, useEffect, useState } from 'react';
import logo from './logo.svg';
import ButtonAppBar from '../componants/appBar';
import { Box, Paper, Typography, Switch, Grid, IconButton, Button, Drawer } from '@mui/material';
import Slider from '@mui/material/Slider';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { MapSharp, SettingsInputAntennaTwoTone, StreamSharp } from '@mui/icons-material';
import EChartsReact from "echarts-for-react";
import data from '../all_stations.json'
import ThermostatIcon from '@mui/icons-material/Thermostat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WavesIcon from '@mui/icons-material/Waves';
import AirIcon from '@mui/icons-material/Air';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import MovingIcon from '@mui/icons-material/Moving';
import AlertDialogSlide from '../componants/dialogue';
import Loading from '../componants/loading';
import MenuIcon from '@mui/icons-material/Menu';
import FilterListIcon from '@mui/icons-material/FilterList';
import CssBaseline from '@mui/material/CssBaseline';

import { parse } from 'node:path/win32';
import { features } from 'node:process';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ZoneDialogue from '../componants/zone_dialogue';

import Toolbar from '@mui/material/Toolbar';
import Tile from '../componants/tile';
import PersistentDrawerRight from '../componants/drawer';
import TileBar from '../componants/tilebar';
import MapTile from '../componants/map';
import { positions } from '@mui/system';
import zones from '../All_zones.json';
import contours from '../contours.json';


const TileBarMemo = React.memo(TileBar);


function Main() {

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXN0aWFudXJpY2giLCJhIjoiY2tpaWx2dnZpMDN5YTJ5cDBxajNibjBtNSJ9.sd3Q-4QrSLopA-WTs0Fc2g';

    let [renderMap, setRenderMap] = useState(false);


    const mapContainer = useRef(null);
    const map_instance = useRef<any>(null);
    let [map_markers, setMapMarkers] = useState<any[]>([])

    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [width, setWindowWidth] = useState(window.innerWidth);


    let [avgTemp, setAvgTemp] = useState<number>()
    let [avgHumid, setAvgHumid] = useState<number>()
    let [avgWind, setAvgWind] = useState<number>()
    let [avgSoil, setAvgSoil] = useState<number>()
    let [minTemp, setMinTemp] = useState<any>()
    let [loaded, setLoaded] = useState<boolean>(false)
    let [soilMoistureFeatures, setSoilMoistureFeatures] = useState<any>()
    let [temperatureFeatures, setTemperatureFeatures] = useState<any>()

    const [lng, setLng] = useState(151.077);
    const [lat, setLat] = useState(-33.849);
    const [zoom, setZoom] = useState(15);

    let [tempLocations, setTempLocations] = useState<any[]>([])
    let [luftTempLocations, setLuftTempLocations] = useState<any[]>([])
    let [soilMoistureLocations, setSoilMoistureLocations] = useState<any[]>([])

    let [coolestFL, setCoolestFl] = useState<number>()
    let [parkFL, setParkFl] = useState<number>()



    let [showTempMap, setShowTempMap] = useState<boolean>(false)
    let [showSoilMap, setShowSoilMap] = useState<boolean>(false)
    let [showSoilSensor, setShowSoilSensor] = useState<boolean>(false)
    let [showLuftTempSensor, setShowLuftTempSensor] = useState<boolean>(false)
    let [showMeshnetTempSensor, setShowMeshnetTempSensor] = useState<boolean>(false)
    let [showIrrigationZones, setShowIrrigationZones] = useState<boolean>(false)
    let [showContours, setShowContours] = useState<boolean>(false)



    let [feelsLikeMessage, setFeelsLikeMessage] = useState<string>()
    let [heatStress, setHeatStress] = useState<{ [key: string]: string }>({
        'level': 'No stress',
        'description': 'The current heat stress level is low.'
    })
    let [coolingMessage, setCoolingMessage] = useState<string>()
    let [tempDiff, setTempDiff] = useState<number>()

    let [open, setOpen] = useState<boolean>(false)
    let [open_zone_dialogue, setOpenZoneDialogue] = useState<boolean>(false)
    let [zone_dialogue_data, setZoneDialogueData] = useState<any>("")
    let [lastObservation, setLastObservation] = useState<Date>()


    let [parkSensors, setParkSensors] = useState<string[]>([
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.457_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.042_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.456_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.442_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.472_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.040_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.426_1221.Temperature',
        "meshnet.bicentennialpark.TempHumidity.SENS0001-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0002-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0003-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0004-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0005-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0006-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0007-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0008-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0009-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0010-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0011-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0012-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0013-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0014-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0015-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0016-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0017-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0018-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0019-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0020-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0021-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0022-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0023-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0024-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0025-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0026-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0027-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0028-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0029-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0030-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0031-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0032-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0033-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0034-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0035-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0036-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0037-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0038-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0039-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0040-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0041-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0042-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0043-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0044-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0045-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0046-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0047-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0048-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0049-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0050-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0052-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0053-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0054-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0055-TRH-SOPA.Temperature"
    ])
    let soil_document_ids = [
        "39ceb15b-7fbb-4552-a6de-609271dfdea0",
        "706526b7-49ab-43cf-8434-6003ac364c8b",
        "b1e190b5-de27-4869-9ce8-719d23bbea3a",
        "26c12782-c7dc-4c36-9ad2-fbe130ba49df",
        "73923646-8c65-4d67-9b74-c39e65a232a4",
        "006e4b83-dd02-40d9-9bbd-25eaadf34d65",
        "dc6df17b-5c57-479d-95b2-08b5678b459b",
        "fc388d73-3272-4161-90c3-9224c64abeec",
        "599d8a87-f4b5-4b2b-a0eb-932e2f45c5a7",
        "9fcd6347-5c49-4aaa-b908-7fbeb4335e99",
        "73bb0ee6-231b-4fc1-817b-04b9258f15fa",
        "8ffaa75b-f08f-45df-b8e1-8d9344399952",
        "10f0ca30-9b9d-43c7-b64c-c78b287b67c6",
        "aa1c64f0-4196-42b6-9088-66d8384ae42a",
        "b8400bb7-a206-47f6-afd9-b77019e27f45",
        "c3410c82-8536-4275-ab44-194eb2793117",
        "6c99e723-8d8a-4d54-bd6f-c251d6eec65d",
        "4f63f373-20c8-4703-9e83-33334e6d94d5",
        "b940fb00-4aea-4272-8ed0-4148ac6c3435",
        "114e12b7-ceb4-4de9-aab2-b0cf6a7a17d5",
        "3f62b931-8364-433a-a02e-c184b248bde5",
        "dcda2e06-77e4-4733-b282-e6dd320e0768",
        "f7499e73-fd8e-4568-a82d-aa352c5ad3e5",
        "a30c7933-d9f6-4173-a97f-bde78a5630e6",
        "14be0ace-7929-4dad-8c2e-fe921e75f0d3"
    ]

    let temp_document_ids = [

        "d30fad93-944a-47a2-bb8f-9b2f21b5a71d",
        "88f813f6-bd98-4c3a-887e-c5921181bfd5",
        "70768a09-c1c6-4d62-ad20-de8d24af93ed",
        "63a90934-7882-4adb-9d0c-80c5101d204a",
        "26e44804-fd1b-45e5-9e61-d8644c516a22",
        "e9d0e2de-188c-4d03-bb95-40b610ec3679",
        "e0545680-733f-48e2-b50c-3d1771aefcf2",
        "55240bd1-95d3-49a7-ad45-ac109fcc16e0",
        "25cc1ec4-d22d-4cf1-bd59-cb59b3c64a21",
        "e893daea-b60a-465f-b65c-465c48ada10e",
        "040748f2-8ad2-4a01-9bc5-b4db2ef44079",
        "e07d5436-012d-432e-876a-8cc22f9fbdc3",
        "8202d795-7f67-48ad-80a5-a217e10d39a5",
        "399418a0-ffb8-44d3-ad0a-8dc90aed46f3",
        "8eba5fb2-3071-43be-ad75-de703bd2c591",
        "edf3778e-9fce-40a3-ba45-13366994e7ac",
        "6f67e38b-1179-428b-b103-a01b320f5905",
        "2f29718d-b328-49a2-8e85-b2af97e128c1",
        "a3d0e652-2fa9-4991-bc4d-a7673dc74ba3",
        "359e0750-65bd-4ffd-b52e-50ce1cb0f630",
        "492148e9-c7ba-4a81-a2c5-084c9648bb05",
        "6d91e4b5-9477-46e6-a2aa-b11a112140e0",
        "c29e8abe-67b5-4a63-86e8-9450f666cf97",
        "1e929fb4-73cf-4336-b6cf-eb75d22f56dc",
        "a21e38fb-b5a5-49b7-ba02-aca201eb309a"
    ]

    let [soilInterval, setSoilInterval] = useState<any>()
    let [soil_current_image, setSoil_current_image] = useState<number>(0)
    let [soil_frames, setSoil_frames] = useState<number>(0)
    let [soil_times, setSoil_times] = useState<any[]>([])
    let [soil_current_time, setSoil_current_time] = useState<string>("")
    let [play, setPlay] = useState<boolean>(false)

    let [tempInterval, setTempInterval] = useState<any>()
    let [temp_current_image, setTemp_current_image] = useState<number>(0)
    let [temp_frames, setTemp_frames] = useState<number>(0)
    let [temp_times, setTemp_times] = useState<any[]>([])
    let [temp_current_time, setTemp_current_time] = useState<string>("")
    let [temp_play, setTemp_play] = useState<boolean>(false)


    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    useEffect(() => {
        // updateDimensions();
        window.addEventListener("resize", updateDimensions, false);
        
        return () => { window.removeEventListener('resize', updateDimensions) };

    }, [])



    if (width > 800) {

        return (
            <>

                <Box sx={{ backgroundColor: '#dfe6e9' }} >
                <ButtonAppBar/>
                    <Grid container>

                        <Grid item xs={12} lg={3} style={{ height: '92vh', overflowY: 'auto' }}>
                            <>
                                <TileBarMemo format={'normal'} />
                            </>
                        </Grid>

                        <Grid item xs={12} lg={9} >
                            
                            <MapTile width={width}></MapTile>
                        </Grid>
                    </Grid>
                </Box>
            </>
        );
    }
    else {
        return (<>
            
            <Box sx={{ backgroundColor: '#dfe6e9', pb: "20px" }} >
                <MapTile width={width}></MapTile>
                
                <Box>

                    <Grid container>

                        <Grid item xs={12}>
                            <Typography variant='h6' sx={{ textAlign: 'center', m: '10px' }}> <b>Live Data</b> </Typography>
                        </Grid>

                        <Grid item xs={12}>

                            <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>

                                <TileBar format={'tight'} />

                            </Box>

                        </Grid>

                    </Grid>



                </Box>
            </Box>

            
            

        </>)
    }


}



export default Main;