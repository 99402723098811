import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {FC} from "react";
import {useNavigate} from "react-router-dom";

interface IButtonAppBarProps {
}


const ButtonAppBar: FC<IButtonAppBarProps> = () => {

  const navigate = useNavigate();
    const isLoggedIn = (): boolean  => {
        let token = localStorage.getItem("token");
        if (!token) {
            return false
        }
        return true
    }
    const handleDocumentation = () => {
        window.open('https://doc-simpact.harc-dev.com.au', "_blank")
    }
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("email");
        navigate('/')
    }
    const handleLogin = () => {
        navigate('/login')
    }

    return (
            <AppBar position="relative">
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                    </IconButton>
                    <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
                        Park Now
                    </Typography>
                    {!isLoggedIn() &&
                        <Button color="inherit" onClick={handleLogin} >Login</Button>
                    }
                    {isLoggedIn() &&
                        <Button color="inherit" onClick={handleLogout}>Logout</Button>
                    }
                     <Button color="inherit" onClick={handleDocumentation}>Documentation</Button>
                </Toolbar>
            </AppBar>
        );
}


export default ButtonAppBar;




