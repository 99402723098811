import React, { useRef, useEffect, useState } from 'react';
import logo from './logo.svg';
import ButtonAppBar from '../componants/appBar';
import { Box, Paper, Typography, Switch, Grid, IconButton, Button, Drawer } from '@mui/material';
import Slider from '@mui/material/Slider';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { getSoilMoistureStreams, getData, getSoilMoistureData, getZoneMoistureStreams, getHistoricRain, getHistoricTempData, getMap, getTemperatureSensors, getLuftTempSensors, getHumiditySensors, getWindSpeedSensors } from '../SenapsCalls'
import { MapSharp, SettingsInputAntennaTwoTone, StreamSharp } from '@mui/icons-material';
import EChartsReact from "echarts-for-react";
import data from '../all_stations.json'
import ThermostatIcon from '@mui/icons-material/Thermostat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WavesIcon from '@mui/icons-material/Waves';
import AirIcon from '@mui/icons-material/Air';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import MovingIcon from '@mui/icons-material/Moving';
import AlertDialogSlide from '../componants/dialogue';
import Loading from '../componants/loading';
import MenuIcon from '@mui/icons-material/Menu';
import FilterListIcon from '@mui/icons-material/FilterList';
import CssBaseline from '@mui/material/CssBaseline';
import { parse } from 'node:path/win32';
import { features } from 'node:process';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import ZoneDialogue from '../componants/zone_dialogue';
import { useParams } from 'react-router-dom';

import Tile from '../componants/tile';
import PersistentDrawerRight from '../componants/drawer';
import TileBar from '../componants/tilebar';
import { positions } from '@mui/system';
import zones from '../All_zones_test.json';
import contours from '../contours.json';
import SummaryDialogue from './summary_dialogue';
import NetworkStatus from './network_status';
import StationSummary from './station_summary';

import { Oval } from 'react-loader-spinner'
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

interface props {
    width: number
}


function MapTile({ width }: props) {

    mapboxgl.accessToken = 'pk.eyJ1IjoiY2hyaXN0aWFudXJpY2giLCJhIjoiY2tpaWx2dnZpMDN5YTJ5cDBxajNibjBtNSJ9.sd3Q-4QrSLopA-WTs0Fc2g';

    let [renderMap, setRenderMap] = useState(false);

    const mapContainer = useRef(null);
    const map_instance = useRef<any>(null);
    let [map_markers, setMapMarkers] = useState<any[]>([])

    const [openDrawer, setOpenDrawer] = React.useState(false);
    let [minTemp, setMinTemp] = useState<any>()

    let [loaded, setLoaded] = useState<boolean>(false)
    let [soilMoistureFeatures, setSoilMoistureFeatures] = useState<any>()
    let [temperatureFeatures, setTemperatureFeatures] = useState<any>()
    let [soilMoistureStatus, setSoilMoistureStatus] = useState<any>()
    let [temperatureStatus, setTemperatureStatus] = useState<any>()
    const [lng, setLng] = useState(151.077);
    const [lat, setLat] = useState(-33.849);
    const [zoom, setZoom] = useState(15);

    let [tempLocations, setTempLocations] = useState<any[]>([])
    let [soilMoistureLocations, setSoilMoistureLocations] = useState<any[]>([])


    let [showTempMap, setShowTempMap] = useState<boolean>(false)
    let [showSoilMap, setShowSoilMap] = useState<boolean>(false)
    let [showSoilSensor, setShowSoilSensor] = useState<boolean>(false)
    let [showLuftTempSensor, setShowLuftTempSensor] = useState<boolean>(false)
    let [showMeshnetTempSensor, setShowMeshnetTempSensor] = useState<boolean>(false)
    let [showIrrigationZones, setShowIrrigationZones] = useState<boolean>(false)
    let [showContours, setShowContours] = useState<boolean>(false)
    let [showSoilConditions, setShowSoilConditions] = useState<boolean>(false)
    let [showNetworkStatus, setShowNetworkStatus] = useState<boolean>(false)

    const params = useParams();
    console.log("guid", params)
    let hasGUID = false
    if (params["guid"]) {
        console.log("guid", params["guid"])
        hasGUID = true
    }

    let [showStationSummary, setShowStationSummary] = useState<boolean>(hasGUID)

    let [open, setOpen] = useState<boolean>(false)
    let [open_zone_dialogue, setOpenZoneDialogue] = useState<boolean>(false)
    let [zone_dialogue_data, setZoneDialogueData] = useState<any>("")


    let [parkSensors, setParkSensors] = useState<string[]>([
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.457_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.042_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.456_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.442_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.472_1221.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.040_0222.Temperature',
        'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.426_1221.Temperature',
        "meshnet.bicentennialpark.TempHumidity.SENS0001-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0002-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0003-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0004-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0005-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0006-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0007-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0008-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0009-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0010-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0011-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0012-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0013-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0014-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0015-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0016-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0017-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0018-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0019-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0020-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0021-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0022-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0023-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0024-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0025-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0026-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0027-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0028-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0029-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0030-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0031-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0032-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0033-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0034-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0035-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0036-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0037-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0038-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0039-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0040-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0041-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0042-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0043-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0044-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0045-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0046-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0047-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0048-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0049-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0050-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0052-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0053-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0054-TRH-SOPA.Temperature",
        "meshnet.bicentennialpark.TempHumidity.SENS0055-TRH-SOPA.Temperature"
    ])
    let soil_document_ids = [

        "39ceb15b-7fbb-4552-a6de-609271dfdea0",
        "706526b7-49ab-43cf-8434-6003ac364c8b",
        "b1e190b5-de27-4869-9ce8-719d23bbea3a",
        "26c12782-c7dc-4c36-9ad2-fbe130ba49df",
        "73923646-8c65-4d67-9b74-c39e65a232a4",
        "006e4b83-dd02-40d9-9bbd-25eaadf34d65",
        "dc6df17b-5c57-479d-95b2-08b5678b459b",
        "fc388d73-3272-4161-90c3-9224c64abeec",
        "599d8a87-f4b5-4b2b-a0eb-932e2f45c5a7",
        "9fcd6347-5c49-4aaa-b908-7fbeb4335e99",
        "73bb0ee6-231b-4fc1-817b-04b9258f15fa",
        "8ffaa75b-f08f-45df-b8e1-8d9344399952",
        "10f0ca30-9b9d-43c7-b64c-c78b287b67c6",
        "aa1c64f0-4196-42b6-9088-66d8384ae42a",
        "b8400bb7-a206-47f6-afd9-b77019e27f45",
        "c3410c82-8536-4275-ab44-194eb2793117",
        "6c99e723-8d8a-4d54-bd6f-c251d6eec65d",
        "4f63f373-20c8-4703-9e83-33334e6d94d5",
        "b940fb00-4aea-4272-8ed0-4148ac6c3435",
        "114e12b7-ceb4-4de9-aab2-b0cf6a7a17d5",
        "3f62b931-8364-433a-a02e-c184b248bde5",
        "dcda2e06-77e4-4733-b282-e6dd320e0768",
        "f7499e73-fd8e-4568-a82d-aa352c5ad3e5",
        "a30c7933-d9f6-4173-a97f-bde78a5630e6",
        "14be0ace-7929-4dad-8c2e-fe921e75f0d3"
    ]

    let temp_document_ids = [

        "d30fad93-944a-47a2-bb8f-9b2f21b5a71d",
        "88f813f6-bd98-4c3a-887e-c5921181bfd5",
        "70768a09-c1c6-4d62-ad20-de8d24af93ed",
        "63a90934-7882-4adb-9d0c-80c5101d204a",
        "26e44804-fd1b-45e5-9e61-d8644c516a22",
        "e9d0e2de-188c-4d03-bb95-40b610ec3679",
        "e0545680-733f-48e2-b50c-3d1771aefcf2",
        "55240bd1-95d3-49a7-ad45-ac109fcc16e0",
        "25cc1ec4-d22d-4cf1-bd59-cb59b3c64a21",
        "e893daea-b60a-465f-b65c-465c48ada10e",
        "040748f2-8ad2-4a01-9bc5-b4db2ef44079",
        "e07d5436-012d-432e-876a-8cc22f9fbdc3",
        "8202d795-7f67-48ad-80a5-a217e10d39a5",
        "399418a0-ffb8-44d3-ad0a-8dc90aed46f3",
        "8eba5fb2-3071-43be-ad75-de703bd2c591",
        "edf3778e-9fce-40a3-ba45-13366994e7ac",
        "6f67e38b-1179-428b-b103-a01b320f5905",
        "2f29718d-b328-49a2-8e85-b2af97e128c1",
        "a3d0e652-2fa9-4991-bc4d-a7673dc74ba3",
        "359e0750-65bd-4ffd-b52e-50ce1cb0f630",
        "492148e9-c7ba-4a81-a2c5-084c9648bb05",
        "6d91e4b5-9477-46e6-a2aa-b11a112140e0",
        "c29e8abe-67b5-4a63-86e8-9450f666cf97",
        "1e929fb4-73cf-4336-b6cf-eb75d22f56dc",
        "a21e38fb-b5a5-49b7-ba02-aca201eb309a"
    ]

    let [soilInterval, setSoilInterval] = useState<any>()
    let [soil_current_image, setSoil_current_image] = useState<number>(0)
    let [soil_frames, setSoil_frames] = useState<number>(0)
    let [soil_times, setSoil_times] = useState<any[]>([])
    let [soil_current_time, setSoil_current_time] = useState<string>("")
    let [play, setPlay] = useState<boolean>(false)


    let [tempInterval, setTempInterval] = useState<any>()
    let [temp_current_image, setTemp_current_image] = useState<number>(0)
    let [temp_frames, setTemp_frames] = useState<number>(0)
    let [temp_times, setTemp_times] = useState<any[]>([])
    let [temp_current_time, setTemp_current_time] = useState<string>("")
    let [temp_play, setTemp_play] = useState<boolean>(false)

    let [show_summary, setShow_summary] = React.useState(false);
    let [summary_data, setSummary_data] = React.useState<any>([]);
    let [summary_map, setSummary_map] = React.useState<any>([]);

    let [show_prediction, setShow_prediction] = React.useState(false);


    const handleShowSummary = () => {
        if (show_summary) {
            setShow_summary(false);
        } else {
            setShow_summary(true);
        }
    }

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleZoneDialogueOpen = () => {

        if (open_zone_dialogue) {
            setOpenZoneDialogue(false)
        } else {
            setOpenZoneDialogue(true)
        }
    }

    const handleShowNetworkStatus = () => {
        if (showNetworkStatus) {
            setShowNetworkStatus(false)
        } else {
            setShowNetworkStatus(true)
        }
    }

    const handleShowStationSummary = () => {
        if (showStationSummary) {
            setShowStationSummary(false)
        } else {
            setShowStationSummary(true)
        }
    }



    /////////////////////////  Temperature Map  /////////////////////////    

    const handleTempMapChange = () => {

        if (showSoilMap) {
            setShowSoilMap(false)
            handleSoilMapChange()
        }

        if ((showTempMap) && (map_instance.current)) {

            // if true, remove layer
            map_instance.current.removeLayer('temp_map')
            setTemp_current_image(temp_frames - 1)
            setTemp_current_time(temp_times[temp_frames - 1])
            setShowTempMap(false)
            clearInterval(tempInterval)

            map_instance.current.getSource('temp_sensors').setData({
                "type": "FeatureCollection",
                "features": tempLocations[temp_frames - 1]
            });

            let current_markers = map_markers

            current_markers.forEach(element => {
                element.addTo(map_instance.current)
            });

        }

        else if (map_instance.current) {

            let current_markers = map_markers

            current_markers.forEach(element => {
                element.remove()
            });


            map_instance.current.addLayer({
                'id': 'temp_map',
                'type': 'fill',
                'source': 'temp_map', // reference the data source
                'layout': {},
                'paint': {
                    // 'fill-color': ['step', ['get', 'value'], '#f7fbff', 16, '#e2edf8', 18, '#cde0f1', 20, '#afd1e7', 22, '#89bedc', 24, '#60a6d2', 26, '#3e8ec4', 28, '#2272b5', 30, '#0a549e', 32, '#08306b'],
                    'fill-color': ['interpolate-hcl', ['linear'], ['get', 'value'], -2, 'blue', 2, 'red'],
                    'fill-opacity': 0.7,
                    'fill-outline-color': ['rgba', 0, 0, 0, 0]

                }
            })

            setShowTempMap(true)
        }

    }

    const handleTempPlay = () => {

        if (temp_play) {
            clearInterval(tempInterval)
            setTemp_play(false)

        }
        else {

            let currentImage = temp_current_image


            let interval_ID = setInterval(async () => {

                currentImage = (currentImage + 1) % temp_frames;
                setTemp_current_image(currentImage);
                setTemp_current_time(temp_times[currentImage])

                if ((temperatureFeatures[currentImage][1] == null) && (temperatureStatus[currentImage] == 'not_loaded')) {

                    setTemperatureStatus((prev: any) => {
                        let new_status = [...prev]
                        new_status[currentImage] = 'loading'
                        return new_status
                    })

                    let data = await downloadData(currentImage, "temp")

                    setTemperatureStatus((prev: any) => {
                        let new_status = [...prev]
                        new_status[currentImage] = 'loaded'
                        return new_status
                    })



                    map_instance.current.getSource('temp_map').setData({
                        "type": "FeatureCollection",
                        "features": data[1]['features']
                    });
                }

                else if (temperatureStatus[currentImage] == 'loaded') {

                    map_instance.current.getSource('temp_map').setData({
                        "type": "FeatureCollection",
                        "features": temperatureFeatures[currentImage][1]['features']
                    });

                }

                map_instance.current.getSource('temp_sensors').setData({
                    "type": "FeatureCollection",
                    "features": tempLocations[currentImage]
                });

            }, 500);

            setTemp_play(true)
            setTempInterval(interval_ID)
        }
    }

    const handleTempJumpTo = async (value: number) => {

        if ((temp_current_image + value >= 0) && (temp_current_image + value < temp_frames)) {


            setTemp_current_image((prev) => prev + value)
            setTemp_current_time(temp_times[(temp_current_image) + value])

            // check to see if we have the data for the image we are jumping to, otherwise load it

            if ((temperatureFeatures[temp_current_image + value][1] == null) && (temperatureStatus[temp_current_image + value] == 'not_loaded')) {

                setTemperatureStatus((prev: any) => {
                    let new_status = [...prev]
                    new_status[temp_current_image + value] = 'loading'
                    return new_status
                })

                let data = await downloadData(temp_current_image + value, "temp")

                setTemperatureStatus((prev: any) => {
                    let new_status = [...prev]
                    new_status[temp_current_image + value] = 'loaded'
                    return new_status
                })



                map_instance.current.getSource('temp_map').setData({
                    "type": "FeatureCollection",
                    "features": data[1]['features']
                });
            }

            else if (temperatureStatus[temp_current_image + value] == 'loaded') {

                map_instance.current.getSource('temp_map').setData({
                    "type": "FeatureCollection",
                    "features": temperatureFeatures[temp_current_image + value][1]['features']
                });

            }

            map_instance.current.getSource('temp_sensors').setData({
                "type": "FeatureCollection",
                "features": tempLocations[temp_current_image + value]
            });

        }
    }

    const handleTempSlider = async (event: any) => {

        setTemp_current_image(event.target.value)
        setTemp_current_time(temp_times[event.target.value])

        if ((temperatureFeatures[event.target.value][1] == null) && (temperatureStatus[event.target.value] == 'not_loaded')) {

            setTemperatureStatus((prev: any) => {
                let new_status = [...prev]
                new_status[event.target.value] = 'loading'
                return new_status
            })

            let data = await downloadData(event.target.value, "temp")

            setTemperatureStatus((prev: any) => {
                let new_status = [...prev]
                new_status[event.target.value] = 'loaded'
                return new_status
            })



            map_instance.current.getSource('temp_map').setData({
                "type": "FeatureCollection",
                "features": data[1]['features']
            });
        }

        else if (temperatureStatus[event.target.value] == 'loaded') {

            map_instance.current.getSource('temp_map').setData({
                "type": "FeatureCollection",
                "features": temperatureFeatures[event.target.value][1]['features']
            });

        }

        map_instance.current.getSource('temp_sensors').setData({
            "type": "FeatureCollection",
            "features": tempLocations[event.target.value]
        });


        return

    }

    /////////////////// Soil Moisture Map //////////////////////

    const handleSoilMapChange = () => {



        if (showTempMap) {

            setShowTempMap(false)
            handleTempMapChange()

        }

        let interval_ID: any


        if ((showSoilMap) && (map_instance.current)) {

            // if true, remove layer and the interval timer
            map_instance.current.removeLayer('soil_map')
            clearInterval(soilInterval)
            setSoil_current_image(soil_frames - 1)
            setSoil_current_time(soil_times[soil_frames - 1])
            setShowSoilMap(false)

            map_instance.current.getSource('soil_sensors').setData({
                "type": "FeatureCollection",
                "features": soilMoistureLocations[soil_frames - 1]
            });

            let current_markers = map_markers

            current_markers.forEach(element => {
                element.addTo(map_instance.current)
            });


        }

        else if (map_instance.current) {

            let current_markers = map_markers

            current_markers.forEach(element => {
                element.remove()
            });

            // frame count is the length of the array for values that are not undefined

            map_instance.current.addLayer({
                'id': 'soil_map',
                'type': 'fill',
                'source': 'soil_map', // reference the data source
                'layout': {},
                'paint': {
                    'fill-color': ['step', ['get', 'value'], '#f7fbff', 16, '#e2edf8', 18, '#cde0f1', 20, '#afd1e7', 22, '#89bedc', 24, '#60a6d2', 26, '#3e8ec4', 28, '#2272b5', 30, '#0a549e', 32, '#08306b'],
                    // 'fill-color': ['interpolate-hcl', ['linear'], ['get', 'value'], 0, 'white', 40, 'blue'],
                    'fill-opacity': 0.9,
                    'fill-outline-color': ['rgba', 0, 0, 0, 0]

                }
            })

            setShowSoilMap(true)

        }

    }

    const handleSoilPlay = () => {

        if (play) {
            clearInterval(soilInterval)
            setPlay(false)


        }
        else {


            let currentImage = soil_current_image


            let interval_ID = setInterval(async () => {

                currentImage = (currentImage + 1) % soil_frames;
                setSoil_current_image(currentImage);
                setSoil_current_time(soil_times[currentImage])


                if ((soilMoistureFeatures[currentImage][1] == null) && (soilMoistureStatus[currentImage] == 'not_loaded')) {

                    setSoilMoistureStatus((prev: any) => {
                        let new_status = [...prev]
                        new_status[currentImage] = 'loading'
                        return new_status
                    })

                    let data = await downloadData(currentImage, "soil")

                    setSoilMoistureStatus((prev: any) => {
                        let new_status = [...prev]
                        new_status[currentImage] = 'loaded'
                        return new_status
                    })



                    map_instance.current.getSource('soil_map').setData({
                        "type": "FeatureCollection",
                        "features": data[1]['features']
                    });
                }

                else if (soilMoistureStatus[currentImage] == 'loaded') {

                    map_instance.current.getSource('soil_map').setData({
                        "type": "FeatureCollection",
                        "features": soilMoistureFeatures[currentImage][1]['features']
                    });

                }
                map_instance.current.getSource('soil_sensors').setData({
                    "type": "FeatureCollection",
                    "features": soilMoistureLocations[currentImage]
                });


            }, 500);

            setPlay(true)
            setSoilInterval(interval_ID)
        }
    }

    const handleSoilJumpTo = async (value: number) => {



        if ((soil_current_image + value >= 0) && (soil_current_image + value < soil_frames)) {


            setSoil_current_image((prev) => prev + value)
            setSoil_current_time(soil_times[soil_current_image + value])


            if ((soilMoistureFeatures[soil_current_image + value][1] == null) && (soilMoistureStatus[soil_current_image + value] == 'not_loaded')) {

                setSoilMoistureStatus((prev: any) => {
                    let new_status = [...prev]
                    new_status[soil_current_image + value] = 'loading'
                    return new_status
                })

                let data = await downloadData(soil_current_image + value, "soil")

                setSoilMoistureStatus((prev: any) => {
                    let new_status = [...prev]
                    new_status[soil_current_image + value] = 'loaded'
                    return new_status
                })



                map_instance.current.getSource('soil_map').setData({
                    "type": "FeatureCollection",
                    "features": data[1]['features']
                });
            }

            else if (soilMoistureStatus[soil_current_image + value] == 'loaded') {

                map_instance.current.getSource('soil_map').setData({
                    "type": "FeatureCollection",
                    "features": soilMoistureFeatures[soil_current_image + value][1]['features']
                });

            }

            map_instance.current.getSource('soil_sensors').setData({
                "type": "FeatureCollection",
                "features": soilMoistureLocations[soil_current_image + value]
            });

        }
    }

    const handleSoilSlider = async (event: any) => {

        setSoil_current_image(event.target.value)
        setSoil_current_time(soil_times[event.target.value])



        if ((soilMoistureFeatures[event.target.value][1] == null) && (soilMoistureStatus[event.target.value] == 'not_loaded')) {

            setSoilMoistureStatus((prev: any) => {
                let new_status = [...prev]
                new_status[event.target.value] = 'loading'
                return new_status
            })

            let data = await downloadData(event.target.value, "soil")

            setSoilMoistureStatus((prev: any) => {
                let new_status = [...prev]
                new_status[event.target.value] = 'loaded'
                return new_status
            })



            map_instance.current.getSource('soil_map').setData({
                "type": "FeatureCollection",
                "features": data[1]['features']
            });
        }

        else if (soilMoistureStatus[event.target.value] == 'loaded') {

            map_instance.current.getSource('soil_map').setData({
                "type": "FeatureCollection",
                "features": soilMoistureFeatures[event.target.value][1]['features']
            });

        }

        map_instance.current.getSource('soil_sensors').setData({
            "type": "FeatureCollection",
            "features": soilMoistureLocations[event.target.value]
        });


        return

    }

    /////////////////// Sensor Icons //////////////////////

    const handleSoilSenorChange = () => {

        // if true, remove layer
        if (showSoilSensor) {
            setShowSoilSensor(false)

            map_instance.current.removeLayer('soil_text')
            map_instance.current.removeLayer('soil_marker')
            map_instance.current.removeLayer('soil_text2')
        }
        // if false, add layers
        else {

            setShowSoilSensor(true)

            map_instance.current.addLayer(
                {
                    'id': 'soil_text',
                    'type': 'symbol',
                    'source': 'soil_sensors',
                    'layout': {
                        "text-field": ["get", "value"],
                        "text-offset": [0, -1],
                    },
                    'paint': {
                        'text-color': 'black',
                        'text-halo-color': 'white',
                        'text-halo-width': 200
                    },
                }
            )

            map_instance.current.addLayer(
                {
                    'id': 'soil_text2',
                    'type': 'symbol',
                    'source': 'soil_sensors',
                    'layout': {
                        "text-field": ["get", "id"],
                        "text-offset": [0, 2],
                    },
                    'paint': {
                    }
                }
            )

            map_instance.current.addLayer(
                {
                    'id': 'soil_marker',
                    'type': 'circle',
                    'source': 'soil_sensors',
                    'layout': {
                    },
                    'paint': {
                        'circle-color': 'blue',
                        'circle-radius': 5,
                        'circle-stroke-width': 2,
                        'circle-stroke-color': '#ffffff'

                    }
                }
            )
        }

    }

    const handleMeshnetTempSenorChange = () => {

        if (showMeshnetTempSensor) {
            setShowMeshnetTempSensor(false)
            map_instance.current.removeLayer('temp_meshnet_marker')
            map_instance.current.removeLayer('temp_meshnet_text')
            map_instance.current.removeLayer('temp_meshnet_text2')
        }

        else {

            map_instance.current.addLayer(
                {
                    'id': 'temp_meshnet_marker',
                    'type': 'circle',
                    'source': 'temp_sensors',
                    'layout': {
                    },
                    'paint': {
                        'circle-color': '#4264fb',
                        'circle-radius': 5,
                        'circle-stroke-width': 2,
                        'circle-stroke-color': '#ffffff'

                    },
                    'filter': ['==', ["get", "type"], 'meshnet']
                }
            )

            map_instance.current.addLayer(
                {
                    'id': 'temp_meshnet_text',
                    'type': 'symbol',
                    'source': 'temp_sensors',
                    'layout': {
                        "text-field": ["get", "value"],
                        "text-offset": [0, -1],

                    },
                    'paint': {
                        'text-color': 'black',
                        'text-halo-color': 'white',
                        'text-halo-width': 200
                    },
                    'filter': ['==', ["get", "type"], 'meshnet']
                }
            )

            map_instance.current.addLayer(
                {
                    'id': 'temp_meshnet_text2',
                    'type': 'symbol',
                    'source': 'temp_sensors',
                    'layout': {
                        "text-field": ["get", "id"],
                        "text-offset": [0, 2],
                        "text-size": 10
                    },
                    'paint': {
                    },
                    'filter': ['==', ["get", "type"], 'meshnet']
                }
            )

            setShowMeshnetTempSensor(true)
        }
    }

    const handleLuftTempSenorChange = () => {

        if (showLuftTempSensor) {
            setShowLuftTempSensor(false)
            map_instance.current.removeLayer('luft_temp_marker')
            map_instance.current.removeLayer('luft_temp_text')
            map_instance.current.removeLayer('luft_temp_text2')
        }

        else {

            map_instance.current.addLayer(
                {
                    'id': 'luft_temp_marker',
                    'type': 'circle',
                    'source': 'temp_sensors',
                    'layout': {
                    },
                    'paint': {
                        'circle-color': 'green',
                        'circle-radius': 7,
                        'circle-stroke-width': 2,
                        'circle-stroke-color': '#ffffff'

                    },
                    'filter': ['==', ["get", "type"], 'fieldmouse']
                }
            )

            map_instance.current.addLayer(
                {
                    'id': 'luft_temp_text',
                    'type': 'symbol',
                    'source': 'temp_sensors',
                    'layout': {
                        "text-field": ["get", "value"],
                        "text-offset": [0, -1.5]


                    },
                    'paint': {
                        'text-color': 'black',
                        'text-halo-color': 'white',
                        'text-halo-width': 200
                    },
                    'filter': ['==', ["get", "type"], 'fieldmouse']
                }
            )

            map_instance.current.addLayer(
                {
                    'id': 'luft_temp_text2',
                    'type': 'symbol',
                    'source': 'temp_sensors',
                    'layout': {
                        "text-field": ["get", "id"],
                        "text-offset": [0, 2],
                        "text-size": 10
                    },
                    'paint': {
                    },
                    'filter': ['==', ["get", "type"], 'fieldmouse']
                }
            )

            setShowLuftTempSensor(true)
        }



    }

    const handleShowIrrigationZones = () => {

        if (showIrrigationZones) {
            setShowIrrigationZones(false)
            map_instance.current.removeLayer('irrigation_zones')
            map_instance.current.removeLayer('irrigation_zones_text')
        }

        else {
            map_instance.current.addLayer(
                {
                    'id': 'irrigation_zones',
                    'type': 'fill',
                    'source': 'irrigation_zones',
                    'layout': {
                    },
                    'paint': {
                        'fill-color': 'rgba(3,138,255,0)',
                        'fill-opacity': 1,
                        'fill-outline-color': 'red'

                    }
                }
            )

            map_instance.current.addLayer(
                {
                    'id': 'irrigation_zones_text',
                    'type': 'symbol',
                    'source': 'irrigation_zones',
                    'layout': {
                        "text-field": ["get", "guid"],
                        "text-offset": [0, -1],

                    },
                    'paint': {
                        'text-color': 'black',
                        'text-halo-color': 'white',
                        'text-halo-width': 200
                    }
                }
            )



            setShowIrrigationZones(true)
        }
    }

    const handleShowContours = () => {

        if (showContours) {
            setShowContours(false)
            map_instance.current.removeLayer('contours')
            map_instance.current.removeLayer('countour_labels')
        }

        else {
            map_instance.current.addLayer(
                {
                    'id': 'contours',
                    'type': 'line',
                    'source': 'contours',
                    'layout': {
                    },
                    'paint': {
                        'line-color': 'red',
                        'line-width': 1,
                        'line-opacity': 0.5

                    }
                }
            )

            map_instance.current.addLayer({
                "id": "countour_labels",
                "type": "symbol",
                "source": 'contours',
                'layout': {
                    'visibility': 'visible',
                    'symbol-placement': 'line',
                    'text-field': ['concat', ['to-string', ['get', 'Elevation']], 'm']
                },
                'paint': {
                    'icon-color': '#877b59',
                    'icon-halo-width': 1,
                    'text-color': 'black',

                    'text-halo-width': 1
                }
            })

            setShowContours(true)
        }
    }

    const handleShowSoilMoistureConditions = () => {

        if (showSoilConditions) {
            setShowSoilConditions(false)
            map_instance.current.removeLayer('soil_conditions')
        }
        else {

            map_instance.current.addLayer({
                'id': 'soil_conditions',
                'type': 'fill',
                'source': 'soil_conditions', // reference the data source
                'layout': {},
                'paint': {
                    'fill-color': ['step', ['get', 'current_conditions'], '#FF0000', 1, '#f00e02', 2, '#f5a50f', 3, '#0d8c2f', 4, '#0fe2f5', 5, '#0c14f5', 6, 'grey'],
                    // 'fill-color': ['interpolate-hcl', ['linear'], ['get', 'value'], 0, 'white', 40, 'blue'],
                    'fill-opacity': 0.9,
                    'fill-outline-color': ['rgba', 0, 0, 0, 0]

                }
            })

            setShowSoilConditions(true)
        }
    }

    const handleShowSoilPedictions = () => {

        if (show_prediction) {
            setShow_prediction(false)
            map_instance.current.removeLayer('soil_predictions')
        }
        else {

            map_instance.current.addLayer({
                'id': 'soil_predictions',
                'type': 'fill',
                'source': 'soil_conditions', // reference the data source
                'layout': {},
                'paint': {
                    'fill-color': ['step', ['get', 'ready_to_go'], '#FF0000', 0, '#f00e02', 1, '#0d8c2f'],
                    // 'fill-color': ['interpolate-hcl', ['linear'], ['get', 'value'], 0, 'white', 40, 'blue'],
                    'fill-opacity': 0.9,
                    'fill-outline-color': ['rgba', 0, 0, 0, 0]

                }
            })

            setShow_prediction(true)
        }



    }


    //////////////////////////// Downloading data from server ////////////////////////////

    const downloadData = async (id: number, type: string) => {

        let map_data: any = []

        if (type === 'soil') {

            map_data = await getMap(soil_document_ids[id])

            setSoilMoistureFeatures((prev: any) => {
                let new_data = [...prev]
                new_data[id] = map_data
                return new_data
            })
        }

        else if (type === 'temp') {

            map_data = await getMap(temp_document_ids[id])

            setTemperatureFeatures((prev: any) => {
                let new_data = [...prev]
                new_data[id] = map_data
                return new_data
            })
        }

        return map_data

    }

    const get_locations = async (data: any[][][], type: "soil" | "temp") => {

        let all_features: any[] = []

        data[0].forEach(time => {

            let features: any[] = []

            time.forEach(stream => {

                // clean the stream Id for both temp and soil sensors

                let id = stream['stream_id']
                let sensor_type = ""

                if (type === "temp") {

                    sensor_type = id.split(".")[0]

                    if (sensor_type === "meshnet") {
                        id = id.split(".")
                        id = id[3].replace("-TRH-SOPA", "")

                    }
                    else if (sensor_type === "fieldmouse") {
                        id = id.split(".")
                        id = id[3] + "." + id[4]

                    }

                }

                if (type === "soil") {
                    sensor_type = 'soil'
                    id = id.replace('meshnet.bicentennialpark.SoilMonitor.', '').replace('-SM-SOPA.VWC', '')
                }


                let feature = {
                    "type": "Feature",
                    "properties": {
                        "value": stream['value'].toFixed(2),
                        "id": id,
                        "type": sensor_type

                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": [stream['long'], stream['lat']]
                    }
                }

                features.push(feature)

            })

            all_features.push(features)


        })

        return all_features

    }

    // find min temperature and the location of that sensor
    let find_min = async (data: any[]) => {



        let date = Date.now()
        date = date - (60 * 60000)

        let min_temp: number = 100000000
        let min_location = ""
        let min_time = ""
        let min_sensor = ""

        let max_temp: number = -1000000
        let max_location = ""
        let max_time = ""
        let max_sensor = ""


        let all_sensors: any[] = []


        data.forEach(element => {


            let sensor_id = element['id']

            let senaps_date = new Date(element['resultsSummary']['last']['t'])
            let senaps_date_mili = senaps_date.getTime()

            let sensor_location = element['_embedded']['location'][0]['geojson']['coordinates']

            if (parkSensors.includes(sensor_id)) {
                all_sensors.push(sensor_location)
            }


            // ensure data is taken from the last 30 min
            if (senaps_date_mili > date) {

                if (parkSensors.includes(sensor_id)) {

                    if (element['resultsSummary']['last']['v']['v'] < min_temp) {

                        min_temp = parseFloat(element['resultsSummary']['last']['v']['v'].toFixed(2))
                        min_location = sensor_location
                        min_time = element['resultsSummary']['last']['t']
                        min_sensor = sensor_id
                    }

                    if (element['resultsSummary']['last']['v']['v'] > max_temp) {

                        max_temp = parseFloat(element['resultsSummary']['last']['v']['v'].toFixed(2))
                        max_location = sensor_location
                        max_time = element['resultsSummary']['last']['t']
                        max_sensor = sensor_id
                    }

                }

            }
        })


        var locations: { [key: string]: string; } = {

            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.457_1221.Temperature': 'Treillage',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.042_0222.Temperature': 'Treillage',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.456_0222.Temperature': 'Lake South and Waterview',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.442_0222.Temperature': 'Lake South and Waterview',
            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.472_1221.Temperature': 'Lake South and Waterview',
            'fieldmouse.bicentennialpark.external_weather_stations.WS600-UMB.040_0222.Temperature': 'Concorde West',
            'fieldmouse.bicentennialpark.external_weather_stations.WS400-UMB.426_1221.Temperature': 'Village Green and Approach',

        };


        let all_data: any[] = await getHistoricTempData(min_sensor)

        let data_values: any[] = []

        all_data.forEach(element => {

            data_values.push([element['t'], element['v']['v']])

        })


        let reversed = data_values.reverse()

        return [parseFloat(min_temp.toFixed(2)), min_location, min_time, min_sensor, locations[min_sensor], reversed, all_sensors, max_location, max_time, max_sensor, locations[max_sensor], parseFloat(max_temp.toFixed(2))]

    }


    useEffect(() => {


        if ((renderMap) && (loaded)) {


            if (map_instance.current) {

                return
            }; // initialize map only once

            let map = new mapboxgl.Map({
                container: mapContainer.current,
                style: 'mapbox://styles/mapbox/satellite-v9', // style URL
                center: [lng, lat],
                zoom: zoom
            })

            map_instance.current = map

            map.on('load', () => {

                map_instance.current.addSource('temp_sensors', {
                    type: 'geojson',
                    data: {
                        "type": "FeatureCollection",
                        "features": tempLocations[temp_frames - 1]
                    }

                })

                map_instance.current.addSource('soil_sensors', {
                    type: 'geojson',
                    data: {
                        "type": "FeatureCollection",
                        "features": soilMoistureLocations[soil_frames - 1]
                    }
                })

                map_instance.current.addSource('soil_map', {
                    type: 'geojson',
                    data: {
                        "type": "FeatureCollection",
                        "features": soilMoistureFeatures[soil_frames - 1][1]['features']
                    }
                });

                map_instance.current.addSource('temp_map', {
                    type: 'geojson',
                    data: {
                        "type": "FeatureCollection",
                        "features": temperatureFeatures[temp_frames - 1][1]['features']
                    }
                });

                map_instance.current.addSource('irrigation_zones', {
                    type: 'geojson',
                    data: {
                        "type": "FeatureCollection",
                        "features": zones['features']
                    }
                });

                map_instance.current.addSource('contours', {
                    type: 'geojson',
                    data: {
                        "type": "FeatureCollection",
                        "features": contours['features']
                    }
                });


                map_instance.current.addSource('soil_conditions', {
                    type: 'geojson',
                    data: {
                        "type": "FeatureCollection",
                        "features": summary_map
                    }
                })


                var popup = new mapboxgl.Popup({
                    closeButton: false
                });

                map_instance.current.on('click', 'irrigation_zones', (e: any) => {

                    setZoneDialogueData(e.features[0].properties['guid'])
                    setOpenZoneDialogue(true)

                })

                map.on('mousemove', 'irrigation_zones', (e: any) => {

                    // popup.setLngLat(e.lngLat)
                    //     .setText(e.features[0].properties['guid'])
                    //     .addTo(map);

                    map.getCanvas().style.cursor = 'pointer';
                });

                map.on('mouseleave', 'irrigation_zones', () => {
                    map.getCanvas().style.cursor = '';
                    // popup.remove();
                });

                // Add terrain source, with slight exaggeration
                map.addSource('mapbox-dem', {
                    'type': 'raster-dem',
                    'url': 'mapbox://mapbox.terrain-rgb',
                    'tileSize': 512,
                    'maxzoom': 14
                });
                map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 2 });


            })




            // locations.forEach((location, index) => {

            //     const luft_marker = new mapboxgl.Marker({ color: 'green' })
            //         .setLngLat(location)
            //         .addTo(map);

            // })

            const marker = new mapboxgl.Marker({ color: 'blue' })
                .setLngLat(minTemp[1])
                .addTo(map);

            const marker2 = new mapboxgl.Marker({ color: 'red' })
                .setLngLat(minTemp[7])
                .addTo(map);

            setMapMarkers([marker, marker2])

            // marker.getElement().addEventListener('click', () => {
            // 
            // })

            // const marker2 = new mapboxgl.Marker()
            //     .setLngLat([lng,lat])
            //     .addTo(map);

            // marker2.getElement().addEventListener('click', () => {
            //
            // })

        }
    }, [renderMap, loaded])

    useEffect(() => {

        let setStreamData = async () => {

            let soil_map_data = await getData('44662edc-73c1-4930-b5d9-253ececb7471')
            let soil_locations = await get_locations(soil_map_data, "soil")

            let temp_map_data = await getData('a20d78cd-1012-4782-b1bc-7906304db8d7')
            let temp_locations2 = await get_locations(temp_map_data, "temp")


            setSoil_times(soil_map_data[1])
            setSoil_current_time(soil_map_data[1][soil_map_data[1].length - 1])
            setSoil_frames(soil_map_data[1].length)
            setSoil_current_image(soil_map_data[1].length - 1)

            setTemp_times(temp_map_data[1])
            setTemp_current_time(temp_map_data[1][temp_map_data[1].length - 1])
            setTemp_frames(temp_map_data[1].length)
            setTemp_current_image(temp_map_data[1].length - 1)

            setSoilMoistureLocations(soil_locations)
            setTempLocations(temp_locations2)

            let soil_map: any[] = []
            let temp_map: any[] = []

            let soil_map_status: any[] = []
            let temp_map_status: any[] = []

            // read in only the current map
            let single_soil_map = await getMap(soil_document_ids[soil_map_data[1].length - 1])
            let single_temp_map = await getMap(temp_document_ids[temp_map_data[1].length - 1])



            for (let i = 0; i < soil_map_data[1].length - 1; i++) {
                soil_map.push([])
                soil_map_status.push('not_loaded')
            }

            for (let i = 0; i < temp_map_data[1].length - 1; i++) {
                temp_map.push([])
                temp_map_status.push('not_loaded')
            }


            soil_map = [...soil_map, single_soil_map]
            soil_map_status = [...soil_map_status, 'loaded']

            temp_map = [...temp_map, single_temp_map]
            temp_map_status = [...temp_map_status, 'loaded']




            setSoilMoistureFeatures(soil_map)
            setTemperatureFeatures(temp_map)
            setSoilMoistureStatus(soil_map_status)
            setTemperatureStatus(temp_map_status)

            // load the min and max temp for red and blue markers
            let luft_temp_data: any[] = await getLuftTempSensors()
            let min_temp = await find_min(luft_temp_data)
            setMinTemp(min_temp)


            // need to produce map to determine if each irrigation zone is wet or dry and then color the zones accordingly

            setRenderMap(true)

        }

        setStreamData()

    }, [])

    useEffect(() => {

        // go through each zone and map the current conditions and the read to go conditions to the zone
        if (summary_data.length > 0) {

            let zone_data = zones['features'].map((zone: any) => {

                let guid = zone['properties']['guid']

                //find the guid in the summary data list
                let zone_summary = summary_data.find((zone_summary: any) => {
                    return zone_summary[2] === guid
                })

                //some zone (3 of them) are yet to have any data, set them to undefined
                if (zone_summary === undefined) {
                    return zone
                }

                zone['properties']['current_conditions'] = zone_summary[4]
                zone['properties']['ready_to_go'] = zone_summary[9]

                return zone
            })


            setSummary_map(zone_data)
            setLoaded(true)
        }

    }, [summary_data])


    let soil_legend = [['#f7fbff', '< 16%'], ['#e2edf8', '16% - 18%'], ['#cde0f1', '18% - 20%'], ['#afd1e7', '20% - 22%'], ['#89bedc', '22% - 24%'], ['#60a6d2', '24% - 26%'], ['#3e8ec4', '26% - 28%'], ['#2272b5', '28% - 30%'], ['#0a549e', '30% - 32%'], ['#08306b', '> 32%']]
    let soil_conditions = [['#0c14f5', 'Saturated'], ['#0fe2f5', 'Wet'], ['#0d8c2f', 'Moist'], ['#f5a50f', 'Dry'], ['#f00e02', 'Very Dry'], ['grey', 'No Data']]

    if (!renderMap) {
        return (
            <Paper variant='outlined' sx={{ margin: '10px', p: '10px', position: 'relative', display: 'flex', justifyContent: 'center', height: '96%', alignItems: 'center' }}>
                <div className="loading">
                    <Oval
                        height={80}
                        width={80}
                        color="#76C7F0"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="#1BB4FF"
                        strokeWidth={6}
                        strokeWidthSecondary={6}

                    />
                </div>
                <SummaryDialogue open={show_summary} openfunction={handleShowSummary} setDataFunction={setSummary_data} />
            </Paper >
        )
    }

    if (width > 800) {

        return (<>
            <Paper variant='outlined' sx={{ margin: '10px', p: '10px', position: 'relative' }}>

                <Paper sx={{ position: 'absolute', backgroundColor: 'lightgrey', top: '15px', right: '15px', width: '40px', zIndex: '10' }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="end"
                        onClick={handleDrawerOpen}
                        sx={{ ...(open && { display: 'none' }) }}
                    >
                        <FilterListIcon />
                    </IconButton>
                </Paper>

                {showSoilMap
                    ? (<>

                        <Paper sx={{ position: 'absolute', backgroundColor: 'lightgrey', top: '15px', left: 'calc(50% - 250px)', width: '500px', zIndex: '10', display: 'flex', justifyContent: 'space-between' }}>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleSoilJumpTo(-1)}
                            >
                                < ChevronLeftIcon />
                            </IconButton>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleSoilPlay}
                            >
                                {play ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleSoilJumpTo(1)}

                            >
                                <ChevronRightIcon />
                            </IconButton>
                            <Slider
                                min={0} max={soil_frames - 1} value={soil_current_image} onChange={handleSoilSlider} sx={{ width: '200px', mr: '20px' }} />
                        </Paper>

                        <Paper sx={{ position: 'absolute', backgroundColor: 'lightgrey', top: '65px', left: 'calc(50% - 250px)', width: '500px', zIndex: '10', display: 'flex', justifyContent: 'center' }}>
                            {soil_current_time}
                        </Paper>





                    </>)

                    : (<></>)


                }



                {showTempMap
                    ? (<>

                        <Paper sx={{ position: 'absolute', backgroundColor: 'lightgrey', top: '15px', left: 'calc(50% - 250px)', width: '500px', zIndex: '10', display: 'flex', justifyContent: 'space-between' }}>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleTempJumpTo(-1)}
                            >
                                < ChevronLeftIcon />
                            </IconButton>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleTempPlay}
                            >
                                {temp_play ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleTempJumpTo(1)}

                            >
                                <ChevronRightIcon />
                            </IconButton>
                            <Slider
                                step={1} min={0} max={temp_frames - 1} value={temp_current_image} onChange={handleTempSlider} sx={{ width: '200px', mr: '20px' }} />
                        </Paper>

                        <Paper sx={{ position: 'absolute', backgroundColor: 'lightgrey', top: '65px', left: 'calc(50% - 250px)', width: '500px', zIndex: '10', display: 'flex', justifyContent: 'center' }}>
                            {temp_current_time}
                        </Paper>
                    </>)
                    : (<></>)
                }



                <Box>
                    <>


                        <div ref={mapContainer} className="map-container" style={{ height: '89vh' }} />


                    </>



                </Box>




                {showSoilMap

                    ? (<><Paper variant='outlined' sx={{ position: 'absolute', bottom: '45px', left: '25px', width: '200px', p: '10px' }}>
                        <Grid container>
                            <>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}> <b>Legend</b></Grid>

                                {soil_legend.map(entry => {

                                    return (<>
                                        <Grid item xs={6}> <div style={{ backgroundColor: entry[0], height: '20px', width: '75px', borderRadius: '0px' }}></div></Grid>
                                        <Grid item xs={6}> {entry[1]} </Grid>
                                    </>)
                                })}
                            </>
                        </Grid>
                    </Paper></>)

                    : (<></>)

                }

                {showSoilConditions

                    ? (<><Paper variant='outlined' sx={{ position: 'absolute', bottom: '45px', left: '25px', width: '200px', p: '10px' }}>
                        <Grid container>
                            <>
                                <Grid item xs={12} sx={{ textAlign: 'center' }}> <b>Legend</b></Grid>

                                {soil_conditions.map(entry => {

                                    return (<>
                                        <Grid item xs={6}> <div style={{ backgroundColor: entry[0], height: '20px', width: '75px', borderRadius: '0px' }}></div></Grid>
                                        <Grid item xs={6}> {entry[1]} </Grid>
                                    </>)
                                })}
                            </>
                        </Grid>
                    </Paper></>)

                    : (<></>)

                }


                {showTempMap

                    ? (<>
                        <Paper variant='outlined' sx={{ position: 'absolute', bottom: '45px', left: '25px', width: '175px', p: '10px' }}>
                            <Grid container >
                                <>
                                    <Grid item xs={12} sx={{ textAlign: 'center', mb: '0px' }}> <b>Legend</b></Grid>
                                    <Grid item xs={12} sx={{ textAlign: 'center', mb: '10px' }}> Difference to average park temperature </Grid>
                                    <Grid item xs={6}> <div style={{ width: "100%", height: 250, backgroundImage: "linear-gradient(red, blue)" }}></div></Grid>
                                    <Grid item xs={6} >
                                        <div style={{ height: '33%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', paddingLeft: '10px' }}>
                                            +2.0°C
                                        </div>
                                        <div style={{ height: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
                                            0°C
                                        </div>
                                        <div style={{ height: '33%', display: 'flex', justifyContent: 'center', alignItems: 'flex-end', paddingLeft: '10px' }}>
                                            -2.0°C
                                        </div>


                                    </Grid>


                                </>
                            </Grid>
                        </Paper>

                    </>)
                    : (<></>)

                }

            </Paper>

            <PersistentDrawerRight
                slider_width={400}
                open={openDrawer}
                setOpen={setOpenDrawer}
                tempmap={showTempMap}
                setTempmap={handleTempMapChange}
                soilmap={showSoilMap}
                setSoilmap={handleSoilMapChange}
                soilSensor={showSoilSensor}
                setSoilSensor={handleSoilSenorChange}
                tempMeshnetSensor={showMeshnetTempSensor}
                setTempMeshnetSensor={handleMeshnetTempSenorChange}
                tempLuftSensor={showLuftTempSensor}
                setTempLuftSensor={handleLuftTempSenorChange}
                irrigation={showIrrigationZones}
                setIrrigation={handleShowIrrigationZones}
                contours={showContours}
                setContours={handleShowContours}
                summary={show_summary}
                setSummary={handleShowSummary}
                soilConditions={showSoilConditions}
                setSoilConditions={handleShowSoilMoistureConditions}
                senorPredictions={show_prediction}
                setSensorPredictions={handleShowSoilPedictions}
                networkStatus={showNetworkStatus}
                setNetworkStatus={handleShowNetworkStatus}
                stationSummary={showStationSummary}
                setStationSummary={handleShowStationSummary} />

            <SummaryDialogue open={show_summary} openfunction={handleShowSummary} setDataFunction={setSummary_data} />
            <NetworkStatus open={showNetworkStatus} openfunction={handleShowNetworkStatus} setDataFunction={setSummary_data} />
            <StationSummary open={showStationSummary} openfunction={handleShowStationSummary} setDataFunction={setSummary_data} />
            {
                loaded

                    ? (<>
                        <ZoneDialogue open={open_zone_dialogue} openfunction={handleZoneDialogueOpen} values={zone_dialogue_data} />

                    </>)

                    : (<></>)

            }
        </>)

    }
    else {

        return (<>

            <Grid container>
                <Grid item xs={12} >
                    <Paper variant='outlined' sx={{ margin: '10px', p: '10px', position: 'relative' }}>
                        <Box>
                            <div ref={mapContainer} className="map-container" style={{ height: '65vh' }} />
                        </Box>
                    </Paper>

                    <Paper sx={{ position: 'absolute', backgroundColor: 'lightgrey', top: '30px', right: '30px', width: '40px', zIndex: '10' }}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="end"
                            onClick={handleDrawerOpen}
                            sx={{ ...(open && { display: 'none' }) }}
                        >
                            <FilterListIcon />
                        </IconButton>
                    </Paper>

                    <PersistentDrawerRight
                        slider_width={width}
                        open={openDrawer}
                        setOpen={setOpenDrawer}
                        tempmap={showTempMap}
                        setTempmap={handleTempMapChange}
                        soilmap={showSoilMap}
                        setSoilmap={handleSoilMapChange}
                        soilSensor={showSoilSensor}
                        setSoilSensor={handleSoilSenorChange}
                        tempMeshnetSensor={showMeshnetTempSensor}
                        setTempMeshnetSensor={handleMeshnetTempSenorChange}
                        tempLuftSensor={showLuftTempSensor}
                        setTempLuftSensor={handleLuftTempSenorChange}
                        irrigation={showIrrigationZones}
                        setIrrigation={handleShowIrrigationZones}
                        contours={showContours}
                        setContours={handleShowContours}
                        summary={show_summary}
                        setSummary={handleShowSummary}
                        soilConditions={showSoilConditions}
                        setSoilConditions={handleShowSoilMoistureConditions}
                        senorPredictions={show_prediction}
                        setSensorPredictions={handleShowSoilPedictions}
                        networkStatus={showNetworkStatus}
                        setNetworkStatus={handleShowNetworkStatus}
                        stationSummary={showStationSummary}
                        setStationSummary={handleShowStationSummary} />
                </Grid>
            </Grid>

            <Box>

                {showSoilMap
                    ? (<>

                        <Paper variant='outlined' sx={{ backgroundColor: 'white', width: width - 20, display: 'flex', justifyContent: 'space-around', m: '10px', mt: '0px' }}>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleSoilJumpTo(-1)}
                            >
                                < ChevronLeftIcon />
                            </IconButton>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleSoilPlay}
                            >
                                {play ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleSoilJumpTo(1)}

                            >
                                <ChevronRightIcon />
                            </IconButton>
                            <Slider
                                min={0} max={soil_frames - 1} value={soil_current_image} onChange={handleSoilSlider} sx={{ width: '200px', mr: '20px' }} />
                        </Paper>

                        <Paper sx={{ position: 'absolute', backgroundColor: 'lightgrey', top: '30px', left: '30px', width: '200px', zIndex: '10', textAlign: 'center' }}>
                            {soil_current_time}
                        </Paper>
                    </>)
                    : (<></>)


                }

                {showSoilMap

                    ? (<><Paper variant='outlined' sx={{ width: width - 20, m: '10px', p: '10px' }}>
                        <Grid container>
                            <>
                                <Grid item xs={12} sx={{ textAlign: 'center', mb: '10px' }}> <b>Legend</b></Grid>

                                <Grid item xs={6}>

                                    <Grid container>

                                        {soil_legend.map((entry, index) => {

                                            if (index < 5) {
                                                return (<>
                                                    <Grid item xs={6}> <div style={{ backgroundColor: entry[0], height: '20px', width: '75px', borderRadius: '0px' }}></div></Grid>
                                                    <Grid item xs={6}> {entry[1]} </Grid>
                                                </>)
                                            }
                                        })}

                                    </Grid>

                                </Grid>

                                <Grid item xs={6}>

                                    <Grid container>

                                        {soil_legend.map((entry, index) => {

                                            if (index >= 5) {
                                                return (<>
                                                    <Grid item xs={6}> <div style={{ backgroundColor: entry[0], height: '20px', width: '75px', borderRadius: '0px' }}></div></Grid>
                                                    <Grid item xs={6}> {entry[1]} </Grid>
                                                </>)
                                            }
                                        })}

                                    </Grid>
                                </Grid>

                            </>
                        </Grid>
                    </Paper></>)

                    : (<></>)

                }

                {showTempMap
                    ? (<>

                        <Paper variant='outlined' sx={{ backgroundColor: 'white', width: width - 20, display: 'flex', justifyContent: 'space-around', m: '10px', mt: '0px' }}>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleTempJumpTo(-1)}
                            >
                                < ChevronLeftIcon />
                            </IconButton>

                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={handleTempPlay}
                            >
                                {temp_play ? <PauseIcon /> : <PlayArrowIcon />}
                            </IconButton>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="end"
                                onClick={() => handleTempJumpTo(1)}

                            >
                                <ChevronRightIcon />
                            </IconButton>
                            <Slider
                                min={0} max={temp_frames - 1} value={temp_current_image} onChange={handleTempSlider} sx={{ width: '200px', mr: '5px' }} />
                        </Paper>

                        <Paper sx={{ position: 'absolute', backgroundColor: 'lightgrey', top: '30px', left: '30px', width: '200px', zIndex: '10', textAlign: 'center' }}>
                            {temp_current_time}
                        </Paper>
                    </>)
                    : (<></>)
                }

                {showTempMap

                    ? (<>
                        <Paper variant='outlined' sx={{ width: width - 20, m: '10px', p: '10px' }}>
                            <Grid container >
                                <>
                                    <Grid item xs={12} sx={{ textAlign: 'center', mb: '0px' }}> <b>Legend</b></Grid>
                                    <Grid item xs={12} sx={{ textAlign: 'center', mb: '10px' }}> Difference to average park temperature </Grid>
                                    <Grid item xs={12}> <div style={{ width: "100%", height: 75, backgroundImage: "linear-gradient(to right, blue, red)" }}></div></Grid>
                                    <Grid item xs={12} >
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ width: '33%', display: 'flex', justifyContent: 'left', alignItems: 'flex-start', paddingLeft: '10px' }}>
                                                -2.0°C
                                            </div>
                                            <div style={{ width: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingLeft: '10px' }}>
                                                0°C
                                            </div>
                                            <div style={{ width: '33%', display: 'flex', justifyContent: 'right', alignItems: 'flex-end', paddingLeft: '10px' }}>
                                                +2.0°C
                                            </div>
                                        </div>
                                    </Grid>
                                </>
                            </Grid>
                        </Paper>

                    </>)
                    : (<></>)

                }


            </Box>

            {
                loaded

                    ? (<>
                        <ZoneDialogue open={open_zone_dialogue} openfunction={handleZoneDialogueOpen} values={zone_dialogue_data} />

                    </>)

                    : (<></>)

            }



        </>)


    }

}


export default MapTile